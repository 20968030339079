import './AddService.css';
import 'react-quill/dist/quill.snow.css';
import { React, useEffect, Table, Form, Input, Select, Row, Col, Checkbox, Space, useState, Locale, Link, useLocation, useNavigate, ReactQuill } from '../../../libraries/index.js';
import { VendorHeader, SuggestionModal, PreviewFileModal, CustomCheckboxWithImage } from '../index.js';
import { SuggestServiceModal, useAddNewServiceData } from '../../component/index.js';
import { CustomSpinner, Heading, EmptyComponent, UploadFileModalLogic } from '../../../common';
import { addService, bussiness, bussinessServices } from '../../../Routes/routes.js';
import { User} from '../../../assets/Images.js';

const AddNewService = () => {
  const location = useLocation();
  const { t } = Locale();
  const [form] = Form.useForm();
  const {
    setSpinner,
    currentUrl,
    error,
    state,
    spinner,
    validateSpecialPrice,
    dataSource,
    getSubCategory,
    category,
    subCategory,
    columns,
    handleAddRow,
    onFinish,
    teamMember,
    handleTeamMemberCheck,
    temMemberArray,
    modules,
    formats,
    validateDescrition,
    validateSpecialTerm,
    showImage,
    setShowImage,
    formDataService,
    validatorImage,
    getEyeClassName
  } = useAddNewServiceData(form);

  const {
    showSuggestionModal,
    handleSuggestionShow,
    handleSuggestionClose,
    handleSuggestion,
  } = SuggestServiceModal({ setSpinner });
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const th = localStorage.getItem('i18nextLng');
  const isSection = localStorage.getItem('isSection');
  const navigate = useNavigate();
  useEffect(() => {
    if (isSection !== 'isBusinessService') {
      localStorage.setItem('isSection', 'isBusinessService');
    }
  }, [location.pathname]);

  const handleServiceSection = (e) => {
    e.preventDefault();
    navigate(`${bussiness}${bussinessServices}`)
  }
  return (
    <>
      <div className='service-review-file'>
        <PreviewFileModal uploadedCheque={(showImage.shopImages[showImage.shopImages.length - 1] ? showImage.shopImages[showImage.shopImages.length - 1] : formDataService)} />
      </div>
      <UploadFileModalLogic setFormValue={setShowImage} formValue={showImage}></UploadFileModalLogic>
      {error ? (
        <EmptyComponent type="pageNotFound" />
      ) : (
        <div className={spinner ? 'blure-bacground' : ''}>
          <div className="heading-outer-div">
            <VendorHeader />
            <Heading
              text={
                state
                  ? `${t('button.editButton')} ${t('favouritePage.service')}`
                  : `${t('button.addButton')} ${t('button.newService')}`
              }
              onClick={(e) => handleServiceSection(e)}
              icon='bi bi-arrow-left-circle'
            />
            <div className="after-heading-outer-div">
              <div className="user-common-box">
                <div className={`user-common-box-inner`}>
                  {spinner ? (
                    ''
                  ) : (
                    <div className="container-fluid user-inner-content-scrollar">
                      <SuggestionModal
                        showSuggestionModal={showSuggestionModal}
                        handleSuggestionShow={handleSuggestionShow}
                        handleSuggestionClose={handleSuggestionClose}
                        handleSuggestion={handleSuggestion}
                      />
                      <div className="mt-3 finance-outer-div ">
                        <Form
                          form={form}
                          name="trigger"
                          layout="vertical"
                          autoComplete="off"
                          onFinish={onFinish}
                        >
                          <Row gutter={16}>
                            <Col xs={24} sm={8}>
                              <Form.Item
                                label={
                                  <span>
                                    {t('header.select')}{' '}
                                    {t('ServiceField.category')}
                                    <span className="custom-astric">*</span>
                                  </span>
                                }
                                name="categoryId"
                                rules={[
                                  {
                                    required: true,
                                    message: `${t('header.select')} ${t('ServiceField.category')}`,
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={`${t('header.select')} ${t('ServiceField.category')}`}
                                  onChange={getSubCategory}
                                >
                                  {category.map((category) => (
                                    <Select.Option
                                      key={category.id}
                                      value={category.id}
                                    >
                                      {th === 'thai' && th !== undefined
                                        ? category.name_thai
                                        : category.name_en}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={8}>
                              <Form.Item
                                label={
                                  <span>
                                    {t('header.select')}{' '}
                                    {t('ServiceField.subCategory')}
                                    <span className="custom-astric">*</span>
                                  </span>
                                }
                                name="subCategoryId"
                                rules={[
                                  {
                                    required: true,
                                    message: `${t('header.select')} ${t('ServiceField.subCategory')}`,
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={`${t('header.select')} ${t('ServiceField.subCategory')}`}
                                >
                                  {subCategory.map((Subcategory) => (
                                    <Select.Option
                                      key={Subcategory.id}
                                      value={Subcategory.id}
                                    >
                                      {th === 'thai' && th !== undefined
                                        ? Subcategory.name_thai
                                        : Subcategory.name_en}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={8}>
                              <Form.Item
                                label={
                                  <span>
                                    {t('ServiceField.duration')}(
                                    {t('ServiceField.inMinute')})
                                    <span className="custom-astric">*</span>
                                  </span>
                                }
                                name="duration"
                                rules={[
                                  {
                                    required: true,
                                    message: `${t('field.pleaseEnter')} ${t('ServiceField.duration')}`,
                                  },
                                  {
                                    pattern: /^[0-9]+$/,
                                    message: `${t('field.pleaseEnter')} ${t('ServiceField.valid')} ${t('ServiceField.number')}`,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={`${t('field.pleaseEnter')} ${t('ServiceField.duration')}`}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                label={
                                  <span>
                                    {t('ServiceField.serviceNameEng')}
                                    <span className="custom-astric">*</span>
                                  </span>
                                }
                                name="serviceNameEnglish"
                                className="form-item-label"
                                rules={[
                                  {
                                    required: true,
                                    message: `${t('field.pleaseEnter')} ${t('ServiceField.serviceNameEng')}`,
                                  },
                                  {
                                    min: 5,
                                    message: `${t('ServiceField.serviceLess')}`,
                                  },
                                  {
                                    max: 50,
                                    message: `${t('ServiceField.serviceLess')}`,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={`${t('field.pleaseEnter')} ${t('favouritePage.service')} ${t('field.name')}`}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                label={
                                  <span>
                                    {t('ServiceField.serviceNameThai')}
                                    <span className="custom-astric">*</span>
                                  </span>
                                }
                                name="serviceNameThai"
                                className="form-item-label"
                                rules={[
                                  {
                                    required: true,
                                    message: `${t('field.pleaseEnter')} ${t('ServiceField.serviceNameThai')}`,
                                  },
                                  {
                                    min: 5,
                                    message: `${t('ServiceField.serviceLess')}`,
                                  },
                                  {
                                    max: 50,
                                    message: `${t('ServiceField.serviceLess')}`,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={`${t('field.pleaseEnter')} ${t('favouritePage.service')} ${t('field.name')}`}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col xs={24} sm={12}>
                              <Row gutter={16} className="price-feature">
                                <Col xs={24} sm={8}>
                                  <Form.Item
                                    name="vouturs"
                                    className="addService-checkbox"
                                  >
                                    <Checkbox.Group className="checkbox-group mt-2">
                                      <Checkbox
                                        value="Featured Service"
                                        className="checkbox-button"
                                      >
                                        {t('ServiceField.featuredService')}
                                      </Checkbox>
                                    </Checkbox.Group>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                  <Form.Item
                                    className="form-item-label"
                                    label={
                                      <span>
                                        {t('ServiceField.price')}
                                        <span className="custom-astric">*</span>
                                      </span>
                                    }
                                    name="price"
                                    rules={[
                                      {
                                        required: true,
                                        message: `${t('field.pleaseEnter')} ${t('ServiceField.price')}`,
                                      },
                                      {
                                        pattern: /^[0-9]+$/,
                                        message: `${t('field.pleaseEnter')} ${t('ServiceField.valid')} ${t('ServiceField.number')}`,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={t('ServiceField.price')}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                  <Form.Item
                                    label={t('ServiceField.specialPrice')}
                                    name="specialPrice"
                                    className="form-item-label"
                                    rules={[
                                      {
                                        pattern: /^[0-9]+$/,
                                        message: `${t('field.pleaseEnter')} ${t('ServiceField.valid')} ${t('ServiceField.number')}`,
                                      },
                                      {
                                        validator: validateSpecialPrice,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={t(
                                        'ServiceField.specialPrice',
                                      )}
                                    />
                                  </Form.Item>
                                </Col>

                              </Row>
                            </Col>

                            <Col xs={24} sm={12}>
                              <Form.Item
                                label={
                                  <span>
                                    {t('ServiceField.uploadImage')}
                                    <span className="custom-astric">*</span>
                                  </span>
                                }
                                className="form-item-label"
                                name="image"
                                rules={[{ validator: validatorImage }]}
                              >
                                <Row gutter={24}>
                                  <Col xs={24} sm={22}>
                                    <div className='service-image-field'>
                                      <div className="finance-cheque-field-after-cheque-outer-div">
                                        <div>
                                          <div
                                            data-bs-toggle="modal"
                                            data-bs-target="#uploadFileModal"
                                            className={`btn editbranchprofile_addbutton`}
                                          ></div>
                                          <label
                                            htmlFor="file"
                                            className="custom-file-upload"
                                          >
                                            <i className="bi bi-upload" data_bs_toggle="modal" data_bs_target="#uploadFileModal"></i>
                                          </label>
                                        </div>
                                        <div className="vendor-finance-file-name">
                                          {showImage.shopImagesShow.length > 0 ? (<>{showImage?.shopImagesShow[showImage?.shopImagesShow?.length - 1]?.name}</>) : <>{formDataService ? <>{t('addService.uploadedImage')}</> : <>{t('financeField.noFileChoosen')}</>}</>}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={24} sm={2}>
                                    <div className='vendor-finance-web-eye'>
                                      <span className="service-previewbutton-Div">
                                        <i
                                          className={`bi bi-eye mt-2 ${getEyeClassName()}`}
                                          title="View Image"
                                          aria-hidden="true"
                                          data-bs-toggle="modal"
                                          data-bs-target="#previewFileModal"
                                        ></i>
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                label={
                                  <span>
                                    {t('ServiceField.serviceDescinEng')}
                                    <span className="custom-astric">*</span>
                                  </span>
                                }
                                className="form-item-label"
                                name="descriptionEnglish"
                                rules={[{ validator: validateDescrition }]}
                              >
                                <ReactQuill
                                  theme="snow"
                                  modules={modules}
                                  formats={formats}
                                  placeholder={t('field.serviceDesPlace')}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                label={
                                  <span>
                                    {t('ServiceField.serviceDescinThai')}
                                    <span className="custom-astric">*</span>
                                  </span>
                                }
                                className="form-item-label"
                                name="descriptionThailand"
                                rules={[{ validator: validateDescrition }]}
                              >
                                <ReactQuill
                                  theme="snow"
                                  modules={modules}
                                  formats={formats}
                                  placeholder={t('field.serviceDesPlace')}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item
                            name="avialableTeamMembers"
                            className="avalableTeamMemberDiv mt-2"
                            label={
                              <span className="custom-label">
                                {t('ServiceField.team')} /{' '}
                                {t('ServiceField.person')}
                              </span>
                            }
                          >
                            {teamMember?.length ? (
                              <Checkbox.Group className="checkbox-group ">
                                {teamMember.map((item, index) => (
                                  <Checkbox
                                    key={item.team_member.id}
                                    onChange={handleTeamMemberCheck}
                                    value={item.team_member.id}
                                    checked={temMemberArray.includes(
                                      item.team_member.id,
                                    )}
                                    className="checkbox-button personCheckBox m-2 d-flex align-items-center"
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                  >
                                    <CustomCheckboxWithImage
                                      imageSrc={
                                        item?.image_url?.image_url
                                          ? item?.image_url?.image_url
                                          : User
                                      }
                                      personName={
                                        item?.team_member?.team_member
                                          ?.first_name +
                                        ' ' +
                                        item?.team_member?.team_member
                                          ?.last_name
                                      }
                                      isHovered={hoveredIndex === index}
                                    />
                                  </Checkbox>
                                ))}
                              </Checkbox.Group>
                            ) : (
                              <small>{t('ServiceField.noMemberYet')}</small>
                            )}
                          </Form.Item>
                          <p className="custom-label">
                            {t('ServiceField.OffPeakDiscounts')}(In %)
                          </p>
                          <div className="table-container team-person text-center add-service-vendor-table">
                            <Table
                              dataSource={dataSource}
                              columns={columns}
                              className="text-center"
                              pagination={false}
                              rowKey="key" // Ensure each row in dataSource has a unique 'key' property
                            />
                          </div>
                          <div className="add-slot">
                            <button
                              className="btn service-add-slot"
                              onClick={handleAddRow}
                            >
                              {t('button.addButton')} {t('ServiceField.slot')}
                            </button>
                          </div>
                          <Row gutter={16} className="form-item-label">
                            <Col xs={24} sm={12}>
                              <Form.Item
                                label={
                                  <span>
                                    {t('ServiceField.specialTermEng')}
                                    <span className="custom-astric">*</span>
                                  </span>
                                }
                                className="form-item-label"
                                name="specialTermEnglish"
                                rules={[{ validator: validateSpecialTerm }]}
                              >
                                <ReactQuill
                                  theme="snow"
                                  modules={modules}
                                  formats={formats}
                                  placeholder={t('field.serviceTermPlace')}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                label={
                                  <span>
                                    {t('ServiceField.specialTermThai')}
                                    <span className="custom-astric">*</span>
                                  </span>
                                }
                                className="form-item-label"
                                name="specialTermThailand"
                                rules={[{ validator: validateSpecialTerm }]}
                              >
                                <ReactQuill
                                  theme="snow"
                                  modules={modules}
                                  formats={formats}
                                  placeholder={t('field.serviceTermPlace')}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <p className="p-headig avalableTeamMemberDiv mt-2">
                            {t('ServiceField.cantFindService')}
                            <Link
                              onClick={handleSuggestionShow}
                              onKeyDown={(event) =>
                                (event.key === 'Enter' || event.key === ' ') &&
                                handleSuggestionShow()
                              }
                              className="custom-link ms-1 me-1"
                              role="button"
                              tabIndex="0"
                            >
                              {t('ServiceField.clickHere')}
                            </Link>
                            {t('ServiceField.toSuggest')}
                          </p>
                          <Form.Item className="footerButton">
                            <Space>
                              <button
                                className="btn backgoround-button"
                                type="submit"
                              >
                                {currentUrl === `${bussiness}${addService}`
                                  ? t('button.saveButton')
                                  : t('button.updateButton')}
                              </button>
                            </Space>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {spinner && <CustomSpinner />}
    </>
  );
};

export default AddNewService;
