import { apiCall } from '../base';

const getAllCustomerInfo = async () => {
  const response = await apiCall('business/members/customers', 'GET');
  return response;
};
export {
  getAllCustomerInfo,

};
