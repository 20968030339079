import './ShareButton.css';
import { React, FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, LineShareButton } from '../../libraries';


const ShareButtons = ({ url, title }) => {
  return (
    <div className="share-buttons">
      <FacebookShareButton url={url}>
        <span className="share-button facebook-button">
          <i className="bi bi-facebook"></i>
        </span>
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title}>
        <span className="share-button twitter-button">
          <i className="bi bi-twitter"></i>
        </span>
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={title}>
        <span className="share-button whatsapp-button">
          <i className="bi bi-whatsapp"></i>
        </span>
      </WhatsappShareButton>
      <EmailShareButton url={url} subject={title}>
        <span className="share-button email-button">
          <i className="bi bi-envelope-fill"></i>
        </span>
      </EmailShareButton>
      <LineShareButton url={url} title={title}>
        <span className="share-button line-button">
          <i className="bi bi-line"></i>
        </span>
      </LineShareButton>
    </div>
  );
};

export default ShareButtons;
