import React from 'react';

import { Input, Space } from 'antd';
export const PasswordField = ({
  className,
  name,
  value,
  setFormValue,
  onChange,
  setErr,
  component,
  placeholder,
}) => {
  return (
    <Space direction="vertical">
      {component === 'userProfile' ? (
        <>
          <Input.Password
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e, setFormValue, '', '', setErr)}
            className={className}
          />
        </>
      ) : (
        <>
          <Input.Password
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e, setFormValue, '', '', setErr)}
            className={className}
          />
        </>
      )}
    </Space>
  );
};
