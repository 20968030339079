import { React, useEffect, useState } from "../../libraries";
import { Footer } from "../../common";
import { NavbarLogic } from "../../components";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb.js";
import { CustomImage } from "../../lazyComponent/index.js";
import { MetaTag } from "../../customHooks/metaKeywords.js";
const CancelPolicyPage = ({ data, BradCrumbData }) => {
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const [privacy, setPrivacy] = useState(0);
  useEffect(() => {}, [selectedLanguage, privacy]);

  const removeHtmlTags = (input) => {
    const doc = new DOMParser().parseFromString(
      `<div charset="windows-874">${input}</div>`,
      "text/html"
    );
    const lines = (doc.body.textContent || "").split(/\r?\n/); // Split text into lines
    const resultLines = [];

    lines.forEach((line) => {
      if (line !== undefined && line !== null) {
        if (/\d/.test(line)) {
          resultLines.push("<br><br>"); // Add line break if line contains a number
        }
        resultLines.push(line);
      }
    });

    return resultLines.join("\n"); // Join lines back into a single string
  };

  const getBusinessPartnerPolicyTitle = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? data?.refund_cancellation_policy?.title_in_thai
      : data?.refund_cancellation_policy?.title;
  };

  const getBusinessPartnerPolicyDescription = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? removeHtmlTags(data?.refund_cancellation_policy?.description_in_thai)
      : data?.refund_cancellation_policy?.description;
  };

  const getSubContentSubHeading = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? removeHtmlTags(data?.sub_content?.sub_heading_in_thai)
      : data?.sub_content?.sub_heading;
  };

  const getSubContentDesc = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? removeHtmlTags(data?.sub_content?.description_in_thai)
      : data?.sub_content?.description;
  };

  return (
    <>
      <MetaTag
        custom_title={data?.refund_cancellation_policy?.title}
        custom_description={data?.refund_cancellation_policy?.description}
        custom_ogtitle={data?.refund_cancellation_policy?.title}
        custom_ogDescription={data?.refund_cancellation_policy?.description}
      />
      <NavbarLogic setPrivacy={setPrivacy} />
      <div className="container about-us-outer-container">
        <BreadCrumb data={BradCrumbData} />
        <div className="aboutus-img-div">
          <div className="row">
            {data?.refund_cancellation_policy_image_url && (
              <CustomImage
                src={data?.refund_cancellation_policy_image_url}
                className="img-fluid"
                alt="business_partner_image"
              />
            )}
            <div
              className={`${
                data?.refund_cancellation_policy_image_url
                  ? "aboutus-title text-center"
                  : "text-center title-business"
              }`}
            >
              {data && getBusinessPartnerPolicyTitle(data)}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <>
            <p
              className="description_in_thai"
              dangerouslySetInnerHTML={{
                __html: data && getBusinessPartnerPolicyDescription(data),
              }}
            ></p>
          </>
        </div>
        {data?.refund_cancellation_policy_sub_content_data?.map(
          (data, index) => (
            <div key={index} className="row mt-5 mb-5">
              {data?.sub_content_image_url && (
                <>
                  <div
                    className={`col-md-6 ${
                      index % 2 === 0 ? "" : "order-md-2"
                    }`}
                  >
                    <CustomImage
                      src={data?.sub_content_image_url}
                      className="img-fluid"
                      alt="sub_content_image_url"
                      width={"unset"}
                      height={"unset"}
                    />
                  </div>
                  <div
                    className={`col-md-6 ${
                      index % 2 === 0 ? "" : "order-md-1"
                    }`}
                  >
                    <div>
                      <p className="sub-heading-content">
                        {getSubContentSubHeading(data)}
                      </p>
                      <p
                        className="description_in_thai"
                        dangerouslySetInnerHTML={{
                          __html: getSubContentDesc(data),
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              {!data?.sub_content_image_url && (
                <div className="col-md-12">
                  <div>
                    <p className="sub-heading-content">
                      {getSubContentSubHeading(data)}
                    </p>
                    <p
                      className="description_in_thai"
                      dangerouslySetInnerHTML={{
                        __html: getSubContentDesc(data),
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )
        )}
      </div>
      <Footer />
    </>
  );
};
export default CancelPolicyPage;
