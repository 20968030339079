import './ReferrelPage.css';
import { React, PropTypes, Locale, useSelector } from '../../libraries';
import { VendorHeader } from '../../vendor/pages';
import { ReferralImage, BackgroundImage } from '../../assets/Images';
import { ShareButtons, Heading, CopytoClipboard } from '../../common';
import { CustomImage } from '../../lazyComponent/index.js';
const ReferralPage = () => {
  const userReferralsDetails = useSelector(
    (state) => state?.userReferralsDetails?.userReferralsDetails,
  );
  const { t } = Locale();
  const shareUrl = `${userReferralsDetails?.referred_amount}`;
  const title = 'Referral Code';
  const deletionRequest = localStorage.getItem('status') === 'requested';

  return (
    <>
      <div
        className={`heading-outer-div user-outer-div ${deletionRequest && 'deletionRequest-user-header'}`}
      >
        <VendorHeader />
        <Heading
          text={t('userEdit.referral')}
          icon="bi-megaphone"
        />
        <div className="after-heading-outer-div">
          <div className="user-common-box">
            <div className="user-common-box-inner">
              <div className="scroller-container services-table-inner-div user-inner-content-scrollar">
                <div className="section">
                  <h4>
                    {t('userEdit.get')} ฿{userReferralsDetails?.referred_amount}{' '}
                    {t('userEdit.referralMsg')}
                  </h4>
                  <div className="steps">
                    <CustomImage
                      src={ReferralImage}
                      className="img-fluid"
                      alt={'Referral_Image'}
                      width={'unset'}
                      height={'unset'}
                    />
                  </div>
                  <div className="description">
                    <p>
                      {t('userEdit.giveFriends')} ฿
                      {userReferralsDetails?.referred_amount}{' '}
                      {t('userEdit.shareFriend')}
                    </p>
                    <p>
                      {t('userEdit.and')} {t('userEdit.get')} ฿
                      {userReferralsDetails?.referred_amount}{' '}
                      {t('userEdit.getCashback')}
                    </p>
                  </div>
                  <div className="mt-3">
                    <CopytoClipboard
                      referralCode={userReferralsDetails?.referral_code}
                    />
                  </div>
                  <div className="share-buttons">
                    <ShareButtons url={shareUrl} title={title} />
                  </div>
                  <CustomImage
                    alt="background_image"
                    src={BackgroundImage}
                    className="referal-back-image"
                    width={'unset'}
                    height={'unset'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
ReferralPage.propTypes = {
  referralCode: PropTypes.string,
  referralAmount: PropTypes.number,
};
export default ReferralPage;