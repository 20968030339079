import { apiCall } from './base.js';
import { signIn, signUp } from '../Routes/routes.js';

const signUpApi = async (params) => {
  const response = await apiCall(`authentication${signUp}`, 'POST', params);
  localStorage.setItem('access_token', response.data.access_token);
  return response;
};

const login = async (params) => {
  let param = { email: params.username, password: params.password };
  const response = await apiCall(`authentication${signIn}`, 'POST', param);
  localStorage.setItem('access_token', response.data.access_token);
  return response;
};

const deleteUser = async (params) => {
  const response = await apiCall(
    'authentication/delete_request',
    'PATCH',
    params,
  );
  return response;
};

const cancelDeleteUserRequest = async (params) => {
  const response = await apiCall(
    'authentication/cancel_request',
    'PATCH',
    params,
  );
  return response;
};

const accountInformation = async () => {
  const response = await apiCall('authentication/user_details', 'GET');
  return response;
};

const editProfile = async (params) => {
  const response = await apiCall('customer/me', 'PATCH', params);
  return response;
};


const getBookings = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await apiCall(`customer/bookings?${queryString}`, 'GET');
  return response;
};

const getBookingBasedOnServiceId = async (param) => {
  const isCustomer = localStorage.getItem("role") === "Customer"
  let response;
  if (isCustomer) {
    response = await apiCall(`customer/bookings?booking_service_id=${param}`, 'GET');
  }
  else {
    response = await apiCall(`business/bookings?booking_service_id=${param}`, 'GET');
  }
  return response;
}

const getBookingByUserId = async (params) => {
  const response = await apiCall(`customer/bookings/${params}`, 'GET');
  return response;
};

const forgetPassword = async (params) => {
  const response = await apiCall(
    'authentication/forget_password',
    'POST',
    params,
  );
  localStorage.setItem('access_token', response.data.access_token);
  localStorage.setItem('email', response.data.access_token);

  return response;
};

const codeVerification = async (params) => {
  const response = await apiCall(
    'authentication/code_verification',
    'POST',
    params,
  );
  return response;
};
const UpdateForgetPassword = async (params) => {
  const response = await apiCall(
    'authentication/reset_password',
    'PATCH',
    params,
  );
  return response;
};
const Logout = async (params) => {
  const myItem = localStorage.getItem('i18nextLng');
  const myLang = localStorage.getItem('selectedLanguage');
  const cartCount = localStorage.getItem('cartCount');

  const response = await apiCall('authentication/logout', 'POST', params);
  localStorage.clear();
  if (myItem !== null && myItem !== undefined) {
    localStorage.setItem('i18nextLng', myItem);
  }
  if (myLang !== null && myLang !== undefined) {
    localStorage.setItem('selectedLanguage', myLang);
  }
  if (cartCount !== null && cartCount !== undefined) {
    localStorage.setItem('cartCount', cartCount);
  }

  return response;
};

const googleSignIn = async (params) => {
  const response = await apiCall('authentication/google', 'POST', params);
  // localStorage.setItem("access_token", response.data.access_token);
  return response;
};
const facebookSignIn = async (params) => {
  const response = await apiCall('authentication/facebook', 'POST', params);
  return response;
};

const changePassword = async (params) => {
  const response = await apiCall(
    'authentication/change_password',
    'PATCH',
    params,
  );
  return response;
};

const showUserDetails = async () => {
  const response = await apiCall('authentication/user_details', 'GET');
  return response;
};

export {
  signUpApi,
  getBookingByUserId,
  accountInformation,
  cancelDeleteUserRequest,
  login,
  deleteUser,
  editProfile,
  getBookings,
  forgetPassword,
  codeVerification,
  UpdateForgetPassword,
  Logout,
  googleSignIn,
  facebookSignIn,
  changePassword,
  showUserDetails,
  getBookingBasedOnServiceId
};
