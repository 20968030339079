import { apiCall } from '../base.js';

const vendorFetchProfileDetails = async () => {
  const response = await apiCall('authentication/user_details', 'GET');
  return response;
};

const vendorEditMyProfile = async (params) => {
  const response = await apiCall('business/user', 'PATCH', params);
  return response;
};

const vendorOrgnizationEdit = async (params) => {
  const response = await apiCall('business/organization', 'PATCH', params);
  return response;
};
const vendorOrgnizationInformation = async () => {
  const response = await apiCall('business/organizations', 'GET');
  return response;
};

const createBillingInformation = async (params) => {
  const response = await apiCall(
    'business/billing_informations',
    'POST',
    params,
  );
  return response;
};

const updateBillingInformation = async (params) => {
  const response = await apiCall(
    '/business/billing_informations',
    'PUT',
    params,
  );
  return response;
};

const fetchBillingInformation = async () => {
  const response = await apiCall('business/billing_informations', 'GET');
  return response;
};
const delete_image = async (id) => {
  const role = localStorage.getItem('role');
  let param = {
    role: role,
  };
  const response = await apiCall(
    `/authentication/images/${id}`,
    'DELETE',
    param,
  );
  return response;
};

const organizationStatus = async () => {
  const response = await apiCall(
    '/authentication/organization_verification',
    'GET',
  );
  return response;
};

const getCalculationPickAndTotalAmount = async (params) => {
  const response = await apiCall(
    `customer/bookings/calculation`,
    'POST',
    params,
  );
  return response;
};

const dataForUniqueId = async (params) => {
  const response = await apiCall(`customer/checkout_details?checkout_url=${params}`, 'GET');
  return response;
};

export {
  getCalculationPickAndTotalAmount,
  organizationStatus,
  updateBillingInformation,
  vendorOrgnizationInformation,
  vendorFetchProfileDetails,
  vendorEditMyProfile,
  fetchBillingInformation,
  createBillingInformation,
  vendorOrgnizationEdit,
  delete_image,
  dataForUniqueId
};
