import { React, PropTypes, Avatar, UserOutlined, useState } from '../../../../libraries/index';
import { User } from '../../../../assets/Images';

const CustomCheckboxWithImage = ({ imageSrc, personName }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="d-flex text-center">
      <span
        className={!isHovered ? 'customteamMember' : 'customteamMemberOnHover'}
      >
        <Avatar
          size={24}
          icon={<UserOutlined />}
          className="CustomCheckboxWithImage-img"
          src={imageSrc ? imageSrc : User}
        />
        <span
          className="teamperson-checkbox-span"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered || window.innerWidth <= 767
            ? personName
            : personName.length > 10
              ? `${personName.substring(0, 8)}..`
              : personName}
        </span>
      </span>
    </div>
  );
};

CustomCheckboxWithImage.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  personName: PropTypes.string.isRequired,
};

export default CustomCheckboxWithImage;
