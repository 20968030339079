import { apiCall } from './base';

const getHomeDetail = async () => {
  const response = await apiCall('public/landing_sections', 'GET');
  return response;
};

const getFooterDetails = async () => {
  const response = await apiCall('footer/contact_details', 'GET');
  return response;
};

const getSocialLink = async()=>{
  const response = await apiCall('public/social_media_links', 'GET');
  return response;
}

const creteNewsLetter = async (params) => {
  const response = await apiCall('public/news_letters', 'POST', params)
  return response;
}
export { getHomeDetail, getFooterDetails, creteNewsLetter,getSocialLink };
