import './OpeningHoursPolicies.css';
import {
  React,
  useEffect,
  useState,
  Locale,
  Select,
  Option,
  PropTypes
} from '../../../libraries/index';
import { CustomButton } from '../../../common/customButton/customButton';
import { CustomSpinner, Small } from '../../../common/index';
import { handleChangeValue, handleSubmit, AccountSettingHeaders, } from '../../component/index';
import { InputField, Heading } from '../../../common';
import { VendorHeader } from '../index.js';
const OpeningHoursPolicie = ({
  isLoader,
  openingHourseFormValue,
  setOpeningHourseFormValue,
}) => {
  const { t } = Locale();
  const [value, setValue] = useState();
  const [day, setDay] = useState();
  const [lunchValue, setLunchValue] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [err, setErr] = useState({});
  const language = localStorage.getItem('i18nextLng');
  const [thaiDay, setThaiDay] = useState();

  useEffect(() => {
    let changeDay = [
      t('vendor.Monday'),
      t('vendor.Tuesday'),
      t('vendor.Wednesday'),
      t('vendor.Thursday'),
      t('vendor.Friday'),
      t('vendor.Saturday'),
      t('vendor.Sunday'),
    ];
    let newDay = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    let newValue = [
      '00:00',
      '00:30',
      '01:00',
      '01:30',
      '02:00',
      '02:30',
      '03:00',
      '03:30',
      '04:00',
      '04:30',
      '05:00',
      '05:30',
      '06:00',
      '06:30',
      '07:00',
      '07:30',
      '08:00',
      '08:30',
      '09:00',
      '09:30',
      '10:00',
      '10:30',
      '11:00',
      '11:30',
      '12:00',
      '12:30',
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
      '18:00',
      '18:30',
      '19:00',
      '19:30',
      '20:00',
      '20:30',
      '21:00',
      '21:30',
      '22:00',
      '22:30',
      '23:00',
      '23:30',
    ];

    let newLunchValue = [
      `00:00 ${t('vendor.hour')}`,
      `00:30 ${t('vendor.hour')}`,
      `01:00 ${t('vendor.hour')}`,
      `01:30 ${t('vendor.hour')}`,
      `02:00 ${t('vendor.hour')}`,
      `02:30 ${t('vendor.hour')}`,
      `03:00 ${t('vendor.hour')}`,
      `03:30 ${t('vendor.hour')}`,
      `04:00 ${t('vendor.hour')}`,
      `04:30 ${t('vendor.hour')}`,
      `05:00 ${t('vendor.hour')}`,
    ];
    setValue(newValue);
    setDay(newDay);
    setLunchValue(newLunchValue);
    setThaiDay(changeDay);
  }, [language, t]);

  const { bussiness_hours, breakTime, paymentOption } = {
    openingHourseFormValue,
  };

  return (
    <>
      <div className="heading-outer-div">
        <VendorHeader />
        <Heading text={t('vendor.accountSetting')} icon={'bi bi-shop-window'} />
        <div className="after-heading-outer-div">
          <div className="user-common-box">
            <div className="user-common-box-inner">
              <div className="user-common-box-fix-content row-box m-0 mb-3">
                <AccountSettingHeaders />
              </div>
              <div className="container-fluid mt-2 user-inner-content-scrollar user-inner-content-scrollar-after-fix-content">
                <div className="OpeningHoursPolicies_BuisnesHourOuterDiv p-2">
                  <h5 className="mt-2 opening-hours-outer-heading">
                    {t('vendor.bussinessHours')}
                  </h5>
                  <div className="row ">
                    <div className="opening-hourse-p-tag">
                      {t('vendor.note')}
                    </div>
                    <Small name={bussiness_hours} err={err.bussiness_hours} />
                  </div>
                  <div className=" OpeningHoursPolicies_DayWise_Time">
                    {day &&
                      day.length >= 0 &&
                      day.map((currentDay, key) => (
                        <div
                          className="row mt-2 opening-hourse-buisssnes-hourse-outer-row"
                          key={key}
                        >
                          <div className="col-4 openingHourseDayDiv mt-2">
                            <input
                              type="checkbox"
                              className="form-check-input openingHourse-day-checkbox"
                              id={`myCheckbox-${currentDay}`}
                              name={`bussiness_hours[${currentDay.toLowerCase()}].shop_open`}
                              checked={
                                openingHourseFormValue.bussiness_hours?.[
                                currentDay.toLowerCase()
                                ]?.['shop_open'] === 'true' ||
                                openingHourseFormValue.bussiness_hours?.[
                                currentDay.toLowerCase()
                                ]?.['shop_open'] === true
                              }
                              onChange={(e) =>
                                handleChangeValue(
                                  e,
                                  setOpeningHourseFormValue,
                                  '',
                                  '',
                                  setErr,
                                )
                              }
                            />
                            <span
                              className="openign-hourse-span-current-day cursor-point-class"
                              onClick={() => {
                                const currentValue =
                                  openingHourseFormValue.bussiness_hours?.[
                                  currentDay.toLowerCase()
                                  ]?.['shop_open'];
                                setOpeningHourseFormValue((prevState) => ({
                                  ...prevState,
                                  bussiness_hours: {
                                    ...prevState?.bussiness_hours,
                                    [currentDay.toLowerCase()]: {
                                      ...prevState?.bussiness_hours?.[
                                      currentDay?.toLowerCase()
                                      ],
                                      shop_open: !currentValue,
                                    },
                                  },
                                }));
                              }}
                            >
                              {thaiDay[key]}
                            </span>
                          </div>
                          <div
                            className={'col-3 a vendor-opening-horse-open-time'}
                          >
                            <Select
                              className="opening-hours-select-tag-open"
                              placeholder={t('header.select')}
                              disabled={
                                (openingHourseFormValue.bussiness_hours &&
                                  openingHourseFormValue.bussiness_hours[
                                    currentDay.toLowerCase()
                                  ]?.shop_open === 'true') ||
                                  openingHourseFormValue.bussiness_hours?.[
                                    currentDay.toLowerCase()
                                  ]?.shop_open === true
                                  ? false
                                  : true
                              }
                              value={
                                openingHourseFormValue.bussiness_hours &&
                                openingHourseFormValue.bussiness_hours[
                                  currentDay.toLowerCase()
                                ]?.open
                              }
                              onChange={(value) =>
                                handleChangeValue(
                                  {
                                    target: {
                                      name: `bussiness_hours[${currentDay.toLowerCase()}].open`,
                                      value,
                                    },
                                  },
                                  setOpeningHourseFormValue,
                                  '',
                                  '',
                                  setErr,
                                )
                              }
                            >
                              {value.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-2 a opening-hourse-to-div text-center">
                            {t('vendor.to')}
                          </div>
                          <div className="col-3 a vendor-opening-horse-close-time">
                            <Select
                              className="opening-hours-select-tag-open"
                              disabled={
                                (openingHourseFormValue.bussiness_hours &&
                                  openingHourseFormValue.bussiness_hours[
                                    currentDay.toLowerCase()
                                  ]?.shop_open === 'true') ||
                                  openingHourseFormValue.bussiness_hours?.[
                                    currentDay.toLowerCase()
                                  ]?.shop_open === true
                                  ? false
                                  : true
                              }
                              value={
                                openingHourseFormValue.bussiness_hours &&
                                openingHourseFormValue.bussiness_hours[
                                  currentDay.toLowerCase()
                                ]?.close
                              }
                              onChange={(value) =>
                                handleChangeValue(
                                  {
                                    target: {
                                      name: `bussiness_hours[${currentDay.toLowerCase()}].close`,
                                      value,
                                    },
                                  },
                                  setOpeningHourseFormValue,
                                  '',
                                  '',
                                  setErr,
                                )
                              }
                              placeholder={t('header.select')}
                            >
                              {value
                                .filter(
                                  (item) =>
                                    openingHourseFormValue &&
                                    openingHourseFormValue?.bussiness_hours &&
                                    item >
                                    openingHourseFormValue?.bussiness_hours[
                                      currentDay.toLowerCase()
                                    ]?.open,
                                )
                                .map((item) => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="OpeningHoursPolicies_service_my_loacationDiv checkbox-outer mt-4">
                    <div className="row">
                      <div className="col-lg-4 col-md-12">
                        <div className="row">
                          <div className="col-1">
                            <input
                              type="checkbox"
                              className="form-check-input openingHourse-day-checkbox"
                              id="myCheckboxServiceMyLocaton"
                              name="serviceMyLocation"
                              checked={openingHourseFormValue.serviceMyLocation}
                              onChange={(e) =>
                                handleChangeValue(
                                  e,
                                  setOpeningHourseFormValue,
                                  '',
                                  '',
                                  setErr,
                                )
                              }
                            />
                          </div>
                          <div className="col-10">
                            <label
                              htmlFor="myCheckboxServiceMyLocaton"
                              className="vendor-opening-hours-p-tag-inner cursor-pointer"
                            >
                              {t('vendor.offerServiceMy')}
                            </label>
                          </div>
                        </div>
                        <Small
                          name={'serviceLocation'}
                          err={err.serviceLocation}
                        />
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="row">
                          <div className="col-1">
                            <input
                              type="checkbox"
                              className="form-check-input openingHourse-day-checkbox"
                              id="myCheckboxServiceCustomer"
                              name="serviceCustomerLocation"
                              checked={
                                openingHourseFormValue.serviceCustomerLocation
                              }
                              onChange={(e) =>
                                handleChangeValue(
                                  e,
                                  setOpeningHourseFormValue,
                                  '',
                                  '',
                                  setErr,
                                )
                              }
                            />
                          </div>
                          <div className="col-10 ">
                            <label
                              htmlFor="myCheckboxServiceCustomer"
                              className="vendor-opening-hours-p-tag-inner cursor-pointer"
                            >
                              {t('vendor.offerServiceCustomer')}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="row">
                          <div className="col-lg-4  col-md-2 col-sm-3  vendor-opening-hours-p-tag-inner-div">
                            <p className="vendor-opening-hours-p-tag-inner">
                              {t('vendorField.breakTime')}
                              <small className="small-err">*</small>
                            </p>
                          </div>
                          <div className="col-lg-5 col-md-2 col-sm-2  breaktime-select-tag">
                            <Select
                              className="opening-hours-select-tag-open"
                              placeholder={t('header.select')}
                              value={
                                openingHourseFormValue.breakTime === 'null'
                                  ? undefined
                                  : openingHourseFormValue.breakTime
                              }
                              onChange={(value) =>
                                handleChangeValue(
                                  {
                                    target: { name: 'breakTime', value: value },
                                  },
                                  setOpeningHourseFormValue,
                                  '',
                                  '',
                                  setErr,
                                )
                              }
                            >
                              {lunchValue &&
                                lunchValue.map((item) => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                          <Small name={breakTime} err={err.breakTime} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="OpeningHoursPolicies_Payment_Option_Div mt-3">
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-3">
                        <div className="vendor-opening-hours-p-tag-inner">
                          {t('vendor.paymentOption')}
                          <small className="small-err">*</small>
                        </div>
                      </div>
                      <div className="col-lg-3  col-md-3 col-sm-4 ml-5  OpeningHours24hoursDiv OpeningHourse_Common">
                        <input
                          type="checkbox"
                          className="form-check-input mt-1 openingHourse-day-checkbox"
                          id="Cash_payment"
                          name="cash_payment"
                          checked={openingHourseFormValue.cash_payment}
                          onChange={(e) =>
                            handleChangeValue(
                              e,
                              setOpeningHourseFormValue,
                              '',
                              '',
                              setErr,
                            )
                          }
                        />
                        <label
                          className="form-check-label openingHourse_CommonClass2"
                          htmlFor="Cash_payment"
                        >
                          {t('vendor.cashPayment')}
                        </label>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-3 ml-5 OpeningHourse_Common">
                        <input
                          type="checkbox"
                          className="form-check-input mt-1 openingHourse-day-checkbox"
                          id="Pay_online"
                          name="pay_online"
                          checked={openingHourseFormValue.pay_online}
                          onChange={(e) =>
                            handleChangeValue(
                              e,
                              setOpeningHourseFormValue,
                              '',
                              '',
                              setErr,
                            )
                          }
                        />
                        <label
                          className="form-check-label openingHourse_CommonClass2"
                          htmlFor="Pay_online"
                        >
                          {t('vendor.onlinePay')}
                        </label>
                      </div>
                      <Small name={paymentOption} err={err.paymentOption} />
                    </div>
                  </div>
                  <div className="OpeningHoursPolicies_Days_valid_after_purchase mt-2">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 OpeningHoursPolicies_Days_valid_after_purchase_inner-div">
                        <div>
                          <span className="vendor-opening-hours-p-tag-inner d-block">
                            {t('ServiceField.cancelDay')}
                          </span>
                          <small className="d-block">
                            ({t('vendor.leaveBlank')})
                          </small>
                        </div>
                        <div className="mt-2">
                          <InputField
                            type="number"
                            placeholder={t('ServiceField.cancelDay')}
                            className="opening-hours-number-field form-control vendor-account-details-input-field"
                            min="1"
                            max="90"
                            setFormValue={setOpeningHourseFormValue}
                            setErr={setErr}
                            onChange={handleChangeValue}
                            name="dayValidAfterPurchase"
                            value={openingHourseFormValue.dayValidAfterPurchase}
                          />
                        </div>
                        <Small
                          name={'dayValidAfterPurchase'}
                          err={err.dayValidAfterPurchase}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 OpeningHoursPolicies_Days_valid_after_purchase_inner-div">
                        <div>
                          <span className="vendor-opening-hours-p-tag-inner d-block">
                            {t('ServiceField.rescheduling')}
                          </span>
                          <small className="d-block">
                            ({t('vendor.leaveBlank')})
                          </small>
                        </div>
                        <div className="mt-2">
                          <InputField
                            field="numberField"
                            type="number"
                            placeholder={t('ServiceField.rescheduling')}
                            className="form-control opening-hours-number-field vendor-account-details-input-field"
                            min="1"
                            max="90"
                            setFormValue={setOpeningHourseFormValue}
                            onChange={handleChangeValue}
                            setErr={setErr}
                            name="cancelDayBeforeScheduleDate"
                            value={
                              openingHourseFormValue.cancelDayBeforeScheduleDate
                            }
                          />
                          <Small
                            name={'cancelDayBeforeScheduleDate'}
                            err={err.cancelDayBeforeScheduleDate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="OpeningHoursPolicies_Must_cancel_days_before_scheduled mt-5"></div>
                  <div className="accountDetails_saveButton_Div mt-5 mb-1">
                    <CustomButton
                      className="btn "
                      name={t('button.saveButton')}
                      onClick={handleSubmit}
                      formValue={openingHourseFormValue}
                      setErr={setErr}
                      formType="openingHourse"
                      disabled={buttonDisabled}
                      setButtonDisabled={setButtonDisabled}
                      t={t}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoader && (
        <>
          <CustomSpinner />
        </>
      )}
    </>
  );
};
OpeningHoursPolicie.propTypes = {
  openingHourseFormValue: PropTypes.shape({
    bussiness_hours: PropTypes.object,
    breakTime: PropTypes.string,
    cancellationPolicy: PropTypes.number,
    paymentOption: PropTypes.bool,
    serviceMyLocation: PropTypes.bool,
    serviceCustomerLocation: PropTypes.bool,
    dayValidAfterPurchase: PropTypes.number,
    cancelDayBeforeScheduleDate: PropTypes.number,
    cash_payment: PropTypes.bool,
    pay_online: PropTypes.bool,
  }),
  setOpeningHourseFormValue: PropTypes.func,
};

export default OpeningHoursPolicie;