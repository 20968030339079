import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Locale } from "../../libraries";
import { NavbarSearch } from "../../components";
import  MenuSlider  from "../../pages/Home/MenuSlider/MenuSlider";
import { editProfile, shopNearMe, user } from "../../Routes/routes";
import  Notifications  from "../notification/Notifications";

export const CustomDropDownCategories = ({
  onBestSellerEnter,
  menuRef,
  overlayRef,
  menuClick,
  subCategoryClickVisible,
  handleCategoryClickFun,
  items,
  onCategoryClick,
  subCategoriesDatas,
  onSubCategoryClick,
  handleMouseLeave,
}) => {
  const { t } = Locale();

  const selectedLanguage = localStorage.getItem("i18nextLng");
  const userName = localStorage.getItem("name");
  const role = localStorage.getItem("role");
  const [isBackBtn, setIsBackBtn] = useState(false);

  const mobileMenuHeadRef = useRef(null);
  const submenu1Ref = useRef({});
  useEffect(() => {
    submenu1Ref.current = {};
  }, []);

  useEffect(() => {
    let lastScrollTop = 0;
    const delta = 5;
    const headerElement = document.querySelector("header");
    const navbarHeight = headerElement.offsetHeight;

    const handleScroll = () => {
      const st = window.scrollY;
      if (Math.abs(lastScrollTop - st) <= delta) return;

      if (st > lastScrollTop && st > navbarHeight) {
        headerElement.classList.remove("nav-down");
        headerElement.classList.add("nav-up");
      } else {
        if (st + window.innerHeight < document.documentElement.scrollHeight) {
          headerElement.classList.remove("nav-up");
          headerElement.classList.add("nav-down");
        }
      }
      lastScrollTop = st;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [title, setTitle] = useState(t("ServiceField.category"));
  const [visibleSlide, setVisibleSlide] = useState(false);

  const [activeItemId, setActiveItemId] = useState(null); // To track the active menu item

  const handleItemClick = (itemId) => {
    setActiveItemId(itemId);
  };

  const showDrawer = () => {
    setVisibleSlide(true);
  };
  const navigate = useNavigate();
  const handleNearbyShopClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          navigate(`${shopNearMe}?lat=${latitude}&lng=${longitude}`);
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  const displayName = (role) => {
    if (role === "freelancer" || role === "Vendor") {
      return localStorage.getItem("name");
    }
    if (role === "Customer") {
      return userName;
    } else {
      return "SuraUser";
    }
  };

  const navigateProfile = () => {
    navigate(`${user}${editProfile}`);
  };

  const hideSubMenu = () => {
    setTitle("Category");
    setIsBackBtn(false);
    Object.values(submenu1Ref?.current).forEach((ref) =>
      ref?.classList?.remove("active")
    );
  };

  const showSubMenu = (categoryId, title, title_in_thai) => {
    setIsBackBtn(true);

    selectedLanguage === "en" ? setTitle(title) : setTitle(title_in_thai);
    submenu1Ref?.current[categoryId]?.classList?.add("active");
  };

  return (
    <header className="header2">
      <div>
        {(role === "Customer" || !role) && (
          <div className="row d-flex justify-content-center">
            <div className="header-item d-flex justify-content-center">
              <div className="menu-overlay" ref={overlayRef}></div>
              <nav className="menu CustomDropDownCategories-nav" ref={menuRef}>
                <div className="mobile-menu-head" ref={mobileMenuHeadRef}>
                  <div
                    className={`go-back ${!isBackBtn && "disable-go-back"}`}
                    onClick={hideSubMenu}
                  >
                    <i className="bi bi-arrow-left"></i>
                  </div>
                  <div className="current-menu-title colorWhite">{title}</div>
                  <div
                    className="mobile-menu-close"
                    id="closeBtn-category-module"
                    onClick={menuClick}
                  >
                    &times;
                  </div>
                </div>
                <ul className="menu-main">
                  {items.map((item) => (
                    <li
                      key={item.id}
                      className={`menu-item-has-children ${
                        activeItemId === item.id ? "active" : ""
                      }`}
                      onClick={(e) => {
                        handleItemClick(item.id);
                        !subCategoryClickVisible
                          ? handleCategoryClickFun(
                              e,
                              item.name_en,

                              item.name_en,
                              item.name_thai
                            )
                          : showSubMenu(item.id, item.name_en, item.name_thai);
                      }}
                      onMouseEnter={() =>
                        onCategoryClick(item.id, item.name_en, item.name_thai)
                      }
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <Link className="category">
                        {selectedLanguage === "thai" &&
                        selectedLanguage !== undefined &&
                        selectedLanguage !== null
                          ? item.name_thai
                          : item.name_en}
                        &nbsp;&nbsp;<i className="bi bi-chevron-down"></i>
                      </Link>
                      {subCategoriesDatas?.length > 0 && (
                        <>
                          <div
                            className="sub-menu single-column-menu"
                            ref={(el) => (submenu1Ref.current[item.id] = el)}
                          >
                            <ul className="submenu-main">
                              {subCategoriesDatas.map((subItem) => (
                                <li
                                  key={subItem.id}
                                  className="menu-item-has-children colorBlack"
                                  onClick={(e) =>
                                    onSubCategoryClick(
                                      e,
                                      subItem.id,
                                      subItem.name_en,
                                      subItem.name_thai
                                    )
                                  }
                                >
                                  <Link>
                                    {selectedLanguage === "thai" &&
                                    selectedLanguage !== undefined &&
                                    selectedLanguage !== null
                                      ? subItem.name_thai
                                      : subItem.name_en}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                  <li
                    className="menu-item-has-children best-seller"
                    onClick={(e) => {
                      !subCategoryClickVisible
                        ? handleCategoryClickFun(
                            e,
                            "Best Sellers",
                            "Best Sellers",
                            "ขายดี"
                          )
                        : showSubMenu("00", "Best Sellers", "ขายดี");
                    }}
                    onMouseEnter={() =>
                      onBestSellerEnter("36", "Best Sellers", "ขายดี")
                    }
                  >
                    <Link>
                      {t("breadcrumb.bestSeller")}&nbsp;&nbsp;
                      <i className="bi bi-chevron-down"></i>
                    </Link>
                    {subCategoriesDatas?.length > 0 && (
                      <>
                        <div className="sub-menu single-column-menu">
                          <ul className="submenu-main">
                            {subCategoriesDatas.map((subItem) => (
                              <li
                                key={subItem.id}
                                className="menu-item-has-children colorBlack"
                                onClick={(e) =>
                                  onSubCategoryClick(
                                    e,
                                    subItem.id,
                                    subItem.name,
                                    subItem.name_thai
                                  )
                                }
                              >
                                <Link>
                                  {selectedLanguage === "thai" &&
                                  selectedLanguage !== undefined &&
                                  selectedLanguage !== null
                                    ? subItem.name_thai
                                    : subItem.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
            <div className="header-item item-right">
              <div className="mobile-view-search-fild-outer-div">
                {visibleSlide && (
                  <MenuSlider
                    displayName={displayName}
                    handleNearbyShopClick={handleNearbyShopClick}
                    visibleSlide={visibleSlide}
                    setVisibleSlide={setVisibleSlide}
                  />
                )}
                <div className="menubar">
                  <div className={`iPad_barsMenu`}>
                    <span
                      className={`iPad_barsBtn`}
                      onClick={showDrawer}
                    ></span>
                  </div>
                </div>
                <NavbarSearch
                  type="search"
                  placeholder={`\u00A0${t("header.placeholder")}`}
                  name="search"
                />
                {role && (
                  <>
                    &nbsp;&nbsp;
                    <div className="notification d-flex">
                      <div className="navbar-cart-div">
                        <Notifications />
                      </div>
                    </div>
                    &nbsp;&nbsp;
                    <div className="user-icon" onClick={navigateProfile}>
                      <i class="bi bi-person"></i>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};