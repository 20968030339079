import { React, useNavigate, FacebookLoginButton, LoginSocialFacebook } from '../../libraries';
import { handleErrorResponse, handleSuccessResponse, showUserDetails, facebookSignIn,showCartList } from '../../services';
import { Profile, bussiness, dashboard, home } from '../../Routes/routes';

const Facebook = ({ className, text }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={className}>
        <LoginSocialFacebook
          appId="384031517363206"
          onResolve={async (res) => {
            try {
              const response = await facebookSignIn({ token: res.data });
              if (response && response.data.access_token) {
                let cartResponse = [];
                try {
                  cartResponse = await showCartList();
                }
                catch (err) {

                }
                let cartCount = cartResponse?.data?.data?.length;
                localStorage.setItem(
                  'access_token',
                  response?.data?.access_token,
                );
                let getShowResponse = await showUserDetails();
                localStorage.setItem('role', getShowResponse?.data?.user?.type);
                localStorage.setItem(
                  'status',
                  getShowResponse?.data?.user?.status,
                );
                let name = getShowResponse?.data?.user?.first_name
                  ? getShowResponse?.data?.user?.first_name
                  : '';
                let lastName = getShowResponse.data.user.last_name
                  ? getShowResponse?.data?.user?.last_name
                  : '';
                let fullName = name + ' ' + lastName;
                localStorage.setItem('name', fullName);
                const newCartCount = Math.max(cartCount || 0);
                localStorage.setItem('cartCount', newCartCount);
                localStorage.setItem('userId', getShowResponse.data.user.id);
                localStorage.setItem(
                  'profilePhoto',
                  getShowResponse.data.image_url
                    ? getShowResponse.data.image_url
                    : '',
                );
                handleSuccessResponse(response, response?.data?.message);
                if (getShowResponse.data.user.type === 'Vendor') {
                  localStorage.setItem('isSection', 'isDashboard');
                  navigate(`${bussiness}${dashboard}`);
                } else if (getShowResponse?.data?.user?.type === 'TeamMember') {
                  localStorage.setItem('isSection', 'isVendorProfile');
                  navigate(`${bussiness}${Profile}`);
                } else {
                  navigate(home);
                }
              } else {
                console.log('invalid users');
              }
            } catch (err) {
              handleErrorResponse(err);
            }
          }}
          onReject={(err) => {
            console.error('Facebook login rejected:', err);
          }}
          redirectUri="http://192.168.1.44:3000/"
        >
          <FacebookLoginButton
            className={`sign-in-facebook-btn ${className}`}
            style={{ height: '2rem', fontSize: '13px', width: '11rem' }}
            text={text}
          />
        </LoginSocialFacebook>
      </div>
    </>
  );
};
export default Facebook;