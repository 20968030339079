import { Locale, useState, useEffect, useNavigate } from '../../../libraries/index';
import { editBookings, handleErrorResponse, handleSuccessResponse, getBookingByUserId, getBookings } from '../../../services';
import { useDebouncedValue } from '../../../jsonData/debouncingFunction';
import { home, serviceDescription, user } from '../../../Routes/routes.js';
import { organizationUrlFormat, urlFormat } from '../../../customHooks/urlFormat.js';
import { encryptData } from '../../../customHooks/urlEncyption.js';
const useBookingServicesUserData = () => {
  const navigate = useNavigate();
  const { t } = Locale();
  const th = localStorage.getItem('i18nextLng');
  const [bookingsTab, setBookingsTab] = useState('all');
  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalePage] = useState(0);
  const [searchServices, setSearchServices] = useState('');
  const [error, setErr] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [bookingItemId, setBookingItemId] = useState(null);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [clickToconfirm, setClickConfirm] = useState(false);
  const [bookingToEdit, setBookingToEdit] = useState();
  const [editBookingModalOpen, setEditBookingModalOpen] = useState(false);
  const [filterValue, setFilterValue] = useState({
    bookings: '',
    bookingId: '',
    serviceName: '',
    date: '',
    duration: '',
    amount: '',
    status: '',
    paymentType: '',
    discount: '',
    peakOffDiscount: '',
    cashback: '',
    totalAmount: '',
    assignStaff: '',
    serviceLocation: '',
    organizationName: '',
    time: '',
    createdTime: '',
  });
  const [previousMinute, setPreviousMinute] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const [callFilter, setCallFilter] = useState(false);
  const [isComplte, setIsComplte] = useState(false);
  const itemsPerPage = 15;
  const debouncedSearchTerm = useDebouncedValue(filterValue, 500);
  const columnHeadings = [
    { label: t('venderBooking.id'), className: 'thclass-booking-user' },
    { label: t('favouritePage.service'), className: 'thclass-booking-user' },
    { label: t('favouritePage.shop'), className: 'thclass-booking-user' },
    { label: t('ServiceField.duration'), className: 'thclass-booking' },
    { label: t('calender.createTime'), className: 'thclass-booking' },
    { label: t('userBooking.scheduleAt'), className: 'thclass-booking-user' },
    { label: t('ServiceField.booking_time'), className: 'thclass-booking' },
    {
      label: t('venderBooking.paidAmount'),
      className: 'thclass-booking-user-number',
    },
    {
      label: t('venderBooking.discount'),
      className: 'thclass-booking-user-number',
    },
    {
      label: t('venderBooking.peakDiscount'),
      className: 'thclass-booking-user-number',
    },
    {
      label: t('venderBooking.cashback'),
      className: 'thclass-booking-user-number',
    },
    {
      label: t('venderBooking.totalAmount'),
      className: 'thclass-booking-user-number',
    },
    {
      label: t('venderBooking.assignTeamMember'),
      className: 'thclass-booking-user',
    },
    {
      label: t('venderBooking.serviceLocation'),
      className: 'thclass-booking-user',
    },
    {
      label: t('venderBooking.paymentType'),
      className: 'thclass-booking-user vendor-payment-list-select',
    },
    { label: t('ServiceField.status'), className: 'thclass-booking-user' },
    { label: t('venderBooking.action'), className: 'thclass-booking-user' },
  ];
  const tabs = [
    { key: 'all', label: t('ServiceField.all') },
    { key: 'upcoming', label: t('ServiceField.upcoming') },
    { key: 'past', label: t('ServiceField.past') },
  ];
  const filterInputs = [
    { key: 'bookingId', id: 'bookingId', type: 'number' },
    { key: 'serviceName', id: 'serviceName', type: 'text' },
    { key: 'organizationName', id: 'shopName', type: 'text' },
    { key: 'duration', id: 'duration', type: 'number' },
    { key: 'createdTime', id: 'createdDate', type: 'datepicker' },
    { key: 'date', id: 'bookingDate', type: 'datepicker' },
    { key: 'time', id: 'bookingTime', type: 'time' },
    { key: 'amount', id: 'amount', type: 'number' },
    { key: 'discount', id: 'discount', type: 'number' },
    { key: 'peakOffDiscount', id: 'peakDiscount', type: 'number' },
    { key: 'cashback', id: 'cashback', type: 'number' },
    { key: 'totalAmount', id: 'paidAmount', type: 'number' },
    { key: 'assignStaff', id: 'assignStaff', type: 'text' },
  ];
  const ServiceFieldHide = showFilterRow
    ? `${t('ServiceField.hide')} ${t('ServiceField.filter')}`
    : `${t('ServiceField.filter')}`;

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    const currentMinute = selectedTime.substring(3, 5);
    if (currentMinute !== previousMinute && previousMinute !== '') {
      handlefilterInputChange('time', selectedTime);
    }
    setPreviousMinute(currentMinute);
  };

  useEffect(() => {
    bookindata();
  }, []);

  useEffect(() => {
    callFilter && applyFilter(1, 15);
  }, [debouncedSearchTerm]);

  const paramObject = (page, pageSize) => {
    return new URLSearchParams({
      ...(filterValue.bookings && { bookings: filterValue.bookings }),
      page_number: page,
      per_page: pageSize,
      search: searchServices,
      booking_service_id: filterValue.bookingId
        ? parseInt(filterValue.bookingId, 10)
        : '',
      service_name_en: filterValue.serviceName,
      organization_name: filterValue.organizationName,
      booking_date: filterValue.date,
      duration: filterValue.duration ? parseInt(filterValue.duration, 10) : '',
      booking_time: filterValue?.time,
      price: filterValue.amount ? parseInt(filterValue.amount, 10) : '',
      discount_amount: filterValue.discount
        ? parseInt(filterValue.discount, 10)
        : '',
      peak_off_discount: filterValue.peakOffDiscount
        ? parseInt(filterValue.peakOffDiscount, 10)
        : '',
      applied_cashback: filterValue.cashback
        ? parseInt(filterValue.cashback, 10)
        : '',
      paid_amount: filterValue?.totalAmount
        ? parseInt(filterValue?.totalAmount, 10)
        : '',
      team_member_name: filterValue?.assignStaff
        ? filterValue?.assignStaff
        : '',
      payment_type: filterValue.paymentType,
      status: filterValue.status,
      created_at: filterValue?.createdTime ? filterValue?.createdTime : '',
      delivery_type: filterValue?.serviceLocation
        ? filterValue?.serviceLocation
        : '',
    });
  };

  const applyFilter = async (page, pageSize) => {
    setLoader(true);
    try {
      const booking = await getBookings(paramObject(page, pageSize));
      if (booking.data.booked_services_details) {
        setBookings(booking.data.booked_services_details);
        setTotalePage(booking.data.total_pages * 15);
      } else if (
        !booking?.data?.booked_services_details ||
        booking?.data?.status_code === 204
      ) {
        setBookings([]);
        setTotalePage(0);
        setIsEmpty('emptyBookingFilter');
      }
      setCurrentPage(1);
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  const bookindata = async () => {
    setLoader(true);
    try {
      const params = { page_number: currentPage, per_page: itemsPerPage };
      const booking = await getBookings(params);
      if (
        !booking?.data?.booked_services_details ||
        booking?.data?.status_code === 204
      )
        setIsEmpty('emptyBooking');
      else setIsEmpty(false);
      if (booking?.data?.booked_services_details) {
        setTotalePage(booking.data.total_pages * 15);
        setBookings(booking.data.booked_services_details);
        setCallFilter(true);
      }
    } catch (err) {
      setErr(err);
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  const handlefilterInputChange = (name, value) => {
    if (name === 'bookings') {
      if (name === 'bookings') {
        setFilterValue({
          [name]: value,
          bookingId: '',
          serviceName: '',
          date: '',
          duration: '',
          amount: '',
          status: '',
          paymentType: '',
          discount: '',
          peakOffDiscount: '',
          cashback: '',
          totalAmount: '',
          assignStaff: '',
          organizationName: '',
          time: '',
          createdTime: '',
        });
      }
    } else {
      setFilterValue({ ...filterValue, [name]: value });
    }
  };

  const handlePaginationChange = async (page, pageSize) => {
    setLoader(true);
    const queryParams = paramObject(page, pageSize);
    try {
      const booking = await getBookings(queryParams);
      if (booking.data.booked_services_details) {
        setBookings(booking.data.booked_services_details);
        setTotalePage(booking.data.total_pages * 15);
      } else {
        setBookings([]);
        setTotalePage(0);
      }
      setCurrentPage(page);
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  const toggleFilterRow = () => {
    setShowFilterRow(!showFilterRow);
  };

  const clearFilterfunction = async () => {
    setCurrentPage(1);
    setFilterValue((prevFilterValue) => ({
      ...prevFilterValue,
      createdTime: '', // Clear the selected createdTime
      date: '', // Clear the selected date
      bookings: '',
      bookingId: '',
      serviceName: '',
      duration: '',
      amount: '',
      status: '',
      paymentType: '',
      discount: '',
      cashback: '',
      totalAmount: '',
      organizationName: '',
      time: '',
      peakOffDiscount: '',
      serviceLocation: '',
    }));
  };

  const filterDataFromSearchHere = async () => {
    setFilterValue({
      bookings: '',
      bookingId: '',
      serviceName: '',
      date: '',
      duration: '',
      amount: '',
      status: '',
      paymentType: '',
      discount: '',
      cashback: '',
      totalAmount: '',
      organizationName: '',
      time: '',
    });
  };

  const cancelUserBooking = async (bookingId, updateCancelReason) => {
    try {
      handleClose();
      setIsComplte(false);
      setLoader(true);
      const status = clickToconfirm ? 'cancelled' : 'completed';
      const showBooking = await getBookingByUserId(bookingId);
      const formData = new FormData();
      formData.set('status', status);
      formData.set(
        'booking_date',
        showBooking.data.booking_service.booking_date,
      );
      formData.set('cancel_reason', updateCancelReason);
      formData.set(
        'delivery_type',
        showBooking.data.booking_service.delivery_type,
      );
      let params = {
        id: bookingId,
        formData: formData,
      };
      const response = await editBookings(params);
      const updatedBooking = bookings.map((item) => {
        if (item.booking_service_id === response.data.booking_service.id) {
          item.status = response.data.booking_service.status;
          item.booking_date = response.data.booking_service.booking_date;
        }
        return item;
      });
      handleSuccessResponse(response, response.data.message);
      setBookings(updatedBooking);
      bookindata();
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  const handleShow = () => setShowModal(true);

  const handleClose = () => {
    setShowModal(false);
    setIsComplte(false);
  };

  const closeEditBookingModal = () => {
    setEditBookingModalOpen(!editBookingModalOpen);
  };

  const getValue = (value) => {
    setBookingsTab(value);
    return value === 'all' ? '' : value;
  };

  const getServiceBookingName = (booking) => {
    return th === 'thai' && th !== undefined
      ? booking?.service_name_thai
      : booking.service_name;
  };

  const getBookingOrgnizationName = (booking) => {
    return ((th === 'thai') ? ((booking?.organization_thai) ? (booking?.organization_thai) : (booking.organization_name)) : (booking.organization_name))
  };

  const getFormatData = (booking) => {
    return (
      booking.booking_date.split('-')[2] +
      home +
      booking.booking_date.split('-')[1] +
      home +
      booking.booking_date.split('-')[0]
    );
  };

  const getPrice = (booking, type) => {
    switch (type) {
      case 'bookingPrice':
        return booking.price || 0;
      case 'totalDiscount':
        return booking.discount_amount ? booking.discount_amount : 0;
      case 'referralAmount':
        return booking.applied_cashback || 0;
      case 'paidAmount':
        return booking.paid_amount || 0;
      case 'pickDiscount':
        return booking.peak_off_discount || 0;
      default:
        return 0;
    }
  };

  const getBookingPaymentType = (booking) => {
    return booking.payment_type === 'online'
      ? `${t('userBooking.payOnline')}`
      : `${t('userBooking.payAtShop')}`;
  };

  const getAssignStaffName = (booking) => {
    return booking?.team_member_name;
  };

  const getServiceLocation = (booking) => {
    if (booking?.delivery_type === 'at_shop') {
      return t('venderBooking.shop');
    } else if (booking?.delivery_type === 'at_my_location') {
      return t('venderBooking.home');
    } else {
      return '';
    }
  };

  const getBookingUserClassName = (booking) => {
    switch (booking.status) {
      case 'confirmed':
        return 'bookinig-confirm';
      case 'completed':
        return 'bookinig-complete';
      case 'cancelled':
        return 'bookinig-cancelled';
      case 'expired':
        return 'bookinig-expire';
      default:
        return '';
    }
  };

  const getStatusName = (booking) => {
    switch (booking.status || booking) {
      case 'confirmed':
        return `${t('userBooking.confirmed')}`;
      case 'completed':
        return `${t('userBooking.completed')}`;
      case 'cancelled':
        return `${t('userBooking.cancelled')}`;
      case 'expired':
        return `${t('userBooking.expired')}`;
      default:
        return '';
    }
  };

  const getConfirmClassName = (booking) => {
    return booking.status === 'confirmed' ? '' : 'dull-image';
  };

  const navigateShop = async (e, shopName, organization_id) => {
    e.preventDefault();
    try {
      const encryptedId = encryptData(organization_id);
      const formatOrganizationName = organizationUrlFormat(shopName);
      let previousBreadcrumb = [
        {
          breadCrumbName: ['Bookings', 'การจอง'],
          breadCrumbNamePath: `${user}booking`,
        },
        { breadCrumbName: shopName, breadCrumbNamePath: '' },
      ];
      localStorage.removeItem('isSection');
      navigate(
        `${serviceDescription}/${encryptedId}/${organizationUrlFormat(formatOrganizationName)}`,
        { state: { previousBreadcrumb } },
      );
    } catch (err) { }
  };

  return {
    bookindata,
    getServiceLocation,
    navigateShop,
    handleTimeChange,
    getConfirmClassName,
    getStatusName,
    getBookingUserClassName,
    getBookingPaymentType,
    getPrice,
    getFormatData,
    getBookingOrgnizationName,
    getAssignStaffName,
    getServiceBookingName,
    getValue,
    ServiceFieldHide,
    filterInputs,
    tabs,
    bookingsTab,
    closeEditBookingModal,
    bookingToEdit,
    setBookingToEdit,
    setEditBookingModalOpen,
    columnHeadings,
    clickToconfirm,
    setClickConfirm,
    setLoader,
    loader,
    handleShow,
    handleClose,
    bookingItemId,
    setBookingItemId,
    showModal,
    error,
    setBookings,
    handlefilterInputChange,
    filterValue,
    totalPage,
    bookings,
    toggleFilterRow,
    showFilterRow,
    filterDataFromSearchHere,
    clearFilterfunction,
    handlePaginationChange,
    currentPage,
    itemsPerPage,
    setSearchServices,
    cancelUserBooking,
    editBookingModalOpen,
    isEmpty,
    isComplte,
    setIsComplte,
  };
};

export default useBookingServicesUserData;
