import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCashbackInfo,
  getReferralCode,
} from '../../../services/cashBackApi';

export const fetchUserReferralsInformation = createAsyncThunk(
  'user/fetchUserReferralsInformation',
  async () => {
    
    const responseReferralCode = await getReferralCode();
    const responseCashbackInfo = await getCashbackInfo();
    return {
      userCashBackDetails: responseCashbackInfo.data,
      userReferralsDetails: responseReferralCode.data,
    };
  },
);

const slice = createSlice({
  name: 'user',
  initialState: {
    userReferralsDetails: {},
    userCashBackDetails: {},
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserReferralsInformation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserReferralsInformation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userReferralsDetails = action.payload.userReferralsDetails;
        state.userCashBackDetails = action.payload.userCashBackDetails;
      })
      .addCase(fetchUserReferralsInformation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default slice.reducer;
