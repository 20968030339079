import { React, useEffect, useState, useNavigate } from '../../../libraries/index';
import { MyModal } from '../../../common';
import { handleErrorResponse, organizationStatus } from '../../../services';
import { Profile, accountDetails, bussiness, finance } from '../../../Routes/routes';
import { GetOrganizationStatus } from '../index';

const UnderReviewLogic = () => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState();
  const [isSection, setIsSection] = useState({});
  const [responsApi, setResponsApi] = useState();
  const organization = localStorage.getItem('status');
  const navigate = useNavigate();
  const { setIsOrganizationStatus } = GetOrganizationStatus();
  useEffect(() => {
    setShowModal(true);
    organization !== 'verified' && fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await organizationStatus();
      if (response.data.status === 'verified') {
        localStorage.setItem('status', 'verified');
        setIsOrganizationStatus('verified');
        setResponsApi(response.data.status);
        setShowModal(false);
      }
      setMessage(
        response.data.error ? response.data.error : response.data.message,
      );
      setIsSection({
        accont: response.data.account,
        finance: response.data.finance,
      });
    } catch (err) {
      err?.response?.data?.error && setMessage(err.response.data.error);
      handleErrorResponse(err);
    }
  };

  const navigateFun = () => {
    if (!isSection.accont) {
      navigate(`${bussiness}${accountDetails}`);
      localStorage.setItem('isSection', 'isBusinessAccount');
    } else if (!isSection.finance) {
      navigate(`${bussiness}${finance}`);
      localStorage.setItem('isSection', 'isBusinessFinance');
    } else {
      navigate(`${bussiness}${Profile}`);
      localStorage.setItem('isSection', 'isVendorProfile');
    }
  };

  return (
    <>
      {!responsApi && (
        <MyModal
          component={'underReview'}
          showModal={showModal}
          message={message}
          navigateFun={navigateFun}
        />
      )}
    </>
  );
};

export default UnderReviewLogic;
