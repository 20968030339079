import { changePassword } from '../../services/userApi';
import { handleErrorResponse, handleSuccessResponse } from '../../services';
import {
  isCurrentPassword,
  isStrongPassword,
  isValidConformPassword,
} from '../validation/fieldRegex';
import { Locale, useState } from '../../libraries';

const PasswordChangeLogic = (setCurrentPage) => {
  const { t } = Locale();
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    conformPassword: '',
  };
  const [passFormValue, setPassFormValue] = useState(initialValues);
  const [passErr, setPassErr] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);

  const handleChangePass = (e) => {
    const { name, value } = e.target;
    let err = handleValidation(name, value);

    setPassErr((prevErr) => ({
      ...prevErr,
      [name]: err[name] || null,
    }));

    setPassFormValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

  };


 
  const handlePassChangeSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let err = {}
    err = handleValidation("","",passFormValue)
    setPassErr(err)
    
    if (!(Object.keys(err).length)) {
      try {
        setDisableBtn(true)
        let param = {
          old_password: passFormValue.currentPassword,
          password: passFormValue.newPassword,
          password_confirmation: passFormValue.conformPassword,
          role: localStorage.getItem('role'),
        };

        let response = await changePassword(param);

        handleSuccessResponse(response, response.data.message);
        setPassFormValue(initialValues);
        setCurrentPage('myProfile');
      } catch (error) {
        handleErrorResponse(error);

      } finally {
        setDisableBtn(false)
      }
    }
  };

  const handleValidation = (name, value, formValue) => {
    let errors = {};
    if (formValue) {
      errors = isCurrentPassword(formValue?.currentPassword, errors, 'currentPassword', t);
      errors = isStrongPassword(formValue?.newPassword, errors, 'newPassword', t);
      errors = isValidConformPassword({ password: formValue.newPassword, cpassword: formValue?.conformPassword }, errors,
        'conformPassword',
        t,
      );
    }
    else {
      if(name === 'currentPassword' ){
        errors = isCurrentPassword(value, errors, name, t);
      }
      if ( name === 'newPassword') {
        errors = isStrongPassword(value, errors, name, t);
      }
      if (name === 'conformPassword') {
        errors = isValidConformPassword(
          { password: passFormValue?.newPassword, cpassword: value },
          errors,
          name,
          t,
        );
      }

    }

    return errors;
  };

  return {
    disableBtn,
    passFormValue,
    handleChangePass,
    handlePassChangeSubmit,
    passErr,
    setPassFormValue,
    setPassErr,
  };
};

export default PasswordChangeLogic;
