import {
    Home,
    SignUp,
    ForgotPasswordPage,
    AboutUsLogic,
    PrivacyPolicyLogic,
    ContactUsLogic,
    FAQLogic,
    BussinessPolicyLogic,
    CancelPolicyLogic,
    TermConditionLogic,
    DisplayServiceLogic,
    Payment,
    CartItemsLogic,
    OrderHistoryLogic,
    SearchAndDisplayDescLogic,
    VendorShopMap,
    UserEditProfileForm,
    UserBookingsList,
    CashbackLogic,
    ReferralLogic,
    ShowAllShop,
    ReviewRatingServiceLogic,
    AddToFavouriteLogic,
    AddToFavouriteServiceLogic,
    AddCart,
    AccountDeletion,
    AccountDeletionAction,
    CancelDeletionAction,
} from "../lazyComponent/index.js";
import {
    vendors,
    users,
    termCondition,
    faqs,
    contact,
    privacy,
    about,
    serviceDescription,
    forgotPassword,
    services,
    home,
    shopNearMe,
    signIn,
    signUp,
    verify,
    createNewPassword,
    orderHistory,
    businessPolicy,
    cancelPolicy,
    payment,
    cartPayment,
    calender,
    branches,
    openinghoursandpolicies,
    accountDetails,
    finance,
    bussinessServices,
    teamMembers,
    buisinesseditService,
    bussinessbookings,
    dashboard,
    Profile,
    addService,
    businessReview,
    payout,
    payoutSummary,
    reviewService,
    referral,
    cashback,
    addToCart,
    reviewShop,
    favouriteService,
    favouriteShop,
    cancelDeleteRequest,
    accountDeletion,
    deleteRequest,
    editProfile,
    bookings,
    successCallback,
    search,
} from '../Routes/routes.js';
import { SignIn } from "../components";
import User from "../pages/user.js";
import Vendor from "../vendor/vendor.js";
import {
    ReviewLogic,
    DashBoardLogic,
    Branch,
    VendorAccountDetails,
    OpeningHoursPolicies,
    Finances,
    VendorTeamListLogic
} from "../vendor/component";

import {
    Payout,
    PayoutSummary,
    ServicesList,
    AddNewService,
    MyCalender,
    VendorProfile,
    BookingsList
} from "../vendor/pages";
import PaymentSuccess from '../vendor/paymentSuccess/paymentSuccess.js';
import MobileResponseId from '../vendor/paymentSuccess/getMobileResponseId.js';

// ----------------------------------- app.js file routes -----------------------------

export const appRoutes = [
    { path: successCallback, element: PaymentSuccess, exact: true },
    { path: 'mobile/:id/payment', element: MobileResponseId, exact: true },
    { path: home, element: Home, exact: true },
    { path: signIn, element: SignIn, exact: true },
    { path: `${signUp}/:value`, element: SignUp, exact: true },
    { path: forgotPassword, element: ForgotPasswordPage, exact: true },
    { path: verify, element: ForgotPasswordPage, exact: true },
    { path: createNewPassword, element: ForgotPasswordPage, exact: true },
    { path: about, element: AboutUsLogic },
    { path: privacy, element: PrivacyPolicyLogic },
    { path: contact, element: ContactUsLogic },
    { path: faqs, element: FAQLogic },
    { path: businessPolicy, element: BussinessPolicyLogic },
    { path: cancelPolicy, element: CancelPolicyLogic },
    { path: termCondition, element: TermConditionLogic },
    { path: `${services}/:categoryName/:subCategoryName/:encryptedData`, element: DisplayServiceLogic, exact: true },
    { path: `${services}${search}:searchText`, element: DisplayServiceLogic, exact: true },
    { path: `${services}/:value`, element: DisplayServiceLogic, exact: true },
    { path: `${payment}/:unique_id`, element: Payment },
    { path: `${cartPayment}/:unique_id`, element: CartItemsLogic },
    { path: `${orderHistory}/:bookingIdsArray`, element: OrderHistoryLogic },
    { path: `${serviceDescription}/:organizationId/:shopName`, element: SearchAndDisplayDescLogic },
    { path: shopNearMe, element: VendorShopMap },
    { path: users, element: User },
    { path: vendors, element: Vendor },
];

// ---------------------------------- vendor.js file vendor routes----------------------------

export const vendorRoutes = [
    { path: dashboard, Component: DashBoardLogic },
    { path: Profile, Component: VendorProfile },
    { path: accountDetails, Component: VendorAccountDetails },
    { path: openinghoursandpolicies, Component: OpeningHoursPolicies },
    { path: finance, Component: Finances },
    { path: payout, Component: Payout },
    { path: `${payoutSummary}/:selectedMonth/:selectedMonthThai`, Component: PayoutSummary },
    { path: bussinessServices, Component: ServicesList },
    { path: addService, Component: AddNewService },
    { path: buisinesseditService, Component: AddNewService },
    { path: teamMembers, Component: VendorTeamListLogic },
    { path: bussinessbookings, Component: BookingsList },
    { path: calender, Component: MyCalender },
    { path: branches, Component: Branch },
    { path: businessReview, Component: ReviewLogic },
];

// ---------------------------------- vendor.js file team-member routes ----------------------

export const teamMemberRoutes = [
    { path: Profile, Component: VendorProfile },
    { path: bussinessbookings, Component: BookingsList },
    { path: calender, Component: MyCalender },
];

// ---------------------------------- user.js file routing ---------------------------------

export const userRoutes = [
    { path: editProfile, component: UserEditProfileForm },
    { path: accountDeletion, component: AccountDeletion },
    { path: deleteRequest, component: AccountDeletionAction },
    { path: cancelDeleteRequest, component: CancelDeletionAction },
    { path: bookings, component: UserBookingsList },
    { path: cashback, component: CashbackLogic },
    { path: referral, component: ReferralLogic },
    { path: reviewShop, component: ShowAllShop },
    { path: reviewService, component: ReviewRatingServiceLogic },
    { path: favouriteService, component: AddToFavouriteServiceLogic },
    { path: favouriteShop, component: AddToFavouriteLogic },
    { path: addToCart, component: AddCart },
];