import './DeletionRequest.css';
import { React, PropTypes, Locale, useState, Link } from '../../libraries';
import { DeletionRequestHeader } from '../index';
import { handleChangeValue } from '../../vendor/component';
import { CustomButton } from '../../common/customButton/customButton';
import { Small } from '../../common/index';
import { VendorHeader } from '../../vendor/pages';
import { accountDeletion, user } from '../../Routes/routes.js';
const DeletionRequest = ({ handleSubmit }) => {
  const { t } = Locale();
  const initialState = {
    deletetionRequestCheckbox1: false,
    deletetionRequestCheckbox2: false,
  };
  const [DeletionRequestValue, setDeletionRequestValue] =
    useState(initialState);
  const [err, setErr] = useState({});
  return (
    <>
      <div className="heading-outer-div user-outer-div">
        <VendorHeader />
        <DeletionRequestHeader />
        <div className="after-heading-outer-div">
          <div className="user-common-box">
            <div className="user-common-box-inner">
              <div className="user-inner-content-scrollar account-deletion-outer-div">
                <div className="row p-4">
                  <div className="col-1 accountDeletion_fa_icon_back ">
                    <Link to={`${user}${accountDeletion}`}>
                      <i className="bi bi-chevron-left"></i>
                    </Link>
                  </div>
                  <div className="col-10">
                    <div className="text-center">
                      <h5>{t('userEdit.beforeDeletePolicy')}</h5>
                    </div>
                    <div className="row mt-4 deletion-request-inner-row">
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="form-check-input openingHourse-day-checkbox"
                          id="deletetionRequestCheckbox1"
                          onChange={(e) =>
                            handleChangeValue(
                              {
                                target: {
                                  name: 'deletetionRequestCheckbox1',
                                  value: e.target.checked,
                                },
                              },
                              setDeletionRequestValue,
                              '',
                              '',
                              setErr,
                            )
                          }
                        />
                      </div>
                      <div className="col-11 accountDelete_checkboxcontaint">
                        <p htmlFor="deletetionRequestCheckbox1">
                          {t('userEdit.cancelPolicyPonit1')}
                        </p>
                      </div>
                    </div>
                    <div className="row mt-2 deletion-request-inner-row">
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="form-check-input openingHourse-day-checkbox"
                          id="deletetionRequestCheckbox2"
                          onChange={(e) =>
                            handleChangeValue(
                              {
                                target: {
                                  name: 'deletetionRequestCheckbox2',
                                  value: e.target.checked,
                                },
                              },
                              setDeletionRequestValue,
                              '',
                              '',
                              setErr,
                            )
                          }
                        />
                      </div>
                      <div className="col-11 accountDelete_checkboxcontaint">
                        <p htmlFor="deletetionRequestCheckbox2">
                          {t('userEdit.cancelPolicyPonit2')}
                        </p>
                      </div>
                      <Small
                        name={'deletetionRequestCheckbox2'}
                        err={err.deletetionRequestCheckbox2}
                      />
                    </div>
                    <div className="accountDelete_confirmDeletionButton_Div mt-3 mb-5">
                      <CustomButton
                        className="userprofileform-save-button"
                        name={t('userEdit.confirmDeletionRequest')}
                        onClick={handleSubmit}
                        formValue={DeletionRequestValue}
                        setErr={setErr}
                        formType="deletetionRequest"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
DeletionRequest.propTypes = {
  handleSubmit: PropTypes.func,
};
export default DeletionRequest;