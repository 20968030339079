import { useEffect, useState, Locale } from '../../../libraries';
import { getAllFAQ } from '../../../services';
import { DesignFAQ } from '../../pages';
import { handleErrorResponse } from '../../../services/base';

const FAQLogic = () => {
  const [fAQData, setFAQData] = useState([]);
  const { t } = Locale();
  const breadCrumbData = [
    {
      breadCrumbName: t('footer.faq'),
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getAllFAQ();
      setFAQData(response?.data?.faqs_data);
    } catch (err) {
      handleErrorResponse(err);
    }
  };
  return (
    <>
      <DesignFAQ fAQData={fAQData} breadCrumbData={breadCrumbData} />
    </>
  );
};
export default FAQLogic;
