import { React } from '../../libraries';
import { CancelDeletionHeader } from '../../pages/index';
import { handleErrorResponse, handleSuccessResponse, cancelDeleteUserRequest } from '../../services/index';

const CancelDeletionHeaderLogic = ({ setDeletionRequest }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    let param = {
      status: 'activated',
    };
    cancelDeleteUserRequest(param)
      .then((response) => {
        handleSuccessResponse(response, response.data.message);
        localStorage.setItem('status', 'activated');
        setDeletionRequest(false);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };
  return (
    <>
      <CancelDeletionHeader handleSubmit={handleSubmit} />
    </>
  );
};
export default CancelDeletionHeaderLogic;