import { apiCall } from './base';

const referralCodeVerification = async (param) => {
  const response = await apiCall(
    `customer/referrals/code_verification?referral_code=${param}`,
    'GET',
  );
  return response;
};
const getReferralCode = async () => {
  const response = await apiCall('customer/referrals', 'GET');
  return response;
};
const getCashbackInfo = async () => {
  const response = await apiCall('customer/cashbacks', 'GET');
  return response;
};
const getCashbackPerCheckout = async () => {
  const response = await apiCall('customer/cashback_per_checkout', 'GET');
  return response;
};

export {
  referralCodeVerification,
  getReferralCode,
  getCashbackInfo,
  getCashbackPerCheckout,
};
