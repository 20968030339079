import './Notification.css';
import { React, Locale, useNavigate, useState, useEffect, ActionCable } from '../../libraries';
import { userNotification, markAllRead } from '../../services/userNotification.js';
import { businessReview, bussiness, cashback, orderHistory, user } from '../../Routes/routes.js';
import { encryptData } from '../../customHooks/urlEncyption.js';

const websocket_url = process.env.REACT_APP_WEBSOCKET_URL;

const Notifications = () => {
  const navigate = useNavigate();
  const { t } = Locale();

  const [notifications, setNotifications] = useState({
    notifications: [],
    unread_notifications_count: 0,
  });
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    fetchAllNotifications();
    try {
      const cable = ActionCable?.createConsumer(`${websocket_url}/cable`);

      const subscription = cable?.subscriptions?.create(
        { channel: 'NotificationChannel', id: localStorage.getItem('userId') },
        {
          received: (data) => {
            if (data) {
              if (data === 'verified' || data === 'cancelled' || data === 'unverified') {
                localStorage?.setItem('status', data);
              } else {
                const { message, created_at, unread_notification } = data || {};
                // setUnReadNotification(unread_notification*1)
                setNotifications((prev) => {
                  const updatedNotifications = [...(prev?.notifications || [])];

                  if (message && created_at) {
                    updatedNotifications.push({ message, created_at });
                  }
                  return {
                    notifications: updatedNotifications,
                    unread_notifications_count:
                      (prev?.unread_notifications_count || 0) + (unread_notification ? 1 : 0),
                  };
                });
              }
            }
          },

        },
      );

      return () => {
        subscription?.unsubscribe();
        cable?.disconnect();
      };
    } catch (error) {
    }
  }, []);


  const fetchAllNotifications = async () => {
    try {
      const response = await userNotification();
      setNotifications({
        notifications: response?.data?.notifications || [],
        unread_notifications_count:
          response?.data?.unread_notifications_count || 0,
      });
      // setUnReadNotification(response?.data?.unread_notifications_count)

    } catch (error) {
      setNotifications({
        notifications: [],
        unread_notifications_count: 0,
      });
    }
  };

  const markAsReadAll = async () => {
    setShowNotifications(!showNotifications);
    try {
      await markAllRead();
      setNotifications((prev) => ({
        notifications: prev?.notifications || [],
        unread_notifications_count: 0,
      }));
    } catch (error) {

    }
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diffMinutes = Math.floor((now - date) / (1000 * 60));
    if (diffMinutes < 0) {
      return 'Just now';
    } else if (diffMinutes < 60) {
      return `${diffMinutes} ${t('notification.minute')}${diffMinutes > 1 ? 's' : ''} ${t('notification.ago')}`;
    } else if (diffMinutes < 1440) {
      const hours = Math.floor(diffMinutes / 60);
      return `${hours} ${t('notification.hour')}${hours > 1 ? 's' : ''} ${t('notification.ago')}`;
    } else {
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    }
  };

  const groupNotificationsByDate = () => {
    const today = new Date().toDateString();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayString = yesterday.toDateString();

    const groupedNotifications = {
      todayNotifications: [],
      yesterdayNotifications: [],
      pastNotifications: [],
    };

    if (notifications.notifications) {
      notifications.notifications.forEach((notification) => {
        const notificationDate = new Date(
          notification?.created_at,
        ).toDateString();

        if (notificationDate === today) {
          groupedNotifications?.todayNotifications.push(notification);
        } else if (notificationDate === yesterdayString) {
          groupedNotifications?.yesterdayNotifications.push(notification);
        } else {
          groupedNotifications?.pastNotifications.push(notification);
        }
      });
    }

    return groupedNotifications;
  };

  const { todayNotifications, yesterdayNotifications, pastNotifications } =
    groupNotificationsByDate();

  const innerContentStyle = {
    height: notifications.notifications.length > 0 && 'auto', // Ensure conditional styling
  };

  const isVendor = localStorage.getItem('role') === 'Vendor';
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const getNotificationMessage = (notification) => {
    return selectedLanguage === 'en'
      ? notification?.message
      : notification?.message_thai;
  };


  const showOrderHistoryPage = (e, notification) => {
    e.preventDefault();
    setShowNotifications(false)
    if (notification?.is_booking) {
      const bookingIds = [notification.booking_service_ids].join(',');
      const encryptedBookingIds = encryptData(bookingIds)
      navigate(`${orderHistory}/${encryptedBookingIds}`);
    }
    else if (notification?.is_referral) {
      localStorage.setItem('isSection', "isCashback")
      navigate(`${user}${cashback}`)
    }
    else if (notification?.is_review && (isVendor)) {
      navigate(`${bussiness}${businessReview}`)
    }
  }

  const handleCloseNotification = () => {
    setShowNotifications(false)
    // setUnReadNotification(0)
  }

  return (
    <div className="notification-container">
      <i
        className="bi bi-bell notification-icon"
        title={t('header.notification')}
        onClick={markAsReadAll}
      ></i>
      {notifications?.unread_notifications_count > 0 && (
        <sup
          className={`${isVendor ? 'vendor-notification-sup' : 'navbar-sup-notification '}`}
        >
          {notifications.unread_notifications_count}
        </sup>
      )}
      <div
        className={`collapse ${showNotifications ? 'show' : ''} notification-panel`}
      >
        <div className="notification-header">
          <h2>{t('header.notification')}</h2>
          <i
            className="bi bi-x-lg close-icon"
            onClick={() => handleCloseNotification(false)}
          ></i>
        </div>
        <div className="inside-content-div" style={innerContentStyle}>
          {notifications?.notifications?.length > 0 ? (
            <>
              {todayNotifications.length > 0 && (
                <div className="notification-section">
                  <h5>{t('header.today')}</h5>
                  {todayNotifications.map((notification, index) => (
                    // <div key={index} className={`notification-item ${(((index + 1) <= (unReadNotification)) ? 'unread-message' : 'read-message')}`}>

                    <div key={index} className={`notification-item`}>
                      {(notification?.is_booking) || (notification?.is_referral) || (notification?.is_review) ? <>
                        <div className="notification-message text-truncate cursor-point-class" onClick={(e) => showOrderHistoryPage(e, notification)}>
                          <i className="bi bi-bell bi-circle-dot message-icon"></i>
                          <div
                            className="text-truncate"
                            title={getNotificationMessage(notification)}
                          >
                            {getNotificationMessage(notification)}
                          </div>
                        </div>
                      </> : <>
                        <div className="notification-message text-truncate">
                          <i className="bi bi-bell bi-circle-dot message-icon"></i>
                          <div
                            className="text-truncate"
                            title={getNotificationMessage(notification)}
                          >
                            {getNotificationMessage(notification)}
                          </div>
                        </div>
                      </>}
                      <div className="notification-time">
                        {formatTime(notification.created_at)}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {yesterdayNotifications.length > 0 && (
                <div className="notification-section">
                  <h5>{t('header.yesterday')}</h5>
                  {yesterdayNotifications.map((notification, index) => (
                    <div key={index} className="notification-item">
                      {notification?.is_booking || notification?.is_referral || notification?.is_review ? <>
                        <div className="notification-message text-truncate cursor-point-class" onClick={(e) => showOrderHistoryPage(e, notification)}>
                          <i className="bi bi-bell bi-circle-dot message-icon"></i>
                          <div
                            className="text-truncate"
                            title={getNotificationMessage(notification)}
                          >
                            {getNotificationMessage(notification)}
                          </div>
                        </div>
                      </> : <>
                        <div className="notification-message text-truncate">
                          <i className="bi bi-bell bi-circle-dot message-icon"></i>
                          <div
                            className="text-truncate"
                            title={getNotificationMessage(notification)}
                          >
                            {getNotificationMessage(notification)}
                          </div>
                        </div>
                      </>}
                      <div className="notification-time">
                        {formatTime(notification?.created_at)}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {pastNotifications?.length > 0 && (
                <div className="notification-section">
                  <h5>{t('header.pastNotifications')}</h5>
                  {pastNotifications?.map((notification, index) => (
                    <div key={index} className="notification-item">
                      {notification?.is_booking || notification?.is_referral || notification?.is_review ? <>
                        <div className="notification-message text-truncate cursor-point-class" onClick={(e) => showOrderHistoryPage(e, notification)}>
                          <i className="bi bi-bell bi-circle-dot message-icon"></i>
                          <div
                            className="text-truncate"
                            title={getNotificationMessage(notification)}
                          >
                            {getNotificationMessage(notification)}
                          </div>
                        </div>
                      </> : <>
                        <div className="notification-message text-truncate">
                          <i className="bi bi-bell bi-circle-dot message-icon"></i>
                          <div
                            className="text-truncate"
                            title={getNotificationMessage(notification)}
                          >
                            {getNotificationMessage(notification)}
                          </div>
                        </div>
                      </>}
                      <div className="notification-time">
                        {formatTime(notification?.created_at)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="no-notifications">
              {t('button.no')} {t('header.notification')}
            </div> // Fallback message when notifications are empty
          )}
        </div>
      </div>
    </div>
  );
};
export default Notifications;