import { apiCall } from './base.js';

const userNotification = async () => {
  const response = await apiCall(`broadcast/notifications`, 'GET');
  return response;
};

const markAllRead = async () => {
  const response = await apiCall(`broadcast/mark_all_read`, 'PUT');
  return response;
};

export { userNotification, markAllRead };
