import { PaymentDone, PaymentFailed } from "../../pages";

const PaymentSuccess = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get('status');
  const role = queryParams.get('role');

  return (
    <>
      {status === "success" ? (
        <PaymentDone role={role} />
      ) : (
        <PaymentFailed role={role} />
      )}
    </>
  );
};

export default PaymentSuccess;

