import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { signIn } from '../Routes/routes';

const baseUrl = process.env.REACT_APP_BASE_URL;

const headers = (token, isFormData) => {
  const baseHeaders = {
    Accept: 'application/json',
    Authorization: token,
    Language: localStorage.getItem('i18nextLng'),
  };

  if (isFormData) {
    return {
      ...baseHeaders,
      'Content-Type': 'multipart/form-data',
    };
  } else {
    return {
      ...baseHeaders,
      'Content-Type': 'application/json',
    };
  }
};

const apiCall = async (path, method, body = {}) => {
  const url = `${baseUrl}${path}`;
  const token = localStorage.getItem('access_token');
  const isFormData = body instanceof FormData;
  const config = {
    method,
    url,
    headers: headers(token, isFormData),
  };
  config.data = body;
  const response = await axios(config);
  return response;
};

const handleSuccessResponse = function (response, successMessage) {
  if (response.status === 200 || response.status === 201) {
    if (successMessage) {
      toast.success(successMessage);
    } else {
      toast.success(response.data.toast);
    }
  } else {
    handleErrorResponse(response);
  }
};

const handleNavigation = (signInComponent) => {
  const myItem = localStorage.getItem('i18nextLng');
  const myLang = localStorage.getItem('selectedLanguage');
  localStorage.clear();

  if (myItem !== null && myItem !== undefined) {
    localStorage.setItem('i18nextLng', myItem);
  }
  if (myLang !== null && myLang !== undefined) {
    localStorage.setItem('selectedLanguage', myLang);
  }
  if (signInComponent) {
    window.location.href = signIn;
  } else window.location.href = '/';
};
const handleErrorResponse = function (error) {
  if (error?.response?.data?.status_code === 404) {
    let selectedLanguage = localStorage.getItem('i18nextLng');
    toast.error(error?.response?.data?.error);
    localStorage.clear();
    localStorage.setItem('i18nextLng', selectedLanguage);
    window.location.href = signIn;
  } else if (error?.response?.data?.status_code === 403) {
    // toast.error(error.response.data.error);
    handleNavigation(true);
  } else if (error.response) {
    const status = error.response.status;
    if (
      error.response.data.error &&
      error.response.data.error.length > 0 &&
      status !== 403
    ) {
      toast.error(error.response.data.error);
    } else if (status === 404 || status === 400) {
      toast.error(error.response.data.error);
    } else if (status === 422) {
      toast.error(error.response.data.error);
      const myItem = localStorage.getItem('i18nextLng');
      const myLang = localStorage.getItem('selectedLanguage');
      localStorage.clear();

      if (myItem !== null && myItem !== undefined) {
        localStorage.setItem('i18nextLng', myItem);
      }
      if (myLang !== null && myLang !== undefined) {
        localStorage.setItem('selectedLanguage', myLang);
      }
      window.location.replace('/sign_in');
    } else if ([500, 409, 400, 401, 405].includes(status)) {
      toast.error(error.response.data.error);
    } else if (status === 403) {
      toast.error(error.response.data.toast);
      handleNavigation();
    } else {
      if (error.response.data.toast) {
        toast.error(error.response.data.toast);
      } else {
        toast.error(error.response.data.error);
      }
    }
  } else {
    // toast.error("please connect to the server"
    toast.error(error);
  }
};

export { apiCall, handleSuccessResponse, handleErrorResponse };
