import { React, useEffect, useCookies } from '../../libraries';
import { Login, FormValidation } from '../../pages';
import { home } from '../../Routes/routes';
const SignIn = ({ showModal, setShowModal, type, commonId, userType }) => {
  const initialValues = {
    username: '',
    password: '',
    venuename: '',
    email: '',
    vpassword: '',
    cpassword: '',
    phone: '',
    address: '',
    postalCode: '',
    subcode: '',
    cname: '',
    cemail: '',
    cphone: '',
    cinfo: '',
  };
  const {
    isLoader,
    formValues,
    formErrors,
    handleSubmit,
    handleChange,
    favhandleSubmit,
    setFormValues,
  } = FormValidation(initialValues);
  let rememberMeChecked;
  let storedUsername;
  let storedPassword;
  const [cookies, setCookie] = useCookies([
    'rememberMe',
    'username',
    'password',
  ]);
  const handleRememberMeChange = (e) => {
    setCookie('rememberMe', e.target.checked, { path: home });
    setCookie('username', formValues.username, { path: home });
    setCookie('password', formValues.password, { path: home });
  };

  useEffect(() => {
    rememberMeChecked = cookies.rememberMe === 'true';
    storedUsername = cookies.username || '';
    storedPassword = cookies.password || '';
    setFormValues((prevValues) => ({
      ...prevValues,
      username:
        formValues.username !== '' ? formValues.username : storedUsername,
      password:
        formValues.password !== '' ? formValues.password : storedPassword,
    }));
  }, []);
  return (
    <>
      <Login
        isLoader={isLoader}
        formValues={formValues}
        formErrors={formErrors}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        showModal={showModal}
        setShowModal={setShowModal}
        favhandleSubmit={favhandleSubmit}
        handleRememberMeChange={handleRememberMeChange}
        rememberMeChecked={rememberMeChecked}
        type={type}
        s_id={commonId}
        userType={userType}
      />
    </>
  );
};

export default SignIn;
