import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { vendorOrgnizationInformation } from '../../../services/vendor/vendorApi';

export const fetchAccountInformation = createAsyncThunk(
  'vendor/fetchAccountInformation',
  async () => {
    const response = await vendorOrgnizationInformation();
    // localStorage.setItem("name", response.data.user.first_name + " " + response.data.user.last_name)
    return response.data;
  },
);

const slice = createSlice({
  name: 'vendor',
  initialState: {
    vendorAccoutnDetails: {},
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountInformation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAccountInformation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vendorAccoutnDetails = action.payload;
      })
      .addCase(fetchAccountInformation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default slice.reducer;
