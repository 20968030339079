import { React, Slider, Link, useEffect, useMemo, useState, Locale, useNavigate } from '../../libraries';
import { DefaultServiceImg } from '../../assets/Images';
import CustomImage from '../image/image';
import { services } from '../../Routes/routes';
import { CustomPrevArrow, CustomNextArrow } from '../index';
import { encryptData } from '../../customHooks/urlEncyption';

const ProductHeader = ({ data, section }) => {
  const { t } = Locale();
  const navigate = useNavigate();
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const [slidesToShow, setSlidesToShow] = useState(3); // Default to 3 slides

  const settings = useMemo(
    () => ({
      dots: data.length > 3, // Show dots only if there are more than 3 items
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
      lazyLoad: 'ondemand',
      prevArrow: <CustomPrevArrow />,
      nextArrow: <CustomNextArrow />,
    }),
    [data.length, slidesToShow],
  );

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1024) {
        setSlidesToShow(3);
      } else if (screenWidth <= 1024 && screenWidth > 480) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigateFunction = (e, id, subCategoryNameEn, subCategoryNameTh) => {
    e.preventDefault();
    e.stopPropagation();
    const encryptionData = {
      categoryId: id,
      categoryName: { categoryNameEn: section?.title, categoryNameTh: section?.title_in_thai },
      subCategoryName: { subCategoryNameEn: subCategoryNameEn, subCategoryNameTh: subCategoryNameTh },
    }
    const encryptedData = encryptData(encryptionData)
    navigate(`${services}/${section?.title}/${subCategoryNameEn}/${encryptedData}`);
  };

  const SubCategoryName = ({ item }) => {
    return selectedLanguage === 'thai'
      ? item.sub_category?.name_thai
      : item.sub_category?.name_en;
  };

  const getSubCategoryDesc = (item) => {
    return selectedLanguage === 'thai'
      ? item.sub_category?.description_thai
      : item.sub_category?.description_en;
  };

  return (
    <div className='best-seler-container-btn'>
      <Slider {...settings}>
        {data?.map((item, index) => (
          <div className="best-seler-container responsive-card" key={index}>
            <div className="cards">
              <Link
                className="card spa-image-outer-card"
                onClick={(e) =>
                  navigateFunction(
                    e,
                    item.sub_category.id,
                    item.sub_category.name_en,
                    item.sub_category.name_thai,
                  )
                }
              >
                <CustomImage
                  className="card__image"
                  width="300"
                  height="200"
                  src={item.image_url || DefaultServiceImg}
                  alt={`Image ${index}`}
                />
                <div className="button-font-and-cart-section best-seller-category-heading venue-name-h3">
                  <SubCategoryName item={item} />
                </div>
                <div className="info  cart-section-paragraph">
                  {item.sub_category?.description_thai && (
                    <>
                      <div className="h1-font">
                        <SubCategoryName item={item} />
                      </div>
                      <p className='hover-description'>
                        {getSubCategoryDesc(item)}
                      </p>
                      <div className=' text-center'>
                        <button className='nbest-seller-ear-me-btn'>{t('ServiceField.readMore')}&nbsp;<i class="bi bi-arrow-right"></i></button>
                      </div>
                    </>)
                  }
                </div>
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default ProductHeader;