import { React, useEffect, message,useState, Locale, useDispatch, useLocation, useSelector } from "../../../libraries";
import { viewService, handleErrorResponse, handleSuccessResponse, addMember, showTeamMember, updateTeamMember, viewAllTeamMember, showBranch } from "../../../services/index";
import { isValidEmail, isSelect, isValidName, isValidPhoneNumber, } from "../../../common/validation/fieldRegex";
import { CountryCodeJson } from "../../../jsonData";
import { GetOrganizationStatus } from "../index";
import { fetchAccountInformation } from "../../../reduxConfig/slice/vendor/vendorSlice";
import { useCropImage } from "../../../common";
import { VendorTeamList } from "../../pages";
const VendorTeamListLogic = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = Locale();

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    gender: "",
    phone: "",
    countrycode: "+66 (Thailand)",
    designation: "",
    branchId: "",
    branch_id: "",
    availableForService: [],
    workingHours: []
  };
  const initialOpeningValue = {
    bussiness_hours: {
      monday: { open: "", close: "", shop_open: false },
      tuesday: { open: "", close: "", shop_open: false },
      wednesday: { open: "", close: "", shop_open: false },
      thursday: { open: "", close: "", shop_open: false },
      friday: { open: "", close: "", shop_open: false },
      saturday: { open: "", close: "", shop_open: false },
      sunday: { open: "", close: "", shop_open: false }
    }
  };
  const { isOrganizationStatus } = GetOrganizationStatus();
  const [formValue, setFormValue] = useState(initialValues);
  const [formError, setFormError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [type, setType] = useState("");
  const [customerId, setCustomerId] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [memberItem, setItem] = useState([]);
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const totalPage = 0;
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectItemVisible, setSelectItemVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [getBranchData, setGetBranchData] = useState([]);
  const [upload, setUpload] = useState(0);
  const [loader, setLoader] = useState(false)
  const [isBtnDisable, setIsBtnDisable] = useState(false)
  const [profileVisible, setProfileVisible] = useState(true)
  const [dataChange, setDataChange] = useState();
  const [imageName, setImageName] = useState();
  const organizationId = localStorage.getItem("orgId")
  const [openingHourseFormValue, setOpeningHourseFormValue] = useState(initialOpeningValue);
  const { vendorAccoutnDetails } = useSelector((state) => state.vendor);
  const isSection = localStorage.getItem("isSection")
  const { crop, setCrop, zoom, setZoom, onCropComplete, showCroppedImage } = useCropImage(selectedImage, setSelectedImage, imageName);
  const role = localStorage.getItem("type")==='true'||localStorage.getItem("type")===true

  useEffect(() => {
    if (isSection !== 'isBusinessTeamSetup') {
      localStorage.setItem("isSection", "isBusinessTeamSetup")
    }
  }, [location.pathname])

  useEffect(() => {
    if (isOrganizationStatus === "verified") {
      fetchServicess();
    }
  }, [isOrganizationStatus, upload]);

  useEffect(() => {
    fetchBranch();
  }, [])

  useEffect(() => {
    if (isOrganizationStatus === "verified") {
      handlePaginationChange(currentPage, itemsPerPage)
    }
  }, [dataChange, isOrganizationStatus])
useEffect(()=>{},[formValue])
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchAccountInformation());
      } catch (error) {
        handleErrorResponse(error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleAddButtonClick = () => {
    setShowModal(true);
    setType("addMember");
    setFormError(initialValues);
    setFormValue(initialValues);
    setOpeningHourseFormValue((prevFormValue) => ({
      ...prevFormValue,
      bussiness_hours: vendorAccoutnDetails.organization.bussiness_hours,
    }));
    setSelectedImage(null);
    setUpload((pre) => pre - 1);
    setServices([]);
    setSelectedItems([]);
  };

  const crosButton = () => {
    setShowModal(false);
    setFormError(initialValues);
  };

  const handleOk = () => {
    setShowModal(false);
  };

  const handleImageUpload = async (event) => {
    setProfileVisible(false)
    if (event) {
      const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
      if (event.size > maxSize) {
        const errorMessage = event.size > maxSize
          ? `${t("validation.imageSize")}`
          : `${t("validation.imageSize")}`;
        message.error(errorMessage);
        return;
      }
      setImageName(event.name)
      setSelectedImage(URL.createObjectURL(event));
    }
  };

  const handleChange = (name, value) => {
    const updatedFormValue = { ...formValue, [name]: value };
    const errorLogs = validates(updatedFormValue);
    setFormError({ ...formError, [name]: errorLogs[name] });
    setFormValue(updatedFormValue);
  };

  const appendData = (formValues, cropFile) => {
    const formData = new FormData();
    if (cropFile)
      formData.append("image", cropFile);
    formData.append("first_name", formValues.fname);
    formData.append("last_name", formValues.lname);
    formData.append("email", formValues.email);
    formData.append("gender", formValues.gender);
    formData.append("team_member_setting[designation]", formValues.designation);
    formData.append("team_member_setting[organization_id]", organizationId);
    if(!role){
      formData.append("team_member_setting[branch_id]", typeof formValues.branchId === "string" ? formValues.branch_id : formValues.branchId,);
    }
    formData.append("phone_number", formValues.countrycode.split("(")[0].trim() + " " + formValues.phone);
    formValues.availableForService.forEach((value) => {
      formData.append("selected_services[]", value);
    });
    const workingHours = openingHourseFormValue.bussiness_hours;
    Object.keys(workingHours).forEach(day => {
      formData.append(`team_member_setting[working_hours][${day}][shop_open]`, workingHours[day]?.shop_open);
      formData.append(`team_member_setting[working_hours][${day}][open]`, workingHours[day]?.open);
      formData.append(`team_member_setting[working_hours][${day}][close]`, workingHours[day]?.close);
    });
    return formData;
  };

  const countryCodeArray = CountryCodeJson.map((item) => {
    return { value: item.dial_code, label: item.dial_code + " (" + item.name + ")" };
  });

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    let cropFile = await showCroppedImage();
    formValue.availableForService = selectedItems;
    setUpload((pre) => pre + 1);
    if (role) {
      delete formValue.branchId;
      delete formValue.branch_id;  // Remove branchName for freelancers if present
    }
    const error = validates(formValue);
    setProfileVisible(true)
    setFormError(error);
    if (Object.keys(error).length === 0) {
      try {
        if (type === "updateTeamMember") {
          formValue.availableForService = selectedItems;
          let formData = appendData(formValue, cropFile);
          setIsBtnDisable(true)
          const response = await updateTeamMember(customerId, formData);
          setDataChange(response.data)
          handleSuccessResponse(response, response?.data?.message);
          crosButton();

        } else if (type === "addMember") {
          formValue.availableForService = selectedItems;
          let formData = appendData(formValue, cropFile);
          setIsBtnDisable(true)
          const response = await addMember(formData);
          handleSuccessResponse(response, response?.data?.message);
          crosButton();
          setDataChange(response?.data)
          setUpload((pre) => pre + 1);
        }
      } catch (err) {
        handleErrorResponse(err);
        setIsBtnDisable(false)

      }
      finally {
        setLoader(false)
        setIsBtnDisable(false)

      }
    }
  };

  const toggleSelectItemVisibility = () => {
    setSelectItemVisible(true);
  };

  const validates = (values) => {
    let errors = {};
    errors = isValidName(values.fname, errors, "fname", `${t("field.fname")}`, t);
    errors = isValidEmail(values.email, errors, "email", `${t("field.email")}`, t);
    errors = isValidName(values.designation, errors, "designation", `${t("header.designation")}`, t);
    errors = isValidName(values.lname, errors, "lname", `${t("field.lName")}`, t);
    errors = isValidPhoneNumber(values.phone, errors, "phone", `${t("field.phoneNumber")}`, t);
    errors = isSelect(values.gender, errors, "gender", `${t("field.gender")}`, t);
    if (!role){
      errors = isSelect(values.branchId, errors, "branchId", `${t("vendor.branch")}`, t);
    }
    if (!values.availableForService.length) {
      errors.availableForService = `${t("field.pleaseSelect")} ${t("teamSetupField.services")}`;
    }
    return errors;
  };

  const handlePaginationChange = async (page, pageSize) => {
    setLoader(true)
    try {
      const params = { page_number: page, per_page: itemsPerPage };
      const response = await viewAllTeamMember(params);
      setItem(response.data.data);
    } catch (err) {
      handleErrorResponse(err);
    }
    finally {
      setLoader(false)
    }
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };

  const deleteFromFarray = () => {
    handlePaginationChange(1, 7);
  };

  const fetchBranch = async () => {
    try {
      const response = await showBranch();
      setGetBranchData(response?.data?.branches)
    }
    catch (err) {
      if (err?.response?.data?.status_code === 404) {
        handleErrorResponse(err)
      }
    }
  }

  const fetchServicess = async () => {
    try {
      const serviceResponse = await viewService();
      if (serviceResponse?.data?.services_info) {
        const servicedata = serviceResponse?.data?.services_info
          .map((item) => {
            return { id: item.id, first_name: item.name };
          })
          .filter((item) => item !== null);

        setServices(servicedata);
      }

    }
    catch (err) {
      handleErrorResponse(err);
    }
  }

  const editTeamMember = async (id) => {
    try {
      setShowModal(true);
      setType("updateTeamMember");
      setCustomerId(id);
      const response = await showTeamMember(id);
      const phoneNumber = response?.data?.team_member?.team_member?.phone_number;
      const match = phoneNumber ? phoneNumber.match(/^\+(\d+)\s(.+)$/) : null;
      const countrycode = match ? match[1] : null;
      const phone = match ? match[2] : null;
      if (response.data.team_member.branch_id){
        const branch = getBranchData.find(branch => branch.branch.id === response.data.team_member.branch_id);
        const branchName = branch ? branch.branch.branch_name : "";
        setFormValue((pre) => {
          return {
            ...pre,
            gender: response?.data?.team_member?.team_member?.gender,
            fname: response?.data?.team_member?.team_member?.first_name,
            lname: response?.data?.team_member?.team_member?.last_name,
            email: response?.data?.team_member?.team_member?.email,
            workingHours: response?.data?.team_member?.working_hours,
            countrycode: "+" + countrycode,
            phone: phone,
            designation: response?.data?.team_member?.designation,
            branch_id: response?.data?.team_member?.branch_id,
            branchId: branchName,
          };
        });
      }
      else{
        setFormValue((pre) => {
          return {
            ...pre,
            gender: response?.data?.team_member?.team_member?.gender,
            fname: response?.data?.team_member?.team_member?.first_name,
            lname: response?.data?.team_member?.team_member?.last_name,
            email: response?.data?.team_member?.team_member?.email,
            workingHours: response?.data?.team_member?.working_hours,
            countrycode: "+" + countrycode,
            phone: phone,
            designation: response?.data?.team_member?.designation,
          };
        });
      }
      setOpeningHourseFormValue((prev) => {
        return {
          ...prev,
          bussiness_hours: response?.data?.team_member?.working_hours,
        };
      });
      setSelectedServices(response?.data?.service_id);
      setSelectedImage(response.data?.image_url);
    } catch (err) { }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());


  return <>

    <VendorTeamList
      isOrganizationStatus={isOrganizationStatus}
      loader={loader}
      isBtnDisable={isBtnDisable}
      setLoader={setLoader}
      filterOption={filterOption}
      selectedImage={selectedImage}
      handleImageUpload={handleImageUpload}
      handleOk={handleOk}
      handleAddButtonClick={handleAddButtonClick}
      showModal={showModal}
      formValue={formValue}
      formError={formError}
      type={type}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      selectedServices={selectedServices}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      services={services}
      selectItemVisible={selectItemVisible}
      setSelectItemVisible={setSelectItemVisible}
      searchText={searchText}
      setSearchText={setSearchText}
      memberItem={memberItem}
      handlePaginationChange={handlePaginationChange}
      toggleSelectItemVisibility={toggleSelectItemVisibility}
      totalPage={totalPage}
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      deleteFromFarray={deleteFromFarray}
      editTeamMember={editTeamMember}
      countryCode={countryCodeArray}
      setShowModal={setShowModal}
      getBranchData={getBranchData}
      openingHourseFormValue={openingHourseFormValue}
      setOpeningHourseFormValue={setOpeningHourseFormValue}
      profileVisible={profileVisible}
      crop={crop} setCrop={setCrop} zoom={zoom} setZoom={setZoom} onCropComplete={onCropComplete} />
  </>
};
export default VendorTeamListLogic;