import { apiCall } from '../base';

const getBookingsVendor = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await apiCall(`business/bookings?${queryString}`, 'GET');
  return response;
};
const getBookingById = async (params) => {
  const response = await apiCall(`business/bookings/${params}`, 'GET');
  return response;
};
const editVendorBookings = async (id, params) => {
  const response = await apiCall(`business/bookings/${id}`, 'PUT', params);
  return response;
};
const vendoreCustomerBooking = async (params) => {
  const response = await apiCall('business/bookings', 'POST', params);
  return response;
};

const sendBookingVerificationCode = async (params) => {
  const response = await apiCall(
    `business_team_member/verification_code/${params.bookingId}`,
    'GET',
  );
  return response;
};

export {
  sendBookingVerificationCode,
  getBookingsVendor,
  getBookingById,
  editVendorBookings,
  vendoreCustomerBooking,
};
