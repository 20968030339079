import { apiCall } from './base';

const shopNearMe = async (currentLocation) => {
  const response = await apiCall(
    `customer/near_shops?latitude=${currentLocation.latitude}&longitude=${currentLocation.longitude}`,
    'GET',
  );
  return response;
};

export { shopNearMe };
