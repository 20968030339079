import { apiCall } from './base.js';

const getServices = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await apiCall(`business/services?${queryString}`, 'GET');
  return response;
};
const getServiceById = async (params) => {
  const response = await apiCall(`business/services/${params}`, 'GET');
  return response;
};

const addServices = async (params) => {
  const response = await apiCall('business/services', 'POST', params);
  return response;
};

const updateServices = async (params) => {
  const response = await apiCall(
    `business/services/${params.id}`,
    'PUT',
    params,
  );
  return response;
};

const deleteService = async (params) => {
  const response = await apiCall(`business/services/${params}`, 'DELETE');
  return response;
};

const suggestService = async (params) => {
  let param = { service_name: params.suggestedName };
  const response = await apiCall('business/recommended_service', 'POST', param);
  return response;
};

const viewService = async () => {
  const response = await apiCall('business/get_services', 'GET');
  return response;
};

export {
  getServices,
  addServices,
  updateServices,
  deleteService,
  suggestService,
  getServiceById,
  viewService,
};
