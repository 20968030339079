import { React, Locale, useNavigate, GoogleLogin } from '../../libraries';
import { handleErrorResponse, handleSuccessResponse, googleSignIn, showUserDetails, showCartList } from '../../services';
import { Profile, bussiness, dashboard, home } from '../../Routes/routes';
const REACT_APP_GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID;

const Google = ({ className, msg }) => {
  const navigate = useNavigate();
  const { t } = Locale();
  const responseGoogle = async (res) => {
    try {
      const response = await googleSignIn({ token: res.credential });

      if (response && response.data.access_token) {
        let cartResponse = [];
        try {
          cartResponse = await showCartList();
        }
        catch (err) {
        }
        let cartCount = cartResponse?.data?.data?.length;
        localStorage.setItem('access_token', response?.data?.access_token);
        let getShowResponse = await showUserDetails();
        localStorage.setItem('role', getShowResponse?.data?.user?.type);
        localStorage.setItem('status', getShowResponse?.data?.user?.status);
        let name = getShowResponse?.data?.user?.first_name
          ? getShowResponse?.data?.user?.first_name
          : '';
        let lastName = getShowResponse.data.user.last_name
          ? getShowResponse?.data?.user?.last_name
          : '';
        let fullName = name + ' ' + lastName;
        localStorage.setItem('name', fullName);
        const newCartCount = Math.max(cartCount || 0);
        localStorage.setItem('cartCount', newCartCount);
        localStorage.setItem('userId', getShowResponse.data.user.id);
        localStorage.setItem(
          'profilePhoto',
          getShowResponse.data.image_url ? getShowResponse.data.image_url : '',
        );
        handleSuccessResponse(response, response?.data?.message);
        if (getShowResponse.data.user.type === 'Vendor') {
          localStorage.setItem('isSection', 'isDashboard');
          navigate(`${bussiness}${dashboard}`);
        } else if (getShowResponse?.data?.user?.type === 'TeamMember') {
          localStorage.setItem('isSection', 'isVendorProfile');
          navigate(`${bussiness}${Profile}`);
        } else {
          navigate(home);
        }
      } else {
        console.log('invalid users');
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  };
  return (
    <div className={className}>
      <GoogleLogin
        theme="filled_blue"
        size="medium"
        text={t('signMessage.signWithgoogle')}
        onSuccess={responseGoogle}
        cookiePolicy={'single_host_origin'}
        locale={msg}
        clientId={REACT_APP_GOOGLE_ID}
      />
    </div>
  );
};
export default Google;