import './AccountDetails.css';
import 'react-quill/dist/quill.snow.css';
import { React, PropTypes, useState, Locale, ToastContainer, Select, ReactQuill } from '../../../libraries/index';
import { CountryCodeJson, AddressJson } from '../../../../src/jsonData';
import { handleChangeValue, handleSubmit, AccountSettingHeaders } from '../../component/index';
import { Small, Label, CustomSpinner, Heading, InputField, UploadFileModalLogic } from '../../../common';
import { ShopImage } from '../../../assets/Images';
import { CustomButton } from '../../../common/customButton/customButton';
import { VendorHeader } from '../index';
const AccountDetails = ({
  modules,
  formats,
  handleDescriptionChange,
  isLoader,
  subDistrictOption,
  filterOption,
  onChange,
  deleteImage,
  formValue,
  setFormValue,
}) => {
  const [err, setErr] = useState({});
  const { t } = Locale();
  const { address, postcode, city, venuPhoneNumber } = { formValue };
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const uniquePostalCodes = [
    ...new Set(AddressJson.map((item) => item.postalCode)),
  ];
  const postalCodeArray = uniquePostalCodes.map((postalCode) => ({
    value: postalCode,
    label: postalCode.toString(),
  }));
  const role = localStorage.getItem('type') === 'true';
  const countryCode = CountryCodeJson.map((item) => {
    return {
      value: item.dial_code,
      label: item.dial_code + ' (' + item.name + ')',
    };
  });

  return (
    <>
      <ToastContainer />
      <UploadFileModalLogic setFormValue={setFormValue} formValue={formValue} />
      <div className="heading-outer-div">
        <VendorHeader />
        <Heading text={t('vendor.accountSetting')} icon={'bi bi-shop-window'} />
        <div className="after-heading-outer-div">
          <div className="user-common-box">
            <div className="user-common-box-inner">
              <div className="user-common-box-fix-content row-box m-0 mb-3">
                <AccountSettingHeaders />
              </div>
              <div className="container-fluid  mt-2 p-2 user-inner-content-scrollar user-inner-content-scrollar-after-fix-content">
                <div className={`p-2`}>
                  {formValue.shopImages && formValue.shopImages.length > 0 ? (
                    <div className="editbranchprofile_img_add_div mt-2">
                      <div className="row">
                        {formValue.shopImages.map((image, key) => (
                          <div className="image-div col-sm-2" key={key}>
                            <i
                              className={`bi bi-x-lg cross-icon mt-2`}
                              onClick={() => deleteImage(key)}
                            ></i>
                            <img
                              alt="shopImage"
                              src={image}
                              className="img-fluid editbranch-shopImage1"
                            />
                          </div>
                        ))}
                        <div className="col-sm-1 accountDetails-add-button-div mt-5">
                          <CustomButton
                            data_bs_toggle="modal"
                            data_bs_target="#uploadFileModal"
                            className="editbranchprofile_addbutton"
                            name={'+'}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row account-details-image-outer-div">
                      <div className="col-sm-5">
                        <div className="accountDetailsImageDiv">
                          <img
                            className="img-fluid account-details-image"
                            alt={ShopImage}
                            src={
                              'https://cdn-icons-png.flaticon.com/512/3443/3443338.png'
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-1 vendoraccountdetailsUploadImageDiv">
                        <CustomButton
                          data_bs_toggle="modal"
                          data_bs_target="#uploadFileModal"
                          className="editBranch-add-button-div"
                          name={'+'}
                        />
                      </div>
                    </div>
                  )}
                  <div className="account-details-outer-div">
                    {!role && (
                      <div className="accountDetails_ThaiNameDiv mt-4">
                        <div className="row">
                          <div className=" col-lg-6 col-md-6 col-sm-12">
                            <div>
                              <label className="form-label">
                                {t('vendorField.venueEngName')}
                              </label>
                              <InputField
                                disabled={true}
                                formValue={formValue}
                                setErr={setErr}
                                name={'venueThaiName'}
                                value={formValue?.venue_name}
                                className={
                                  'form-control vendor-account-details-input-field'
                                }
                              />
                            </div>
                          </div>
                          <div className=" col-lg-6 col-md-6 col-sm-12">
                            <div>
                              <label className="form-label">
                                {t('vendorField.venueThaiName')}
                              </label>
                              <InputField
                                placeholder={`${t('field.pleaseEnter')} ${t('vendorField.venueThaiName')}`}
                                name={'venueThaiName'}
                                value={formValue?.venueThaiName}
                                setFormValue={setFormValue}
                                onChange={handleChangeValue}
                                type={'text'}
                                formValue={formValue}
                                setErr={setErr}
                                className={
                                  'form-control vendor-account-details-input-field'
                                }
                                id={'venue_Thai_Name'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 col-sm-6 mt-2 account-details-vanue-email-div col-sm-12 mt-2">
                        <div>
                          <Label
                            className="form-label"
                            text={
                              <>
                                {t('field.semail')}
                                <small className="small-err">*</small>
                              </>
                            }
                          />
                          <InputField
                            onChange={handleChangeValue}
                            name={'email'}
                            placeholder={`${t('field.pleaseEnter')} ${t('field.semail')}`}
                            value={formValue?.email}
                            setFormValue={setFormValue}
                            type={'email'}
                            formValue={formValue}
                            setErr={setErr}
                            className={
                              'form-control vendor-account-details-input-field '
                            }
                            id={'contact_person_name'}
                          />
                          <Small name={'email'} err={err.email} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="accountDetails_phonenumber_Div ">
                          <div className="row ">
                            <div className="col-lg-4 col-md-6 col-sm-6 mt-2">
                              <Label
                                className={'form-label'}
                                text={
                                  <>
                                    {t('field.countryCode')}
                                    <small className="small-err">*</small>
                                  </>
                                }
                              />
                              <Select
                                className="finace-ant-select-form"
                                showSearch
                                placeholder={`${t('header.select')} ${t(
                                  'field.countryCode',
                                )}`}
                                optionFilterProp="children"
                                onChange={(value) =>
                                  onChange('countrycode', value)
                                }
                                filterOption={filterOption}
                                options={countryCode}
                                value={formValue?.countrycode || ''}
                              />
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-6 mt-2">
                              <Label
                                className={'form-label'}
                                text={
                                  <>
                                    {t('field.phoneNumber')}
                                    <small className="small-err">*</small>
                                  </>
                                }
                              />
                              <InputField
                                name={'venuPhoneNumber'}
                                placeholder={`${t('field.pleaseEnter')} ${t('field.phoneNumber')}`}
                                value={formValue?.venuPhoneNumber}
                                setFormValue={setFormValue}
                                formValue={formValue}
                                setErr={setErr}
                                onChange={handleChangeValue}
                                type={'text'}
                                className={
                                  'form-control vendor-account-details-input-field'
                                }
                                id={'venuPhoneNumber'}
                              />
                              <Small
                                name={venuPhoneNumber}
                                err={err.venuPhoneNumber}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="accountDetails_ThaiNameDiv mt-2">
                          <div>
                            <label className="form-label">
                              {t('vendorField.venueEnglishDes')}
                              <small className="small-err">*</small>
                            </label>
                          </div>
                          <div className="accountDetails_TrixDiv">
                            <ReactQuill
                              theme="snow"
                              value={formValue?.venueEnglishDescription}
                              onChange={(e) =>
                                handleDescriptionChange(
                                  'venueEnglishDescription',
                                  e,
                                )
                              }
                              modules={modules}
                              formats={formats}
                              placeholder={t('field.serviceStorePlace')}
                            />
                            <Small
                              className={'accountDetails_Address_Outer_Div_err'}
                              name={'venueEnglishDescription'}
                              err={err.venueEnglishDescription}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="accountDetails_ThaiNameDiv mt-2">
                          <div>
                            <label className="form-label">
                              {t('vendorField.venueThaiDes')}
                              <small className="small-err">*</small>
                            </label>
                          </div>
                          <div className="accountDetails_TrixDiv">
                            <ReactQuill
                              theme="snow"
                              value={formValue?.venueThaiDescription}
                              onChange={(e) =>
                                handleDescriptionChange(
                                  'venueThaiDescription',
                                  e,
                                )
                              }
                              modules={modules}
                              formats={formats}
                              placeholder={t('field.serviceStorePlace')}
                            />
                            <Small
                              className={'accountDetails_Address_Outer_Div_err'}
                              name={'venueThaiDescription'}
                              err={err?.venueThaiDescription}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`accountDetails_Address_Outer_Div mt-3 ${(!err?.venueThaiDescription || !err.venueEnglishDescription) && 'accountDetails_Address_Outer_Div_error_active'}`}
                    >
                      <div className="accountDetails_Address_Heading">
                        {t('vendor.whereYouLocate')}
                      </div>
                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-2">
                          <div className="accountDetails_Address_Inner_Div">
                            <Label
                              className={'form-label'}
                              text={
                                <>
                                  {t('field.postalCode')}
                                  <small className="small-err">*</small>
                                </>
                              }
                            />
                            <Select
                              className="signUp-customeSelect-ant"
                              showSearch
                              placeholder={`${t('header.select')} ${t(
                                'field.postalCode',
                              )}`}
                              optionFilterProp="children"
                              onChange={(value) => onChange('postcode', value)}
                              filterOption={filterOption}
                              options={postalCodeArray}
                              value={formValue?.postcode || ''}
                            />
                            <Small name={postcode} err={err.postCode} />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-2">
                          <div className="accountDetails_Address_City_Div">
                            <Label
                              className={'form-label'}
                              text={
                                <>
                                  {t('field.subDistrict')}
                                  <small className="small-err">*</small>
                                </>
                              }
                            />
                            <Select
                              className="finace-ant-select-form"
                              placeholder={`${t('header.select')} ${t(
                                'field.subDistrict',
                              )}`}
                              optionFilterProp="children"
                              onChange={(value) => onChange('city', value)}
                              options={subDistrictOption}
                              value={formValue?.city || ''}
                            />
                            <Small name={city} err={err.city} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 accountDetails_Address_Inner_Div mt-2">
                          <Label
                            className={'form-label'}
                            text={
                              <>
                                {t('field.address')}
                                <small className="small-err">*</small>
                              </>
                            }
                          />
                          <InputField
                            placeholder={`${t('field.pleaseEnter')} ${t('field.address')}`}
                            name={'address'}
                            value={formValue?.address}
                            setFormValue={setFormValue}
                            formValue={formValue}
                            setErr={setErr}
                            onChange={handleChangeValue}
                            type={'text'}
                            className={
                              'form-control vendor-account-details-input-field'
                            }
                            id={'address'}
                          />
                          <Small name={address} err={err.address} />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 mt-2 mb-4">
                          <div className="accountDetails_Website_Div">
                            <Label
                              className={'form-label'}
                              text={<>{t('vendorField.website')}</>}
                            />
                            <InputField
                              name={'website'}
                              placeholder={`${t('field.pleaseEnter')} ${t('vendorField.website')}`}
                              value={formValue?.website}
                              setFormValue={setFormValue}
                              formValue={formValue}
                              setErr={setErr}
                              onChange={handleChangeValue}
                              type={'text'}
                              className={
                                'form-control vendor-account-details-input-field'
                              }
                              id={'website'}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="accountDetails_Contact_Outer_Div mt-2">
                        <div className="accountDetails_saveButton_Div mt-2 ">
                          <CustomButton
                            className="btn"
                            name={t('button.saveButton')}
                            onClick={handleSubmit}
                            formValue={formValue}
                            setErr={setErr}
                            formType="accountEdit"
                            disabled={buttonDisabled}
                            setButtonDisabled={setButtonDisabled}
                            t={t}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoader && (
          <>
            <CustomSpinner />
          </>
        )}
      </div>
    </>
  );
};
AccountDetails.propTypes = {
  subDistrictOption: PropTypes.array,
  filterOption: PropTypes.func,
  onChange: PropTypes.func,
  venueEnglishDescription: PropTypes.string,
  venueThaiDescription: PropTypes.string,
  deleteImage: PropTypes.func,
  formValue: PropTypes.object,
  setFormValue: PropTypes.func,
};

export default AccountDetails;