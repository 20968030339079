import { Helmet } from "react-helmet";

const description = 'Sura360 offers comprehensive digital solutions tailored to your business needs.';
const title = "Sura360 - Relax. Refresh. Rejuvenate"
const englishKeywords = `
  Spa, Massage, Sura360, Service, Wellness center, Spa services, Spa treatments, Spa packages, Spa near me, Spa offers, 
  Incall, Outcall, Spa & Massage, Beauty Care, Hair Removal, Dental Clinic, Best Seller, Relax, Refresh, Rejuvenate, 
  Vendor, Freelancer, Pregnancy Massage, Breast Massage, Office Syndrome Massage, Head & Shoulder Massage, Foot Massage, 
  4 Hands Massage, Couple Massage, Deep Tissue Massage, Swedish Massage, Aroma Massage, Relaxation Massage, Oil Massage, 
  Thai Massage, Root Canal, Retainers, Implant, Teeth Aligners, Teeth Whitening, Teeth Cleaning, Dental Check Up, 
  Eyebrow Basics, Face Hair Removal & Upper Lip, Bikini Waxing, Full Leg Waxing, Half Leg Waxing, Full Arms, Under Arms 
  Waxing, Bust Lifting & Firming, Anti-Aging Treatment, Pedicure, Manicure, Bleach & Detan, Facial, Body Scrub
`.trim().replace(/\s\s+/g, ' ');

const thaiKeywords = `
  สปา, เส้นผม, ซาลอน, สปา, นวด, ซูรา360, บริการ, ศูนย์สุขภาพ, บริการสปา, การบำบัดสปา, แพ็คเกจสปา, 
  สปาใกล้ฉัน, ข้อเสนอของสปา, การนวดในสถานที่, การนวดออกสถานที่, สปา & นวด, การดูแลความงาม, 
  การกำจัดขน, คลินิกทันตกรรม, ขายดี, ผ่อนคลาย, สดชื่น, ฟื้นฟู, ผู้ขาย, ฟรีแลนซ์, การนวดระหว่างตั้งครรภ์, 
  การนวดหน้าอก, การนวดออฟฟิศซินโดรม, การนวดหัวและไหล่, การนวดเท้า, การนวด 4 มือ, การนวดคู่, 
  การนวดเนื้อเยื่อลึก, การนวดสวีดิช, การนวดอโรม่า, การนวดเพื่อการผ่อนคลาย, การนวดน้ำมัน, การนวดไทย, 
  การรักษารากฟัน, เครื่องมือจัดฟัน, การปลูกฟัน, เครื่องมือจัดฟัน, การฟอกสีฟัน, การทำความสะอาดฟัน, 
  การตรวจสุขภาพฟัน, การดูแลขนคิ้วพื้นฐาน, การกำจัดขนหน้าและริมฝีปากบน, การแวกซ์บิกินี่, การแวกซ์ขาเต็ม, 
  การแวกซ์ขาครึ่งหนึ่ง, การแวกซ์แขนเต็ม, การแวกซ์รักแร้, การยกกระชับและฟื้นฟูหน้าอก, การรักษาผิวหน้า, 
  การทำเล็บเท้า, การทำเล็บมือ, การฟอกสีและการทำความสะอาด, การทำความสะอาดใบหน้า, การขัดผิว
`.trim().replace(/\s\s+/g, ' ');

const ogTitle="Sura360 - Your Comprehensive Digital Solution"

const ogDescription="Sura360 offers comprehensive digital solutions tailored to your business needs."

export const combinedKeywords = `${englishKeywords}, ${thaiKeywords}`;

const removeDescription = (content) => {
    if (!content) return "";

    const doc = new DOMParser().parseFromString(content, "text/html");
    const textContent = doc.body.textContent || "";

    const cleanedText = textContent.trim().replace(/\s\s+/g, " ");

    const periodIndex = cleanedText.indexOf(".");

    return periodIndex !== -1
      ? cleanedText.substring(0, periodIndex + 1)
      : cleanedText;
  };
export const MetaTag=({custom_title,custom_description,custom_ogtitle,custom_ogDescription})=>{
return(
    <Helmet>
    <title>{custom_title?`${custom_title} || Sura360`:title}</title>
    <meta name="description" content={`Sura360 - ${removeDescription(custom_description||description)}`}/>
    <meta property="og:title" content={custom_ogtitle?`${custom_ogtitle} || Sura360`:ogTitle}/>
    <meta property="og:description" content={`Sura360 - ${removeDescription(custom_ogDescription||ogDescription)}`}/>
  </Helmet>
)
}
