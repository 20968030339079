import './Style.css';
import { React, Link, Divider, Locale, PropTypes, useState, useEffect } from '../../libraries/index';
import { CustomButton, CustomCheckbox, CustomLink, CustomSpinner, Facebook, Google, InputField, Label, PasswordField } from '../../common';
import { SuraSign } from '../../assets/Images';
import { forgotPassword, signUp, user } from '../../Routes/routes.js';
const Login = ({
  isLoader,
  formValues,
  formErrors,
  handleChange,
  handleSubmit,
  favhandleSubmit,
  showModal,
  setShowModal,
  rememberMeChecked,
  handleRememberMeChange,
  type,
  s_id,
  userType,
}) => {
  const { t } = Locale();
  const closeModalBtnObj = document.getElementById('sign_modal_close_button');
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  useEffect(() => {
    // Check if both fields have values
    const isFormValid = formValues.username && formValues.password;
    setIsSubmitEnabled(isFormValid);
  }, [formValues]);

  return (
    <>
      {showModal ? (
        <>
          <div className="modal-backdrop"></div>
          <div
            className={`modal fade ${showModal ? 'd-block show' : 'd-none'}`}
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-fav modalPopup-body"
              id="exampleModal"
            >
              <div className="modal-content fav-modal">
                <div className="modal-body">
                  <button
                    id="sign_modal_close_button"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  ></button>
                  <h4 className="signup-form-heading3">{t('header.signin')}</h4>
                  <form
                    onSubmit={(e) =>
                      favhandleSubmit(e, type, s_id, userType, closeModalBtnObj)
                    }
                    method="POST"
                    className="modal-body-form"
                  >
                    <div className="form-group mb-2">
                      <Label text={t('field.email')}></Label>
                      <InputField
                        name="username"
                        placeholder={`${t('field.placeholder')} ${t('field.email')}`}
                        className="form-control"
                        value={formValues?.username}
                        onChange={(e) => handleChange(e, 'signInModal')}
                        error={formErrors?.username}
                      ></InputField>
                    </div>
                    <div className="form-group mb-2">
                      <Label text={t('field.password')}></Label>
                      <PasswordField
                        name="password"
                        placeholder={`${t('field.placeholder')} ${t('field.password')}`}
                        className="password-field"
                        value={formValues?.password}
                        type="password"
                        onChange={(e) => handleChange(e, 'signInModal')}
                        error={formErrors?.password}
                      ></PasswordField>
                    </div>
                    <span>
                      <CustomCheckbox
                        text={t('signMessage.rememberMe')}
                        className="checkbox"
                        checked={rememberMeChecked}
                        onChange={handleRememberMeChange}
                      />
                      <CustomLink
                        text={t('signMessage.forgotPassword')}
                        path={forgotPassword}
                        color="#EE0A89"
                        className="signin-forgetPassword1"
                      />
                    </span>
                    <div>
                      <div className="sign-in-sign-in-btn-div">
                        <CustomButton
                          color="white"
                          text={t('header.signin')}
                          className="signInbtn-color"
                          buttonClicked={!isSubmitEnabled}
                        ></CustomButton>
                      </div>
                    </div>
                    <div className="text-center">
                      <div>
                        <div className="row">
                          <Divider className="solid-black">
                            {t('signMessage.signWith')}
                          </Divider>
                        </div>
                        <Facebook
                          className="btn-social"
                          text={t('signMessage.signWithfb')}
                        />
                        <Google
                          className="btn-social mx-3"
                          msg={t('signMessage.signWithgoogle')}
                        />
                      </div>
                    </div>
                    <div className="navigate-signup mt-2">
                      {t('signMessage.dontAccount')}
                      <CustomLink
                        text={t('signMessage.signUpnow')}
                        path={`${signUp}${user}`}
                        color="#EE0A89"
                        className="mx-1"
                      ></CustomLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {isLoader && (
            <>
              <CustomSpinner />
            </>
          )}
        </>
      ) : (
        <>
          <div className={` no-gutter ${isLoader && 'processBlur'}`}>
            <div className="inner-div container sign-in-final">
              <div className="row ">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div className=" d-md-block bg-image text-center sign-in-img-div">
                    <img
                      src={SuraSign}
                      alt="SignIn"
                      className="w-85 p-2 img-fluid signIn-image"
                    />
                    <div className="signUp-home-arrow-div">
                      <i className="bi bi-arrow-left-short"></i>&nbsp;
                      <Link to="/" className="back-to-home">
                        {t('button.backToHome')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div className="signin-form-container">
                    <h2 className="signup-form-heading mt-3">
                      {t('signMessage.welcome')}
                    </h2>
                    <h5 className="signup-form-heading2">
                      {t('signMessage.signAccount')}
                    </h5>
                    <div className="signin-formfield">
                      <form onSubmit={handleSubmit} method="POST">
                        <div className="form-group mb-3 sign-in-form-div">
                          <Label text={t('field.email')}></Label>
                          <InputField
                            name="username"
                            placeholder={`${t('field.placeholder')} ${t('field.email')}`}
                            className="form-control"
                            value={formValues?.username}
                            onChange={handleChange}
                            error={formErrors?.username}
                          ></InputField>
                        </div>
                        <div className="form-group mb-3">
                          <Label text={t('field.password')}></Label>
                          <PasswordField
                            name="password"
                            placeholder={`${t('field.placeholder')} ${t('field.password')}`}
                            className="password-field "
                            value={formValues?.password}
                            type="password"
                            onChange={handleChange}
                            error={formErrors?.password}
                          ></PasswordField>
                        </div>
                        <div className="justify__space_between">
                          <CustomCheckbox
                            text={t('signMessage.rememberMe')}
                            className="checkbox"
                            checked={rememberMeChecked}
                            onChange={handleRememberMeChange}
                          />
                          <div>
                            <CustomLink
                              text={t('signMessage.forgotPassword')}
                              path={forgotPassword}
                              color="#EE0A89"
                              className="signin-forgetPassword"
                            />
                          </div>
                        </div>
                        <div className="sign-in-sign-in-btn-div">
                          <CustomButton
                            color="white"
                            text={t('header.signin')}
                            className="signInbtn-color"
                            buttonClicked={!isSubmitEnabled}
                          ></CustomButton>
                        </div>
                        <div className="text-center">
                          <div>
                            <div className="row">
                              <Divider className="solid-black">
                                {t('signMessage.signWith')}
                              </Divider>
                            </div>
                            <Facebook
                              className="btn-social"
                              text={t('signMessage.signWithfb')}
                            />
                            <Google
                              className="btn-social mx-3"
                              msg={t('signMessage.signWithgoogle')}
                            />
                          </div>
                        </div>
                        <div className="navigate-signup mt-2">
                          {t('signMessage.dontAccount')}
                          <CustomLink
                            text={t('signMessage.signUpnow')}
                            path={`${signUp}${user}`}
                            color="#EE0A89"
                            className="mx-1"
                          ></CustomLink>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLoader && (
            <>
              <CustomSpinner />
            </>
          )}
        </>
      )}
    </>
  );
};
Login.propTypes = {
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  favhandleSubmit: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  rememberMeChecked: PropTypes.bool,
  handleRememberMeChange: PropTypes.func,
  type: PropTypes.string,
  s_id: PropTypes.string,
  userType: PropTypes.string,
};
export default Login;