import './PageNotFound.css'
import { Locale, React, useNavigate } from '../../libraries';
import {  bussiness, dashboard, home  } from '../../Routes/routes';
import { PageNotFoundImage } from '../../assets/Images';
const PageNotFound = () => {
    const { t } = Locale();
    const navigate = useNavigate();
    const role=localStorage.getItem("role")
    const navigateHome = (e) => {
        e.preventDefault();
        if ((role === 'Vendor' || role === 'freelancer' || role === 'TeamMember'))
            navigate(`${bussiness}${dashboard}`)
        else navigate(home)
    }
    return <>
        <div className='page-not-found-outer-div'>
            <img
                alt={'page-not-found-img'}
                src={PageNotFoundImage}
                className='page-not-found-img'
            />
            {/* <div className='page-not-found-btn'>
                <i className="bi bi-arrow-left mt-4 mb-4 "></i>&nbsp;
                <Link
                    onClick={(e) => navigateHome(e)}
                    className="back-to-home fa-empty-card"
                >
                    {t('button.backToHome')}
                </Link>
            </div> */}
            <button
                onClick={(e) => navigateHome(e)}
                className='page-not-found-btn'
            >
                <i className="bi bi-arrow-left mt-4 mb-4 "></i>&nbsp;  {t('button.backToHome')}
            </button>

        </div>
    </>
}
export default PageNotFound;