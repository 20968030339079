import { apiCall } from './base';

const getAllCategory = async (params) => {
  const response = await apiCall('customer/categories', 'GET', params);
  return response;
};
const getSubCategory = async (params) => {
  const response = await apiCall(`customer/sub_categories/${params}`, 'GET');
  return response;
};

const getBestSellerSubCategory = async () => {
  const response = await apiCall('customer/categories/details', 'GET');
  return response;
};

const getSubCategoryService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await apiCall(`customer/sub_category/services?${queryString}`,'GET');
  return response;
};

const getSearchService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await apiCall(`customer/search?${queryString}`, 'GET');
  return response;
};
const getViewAllDetails = async (params) => {
  const response = await apiCall(`customer/organization/${params}`, 'GET');
  return response;
};
export {
  getAllCategory,
  getBestSellerSubCategory,
  getSubCategory,
  getSubCategoryService,
  getSearchService,
  getViewAllDetails,
};
