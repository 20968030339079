import React from 'react';
// ------------------------------------ app.js file import component-----------------------
const Home = React.lazy(() => import('../pages/NewHome/Home.js'));
const SignUp = React.lazy(() => import('../components/signUp/signUpForm.js'));
const ForgotPasswordPage = React.lazy(() => import('../common/forgotPassword/forgotPasswordPage.js'));
const AboutUsLogic = React.lazy(() => import('../components/aboutUs/aboutUsLogic.js'));
const PrivacyPolicyLogic = React.lazy(() => import('../components/privacyPolicy/privacyPolicyLogic.js'));
const ContactUsLogic = React.lazy(() => import('../components/contactUs/contactUsLogic.js'));
const FAQLogic = React.lazy(() => import('../vendor/component/fAQ/fAQ.js'));
const BussinessPolicyLogic = React.lazy(() => import('../components/bussinessPolicy/bussinessPolicyLogic.js'));
const CancelPolicyLogic = React.lazy(() => import('../components/cancelPolicy/cancelPolicyLogic.js'));
const TermConditionLogic = React.lazy(() => import('../components/termCondition/termConditionLogic.js'));
const DisplayServiceLogic = React.lazy(() => import('../components/displayService/displayService.js'));
const Payment = React.lazy(() => import('../pages/Payment/Payment.js'));
const CartItemsLogic = React.lazy(() => import('../components/cartItemsPayment/cartItems.js'));
const OrderHistoryLogic = React.lazy(() => import('../components/OrderHistory/OrderHistory.js'));
const SearchAndDisplayDescLogic = React.lazy(() => import('../components/searchAndDisplayDesc/searchAndDisplayDesc.js'));
const VendorShopMap = React.lazy(() => import('../pages/Map/VendorShopMap.js'));

// -------------------------------- user.js file import component------------------------
const UserEditProfileForm = React.lazy(() => import('../pages/UserProfile/UserProfileForm.js'));
const UserBookingsList = React.lazy(() => import('../pages/UserBooking/UserBookingList/UserBookingList.js'));
const CashbackLogic = React.lazy(() => import('../components/cashBackPage/cashBack.js'));
const ReferralLogic = React.lazy(() => import('../components/referralPage/referral.js'));
const ShowAllShop = React.lazy(() => import('../components/reviewAndRatings/shopReviewAndRating/getAllShop.js'));
const ReviewRatingServiceLogic = React.lazy(() => import('../components/reviewAndRatings/reviewAndRatingService/reviewRatingService.js'));
const AddToFavouriteLogic = React.lazy(() => import('../components/addToFavourite/addToFavourite.js'));
const AddToFavouriteServiceLogic = React.lazy(() => import('../components/addToFavouriteService/addToFavouriteService.js'));
const AddCart = React.lazy(() => import('../components/addToCart/addToCart.js'));
const AccountDeletion = React.lazy(() => import('../pages/AccountDeletion/AccountDeletion.js'),);
const AccountDeletionAction = React.lazy(() => import('../components/accountDeletion/accountDeletion.js'),);
const CancelDeletionAction = React.lazy(() => import('../components/cancelDeletion/cancelDeletion.js'));

// ------------------------------ home.js file import component-----------------------
const NavbarLogic = React.lazy(() => import('../components/navbar/navbar.js'));
const HomeHeader = React.lazy(() => import('../pages/NewHome/Header/HomeHeader.js'));
const HomeOurService = React.lazy(() => import('../pages/NewHome/OurService/HomeOurService.js'));
const HomeCategory = React.lazy(() => import('../pages/NewHome/Category/HomeCategory'));
const HomeSlider = React.lazy(() => import('../pages/NewHome/Slider/HomeSlider'));
const HomeBestSeller=React.lazy(()=>import('../pages/NewHome/BestSelling/HomeBestSelling.js'))
const WebSiteUpdate = React.lazy(() => import('../pages/NewHome/WebsiteUpdate/WebsiteUpdate'))

// ---------------------------- homeHeader.js file import component -----------------------
const CustomImage = React.lazy(() => import('../common/image/image.js'));

// ---------------------------- HomeSlider.js file import component -----------------------
const NewCarousal = React.lazy(() => import('../common/textCarousal/newCarousal.js'));

// ---------------------------- order history page component--------------------------------
const OrderHistory = React.lazy(() => import('../pages/OrderHistory/orderHistory.js'));

export {
    OrderHistory,
    NewCarousal,
    CustomImage,
    HomeBestSeller,
    NavbarLogic,
    HomeHeader,
    HomeOurService,
    HomeCategory,
    HomeSlider,
    WebSiteUpdate,
    Home,
    SignUp,
    ForgotPasswordPage,
    AboutUsLogic,
    PrivacyPolicyLogic,
    ContactUsLogic,
    FAQLogic,
    BussinessPolicyLogic,
    CancelPolicyLogic,
    TermConditionLogic,
    DisplayServiceLogic,
    Payment,
    CartItemsLogic,
    OrderHistoryLogic,
    SearchAndDisplayDescLogic,
    VendorShopMap,
    UserEditProfileForm,
    UserBookingsList,
    CashbackLogic,
    ReferralLogic,
    ShowAllShop,
    ReviewRatingServiceLogic,
    AddToFavouriteLogic,
    AddToFavouriteServiceLogic,
    AddCart,
    AccountDeletion,
    AccountDeletionAction,
    CancelDeletionAction
}

