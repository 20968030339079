import './DisplayServices.css';
import { Link, useLocation, Checkbox, Locale, Accordion, Drawer, React, useEffect, useState, Rate, useParams } from '../../libraries/index.js';
import { DefaultServiceImg } from '../../assets/Images';
import { AddressJson, DiscountPercent } from '../../jsonData';
import { Footer, InputField, RadioButton, CustomSpinner, EmptyComponent } from '../../common';
import { NavbarLogic, DisplayServices, BookNowModalLogic, SignIn } from '../../components';
import { SidebarFilter } from './SidebarFilter.js';
import { home, services } from '../../Routes/routes.js';
import { CustomImage } from '../../lazyComponent';
import { urlDeFormat, urlFormat } from '../../customHooks/urlFormat.js';
const Alternative = () => {
  const location = useLocation();
  const params = useParams();
  const { searchText, value } = params || {}
  const {
    defaultOpenAccordianA,
    serviceType,
    defaultOpenAccordian,
    setSearchTermArea,
    areaSearch,
    sortBy,
    filteredServices,
    handleLikeClick,
    handleSortChange,
    searchTerm,
    setSearchTerm,
    selectedCity,
    handleCityCheckboxChange,
    allData,
    handleCategoryCheckboxChange,
    handleAllSubcategoriesCheck,
    handleFeaturedServicesChange,
    showModal,
    setShowModal,
    handleAddToCart,
    type,
    commonId,
    userType,
    handleAddToCartCount,
    viewAllFunction,
    categoryId,
    categoryIds,
    sortOptions,
    handleLoadMore,
    showSort,
    showDrawer,
    onClose,
    open,
    openSort,
    selectedLanguage,
    categoryName,
    subCategoryName,
    previousState,
    isLoading,
    useAllData,
    filterLoading,
    cartItem,
    getSpecialPrice,
    getHearIconClassName,
    getServiceName,
    getVenueName,
    getBreadCrumbArgument,
    getBreadCrumbName,
    showCategoryName,
    showCategoryNameThai,
    currentPage,
    loader,
    setLoader,
    categoryNameBread,
  } = DisplayServices();
  const checkedCityCount = selectedCity.length;
  const role = localStorage.getItem('role');
  const th = localStorage.getItem('i18nextLng');
  const { t } = Locale();
  const currentUrl = location.pathname;
  const isServicePage = params?.subCategoryName || searchText;
  const isBestSellers = urlDeFormat(value) === 'Best Sellers'
  const isSelectedLanguageEnglish = selectedLanguage === 'en';
  const isSelectedLanguageThai = selectedLanguage === 'thai';
  const isCategoryPage = !isServicePage;
  const getCartDisable = (serviceIs, outer) => {
    if (cartItem?.includes(serviceIs)) {
      return !outer ? 'disabled-icon' : 'disable-icon-outer';
    } else {
      return 'inabled-icon';
    }
  };

  const [isAccordianOpen, setIsAccordianOpen] = useState(false);

  // Arrange Address in  Ascending order logic here
  const AddressJsonAscending = AddressJson?.sort((a, b) =>
    a.districtNameEn.localeCompare(b.districtNameEn),
  );

  // Arrange service in  Ascending order logic here
  const serviceTypeAscending =
    serviceType.length > 0 &&
    serviceType.sort((a, b) => {
      return a?.category?.name?.localeCompare(b?.category?.name);
    });

  const deletionRequest = localStorage.getItem('status') === 'requested';

  const debounce = (func, delay) => {
    let timeoutId;
    let isRunning = false;
    
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
  
      if (!isRunning) {
        isRunning = true;
        func.apply(null, args);
        timeoutId = setTimeout(() => {
          isRunning = false;
        }, delay);
      } else {
        timeoutId = setTimeout(() => {
          isRunning = false;
          func.apply(null, args);
        }, delay);
      }
    };
  };
  

  useEffect(() => {
    const handleScroll = debounce(() => {
      const footer = document.querySelector("#footer");
      if (!footer) return;
  
      const footerPosition = footer.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
  
      if (footerPosition - windowHeight <= 100) {
        if (currentPage < filteredServices?.total_pages && !isLoading) {
          setLoader(true);
          handleLoadMore(); // This will increment the page and fetch new data
        }
      }
    }, 200); // Debounce delay
  
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);
  
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [filteredServices?.total_pages, currentPage, isLoading]);
  


  // useEffect(() => {
  //   const handleScroll = () => {
  //     const footer = document.querySelector("#footer");
  //     if (!footer) return;
  //     const footerPosition = footer.getBoundingClientRect().top;
  //     const windowHeight = window.innerHeight;

  //     if (footerPosition - windowHeight <= 100) {
  //       if (((currentPage) < (filteredServices?.total_pages)) && !isLoading) {
  //         setLoader(true);
  //         handleLoadMore();
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [currentPage, filteredServices?.total_pages, isLoading, currentUrl]);

  const formatPrice = (price) => {
    const priceStr = String(price);
    return priceStr.includes('.') ? priceStr : `${priceStr}.00`;
  };
  const getCateoryNameUrl = (categoryName) => {
    return urlFormat(categoryName);
  }


  return (
    <>
      <NavbarLogic
        handleAddToCartCount={handleAddToCartCount}
        showDrawer={showDrawer}
        showSort={showSort}
        serviceType={serviceType}
      />
      <div className="display-service-pages-outer-container">
        <div
          className={`after-category-new-div container display-service-outer-div dsplay-service-outer ${deletionRequest && 'deletionRequest-user-bradcrumb-outer'}`}
        >
          <div className="breadcrumb-outer-div">
            <nav
              lang="sk"
              role="navigation"
              aria-label="Omrvinková navigácia"
              className="breadcrumb"
            >
              <ol>
                <li>
                  <Link to={home} className="breadcrumb-link-tag">
                    {t('breadcrumb.home')}
                  </Link>
                </li>
                <span aria-hidden="true" className="breadcrumb-separator">
                  &gt;
                </span>
                {isServicePage && !(searchText) ? (
                  <>
                    <li className="breadcrumb-li-tag">
                      <Link
                        to={`${services}/${getCateoryNameUrl(categoryName?.categoryNameEn)}`}
                        className="breadcrumb-link-tag"
                      >

                        {getBreadCrumbName(categoryName, 'category')}
                      </Link>
                    </li>
                    <span aria-hidden="true" className="breadcrumb-separator">
                      &gt;
                    </span>
                    <li className="breadcrumb-li-tag">
                      {getBreadCrumbName(subCategoryName, 'subcategory')}
                    </li>{' '}
                  </>
                ) : (
                  <>
                    <li className="breadcrumb-li-tag">
                      {allData.length === 0 ?
                        ((isSelectedLanguageEnglish) ? (showCategoryName) ? (showCategoryName) : ((searchText) ? (searchText) : (isCategoryPage) ? (categoryNameBread?.categoryNameEn) : " ") : ((showCategoryNameThai) ? (showCategoryNameThai) : ((searchText) ? (searchText) : (isCategoryPage) ? (categoryNameBread?.categoryNameTh) : isBestSellers ? (isSelectedLanguageEnglish ? 'Best Sellers' : 'สินค้าขายดี') : "")))
                        :

                        isSelectedLanguageEnglish ? (categoryNameBread?.categoryNameEn) : (categoryNameBread?.categoryNameTh)
                      }

                    </li>
                  </>
                )}
              </ol>
            </nav>
          </div>

          {(!filteredServices) || (isLoading) ? (
            <div className="loading-spinner text-center">
              <CustomSpinner />
            </div>
          ) : !filteredServices.shops_info ? (
            <div>
              <EmptyComponent type="notFound" />
            </div>
          ) : (<>
            <div className='div'>
              <div className='row'>
                <div className='col-md-3  display-service-section-outer-filter-col-3'>
                  <div className='display-service-section-filter'>
                    <SidebarFilter
                      handleCategoryCheckboxChange={handleCategoryCheckboxChange}
                      handleFeaturedServicesChange={handleFeaturedServicesChange}
                      handleSortChange={handleSortChange}
                      setSearchTermArea={setSearchTermArea}
                      handleAllSubcategoriesCheck={handleAllSubcategoriesCheck}
                      sortBy={sortBy}
                      allData={allData}
                      checkedCityCount={checkedCityCount}
                      open={open}
                      areaSearch={areaSearch}
                      handleCityCheckboxChange={handleCityCheckboxChange}
                      selectedCity={selectedCity}
                      th={th}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      defaultOpenAccordian={defaultOpenAccordian}
                      defaultOpenAccordianA={defaultOpenAccordianA}
                      serviceType={serviceType}
                      subCategoryId={categoryId}
                      categoryId={categoryIds}
                      sortOptions={sortOptions}
                      useAllData={useAllData}
                    />
                    <Drawer className="filter-drawer" title={t('field.filters')} onClose={onClose} open={open} placement="right">
                      <div className="container-fluid display-service-sidebar-container2Outer">
                        <p className="mt-2 showFeaturedService">
                          {t('header.area')} ({checkedCityCount})
                        </p>
                        <div>
                          <InputField
                            placeholder={`${t('header.search')} ${t('header.area')} ${t('header.here')}`}
                            value={areaSearch}
                            onChange={(e) => setSearchTermArea(e.target.value)}
                          />
                        </div>
                        <div className="display-service-sidebar-container2 mb-3">
                          <div className="row mt-2 mb-3">
                            {AddressJsonAscending?.filter((item) =>
                              item.districtNameEn
                                .toLowerCase()
                                .includes(areaSearch.toLowerCase()),
                            ).map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="display-service-sidebar-container2-row mt-2"
                                >
                                  <Checkbox
                                    className="checkbox-button"
                                    onChange={() =>
                                      handleCityCheckboxChange(item.districtNameEn)
                                    }
                                    ischecked={
                                      selectedCity.includes(item.districtNameEn)
                                        ? 'true'
                                        : 'false'
                                    }
                                  >
                                    {th === 'thai' && th !== undefined
                                      ? item.districtNameTh
                                      : item.districtNameEn}
                                  </Checkbox>
                                  <span></span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div>
                        <hr className="mt-3" />
                      </div>

                      <div className="container-fluid display-service-sidebar-container3Outer">
                        <p className="mt-2 showFeaturedService">
                          {t('header.serviceType')} (
                          {allData.length > 0
                            ? allData.length
                            : categoryId
                              ? 1
                              : categoryIds
                                ? categoryIds.length
                                : 0}
                          )
                        </p>
                        <div className="mb-2">
                          <InputField
                            placeholder={`${t('header.search')} ${t('header.serviceType')} ${t('header.here')}`}
                            value={searchTerm}
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                              defaultOpenAccordian();
                            }}
                          />
                        </div>


                        <div className="display-service-filter-service-outer container-fluid  display-service-sidebar-container2">
                          <div className="row">
                            <Accordion
                              onClick={() => setIsAccordianOpen(!isAccordianOpen)}
                              defaultActiveKey={defaultOpenAccordianA?.map((a) =>
                                a.toString(),
                              )}
                            >
                              {serviceTypeAscending.length > 0 &&
                                serviceTypeAscending
                                  ?.filter((item) => {
                                    let status = false;
                                    item?.subcategory?.some((sub) => {
                                      if (
                                        sub?.name_en
                                          ?.toLowerCase()
                                          ?.includes(searchTerm.toLowerCase())
                                      ) {
                                        status = true;
                                        return true; // Return true when the condition is met
                                      }
                                      return false; // Ensure that false is returned when the condition is not met
                                    });
                                    return status;
                                  })
                                  ?.map((category, index) => {
                                    return (
                                      <Accordion.Item
                                        key={category.category.id}
                                        eventKey={index.toString()}
                                        className="accordion-header"
                                      >
                                        <Accordion.Header className="accordion-header-icon mt-0 m-0 p-0">
                                          <Checkbox
                                            className="checkbox-button"
                                            onChange={(e) =>
                                              handleAllSubcategoriesCheck(
                                                e,
                                                category.category.id,
                                                isSelectedLanguageThai &&
                                                  selectedLanguage !== undefined &&
                                                  selectedLanguage !== null
                                                  ? category.category.name_thai
                                                  : category.category.name_en,
                                              )
                                            }
                                            checked={category.subcategory.every((sub) =>
                                              categoryIds?.includes(sub.id),
                                            )}
                                          >
                                            {isSelectedLanguageThai &&
                                              selectedLanguage !== undefined &&
                                              selectedLanguage !== null ? (
                                              <> {category.category.name_thai}</>
                                            ) : (
                                              <>{category.category.name_en}</>
                                            )}
                                          </Checkbox>
                                        </Accordion.Header>
                                        <Accordion.Body className="accordion-body mt-0 mb-0">
                                          {category.subcategory
                                            .sort((a, b) =>
                                              a.name_en.localeCompare(b.name_en),
                                            )
                                            .map((subCategory) => (
                                              <div key={subCategory.id}>
                                                <Checkbox
                                                  className="mt-0 checkbox-button"
                                                  onChange={() =>
                                                    handleCategoryCheckboxChange(
                                                      subCategory.id,
                                                    )
                                                  }
                                                  checked={
                                                    categoryId === subCategory.id
                                                      ? true
                                                      : categoryIds?.includes(subCategory.id)
                                                  }
                                                >
                                                  {isSelectedLanguageThai &&
                                                    selectedLanguage !== undefined &&
                                                    selectedLanguage !== null ? (
                                                    <>{subCategory.name_thai} </>
                                                  ) : (
                                                    <> {subCategory.name_en}</>
                                                  )}
                                                </Checkbox>
                                                <br />
                                              </div>
                                            ))}
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    );
                                  })}
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </Drawer>
                    <Drawer className="sort-by-drawer" title={t('header.sortBy')} onClose={onClose} open={openSort} placement="left">
                      {sortOptions.map((option, index) => (
                        <div className="row mt-2" key={index}>
                          <RadioButton
                            className="checkbox-button"
                            onChange={() =>
                              handleSortChange({ [option.value]: option.order })
                            }
                            checked={sortBy[option.value] === option.order}
                            name="sortOption"
                            bgValue={`${t(option.label)}`}
                          ></RadioButton>
                        </div>
                      ))}
                    </Drawer>
                  </div>
                </div>
                <div className='col-md-9'>
                  <div >
                    {!filteredServices.shops_info || filterLoading ? (
                      <div className="loading-spinner text-center">
                        <CustomSpinner center="loading-spinner" />
                      </div>
                    ) : (
                      <>
                        {filteredServices.shops_info
                          .map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {item.service_info.length > 0 ? (<>

                                  {/* ------------------------------------- top section search by service ---------------- */}
                                  <div className='display-service-container mb-4 shop-detail-div mt-1 new-display-service-info-outer'>
                                    <div>
                                      <div className='display-service-content-outer'>
                                        <div className='row'>
                                          <div className='col-8'>
                                            {item.vendor_info?.venue_name ? (
                                              <>
                                                <div
                                                  className="display-service-heading-new venue-name-h5"
                                                  color={'red'}
                                                  onClick={() =>
                                                    viewAllFunction(
                                                      item.vendor_info?.vendor_id,
                                                      getBreadCrumbArgument(item),
                                                      previousState,
                                                      item?.vendor_info?.venue_name
                                                    )
                                                  }
                                                >
                                                  {getVenueName(item)}
                                                </div>
                                                <div>
                                                  {item.vendor_info?.venue_name && (
                                                    <div className="display-service-location">
                                                      <i
                                                        className="bi bi-geo-alt-fill"
                                                        aria-hidden="true"
                                                      ></i>{' '}
                                                      &nbsp;{item.vendor_info?.city},
                                                      {item.vendor_info?.address}
                                                    </div>
                                                  )}
                                                </div>
                                              </>
                                            ) : (
                                              <div className="display-service-location-vn">
                                                <i
                                                  className="bi bi-geo-alt-fill"
                                                  aria-hidden="true"
                                                ></i>{' '}
                                                &nbsp;{item.vendor_info?.city},
                                                {item.vendor_info.address}
                                              </div>
                                            )}
                                          </div>
                                          <div className='col-4 text-end'>
                                            <div className="display-service-start">
                                              <Rate
                                                allowHalf
                                                disabled
                                                value={
                                                  item.vendor_info.vendor_average_rating
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='bg-white display-service-card-div'>
                                      <div className='row display-service-service-info-outer-div-col'>
                                        {item.service_info && item.service_info.slice(0, 4).map((service, index) => service.is_enabled && (
                                          <>

                                            <div
                                              key={service.service_id}
                                              className="col-lg-6 mt-1"
                                            >
                                              <div className="display-service-service-info-outer-div">
                                                <div className='row'>
                                                  <div className='col-5'>
                                                    <div className='display-service-service-image-div'>
                                                      <CustomImage
                                                        width={'unset'}
                                                        height={'unset'}
                                                        alt={'shop image'}
                                                        src={(service?.service_image) ? (service?.service_image) : (DefaultServiceImg)}
                                                        className={
                                                          'display-service-service-section-img cursor-point-class'
                                                        }
                                                        onClick={() =>
                                                          viewAllFunction(
                                                            item.vendor_info?.vendor_id,
                                                            getBreadCrumbArgument(item),
                                                            previousState,
                                                            item?.vendor_info?.venue_name
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className='col-7'>
                                                    <div className='display-service-service-section-info-outer-div'>
                                                      <div>
                                                        <div className='display-service-service-name-div'>
                                                          <div className='display-service-section-service-name-div cursor-point-class'
                                                            onClick={() =>
                                                              viewAllFunction(
                                                                item.vendor_info?.vendor_id,
                                                                getBreadCrumbArgument(item),
                                                                previousState,
                                                                item?.vendor_info?.venue_name
                                                              )
                                                            }
                                                          >
                                                            {getServiceName(
                                                              service,
                                                            )}
                                                          </div>

                                                          <div className="display-service-heart-icon-div2">
                                                            <i
                                                              className={`${getHearIconClassName(service)}`}
                                                              onClick={() =>
                                                                handleLikeClick(
                                                                  service
                                                                    .favourite
                                                                    .is_service_favourite,
                                                                  service
                                                                    .favourite
                                                                    .favourite_id,
                                                                  service.service_id,
                                                                  'service',
                                                                  role,
                                                                )
                                                              }
                                                            ></i>
                                                            <div
                                                              className={`modal fade ${showModal ? 'd-block show' : 'd-none'}`}
                                                              id="exampleModal"
                                                              tabIndex="-1"
                                                              aria-labelledby="exampleModalLabel"
                                                              aria-hidden="true"
                                                            >
                                                              <SignIn
                                                                setShowModal={
                                                                  setShowModal
                                                                }
                                                                showModal={
                                                                  showModal
                                                                }
                                                                type={type}
                                                                commonId={
                                                                  commonId
                                                                }
                                                                userType={
                                                                  userType
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className='display-service-service-section-service-time'>
                                                          <div className="min-content">
                                                            <i className="bi bi-clock-fill clock-icon"></i>
                                                            &nbsp;
                                                            {service.duration}{' '}
                                                            {t('paymentSection.min')}
                                                          </div>
                                                        </div>
                                                        {service?.discount_on_service &&
                                                          <>
                                                            <div className='display-service-service-section-discount-price-outer'>
                                                              <div className='display-service-special-price'>
                                                                <div className="yRaY8j A6+E6v">
                                                                  {service.price ? <>
                                                                    ฿ {formatPrice(service.price)}
                                                                  </> : <></>}
                                                                </div>
                                                              </div>
                                                              <div className='display-service-discount-percent'>
                                                                <DiscountPercent value={service?.discount_on_service} />
                                                              </div>
                                                              <div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        }
                                                        <div className='display-service-service-section-price-and-butoon'>
                                                          <div className='display-service-service-price-div'>
                                                            <div className="Nx9bqj CxhGGd">฿ {formatPrice(getSpecialPrice(service))}</div>
                                                          </div>
                                                          <div className='display-service-service-btn-action-div'>
                                                            <div className={service.special_price ? "cart-booking-btn" : "cart-booking-btn-specialprice"}>
                                                              <div
                                                                onClick={() =>
                                                                  handleAddToCart(
                                                                    service.service_id,
                                                                  )
                                                                }
                                                                data_bs_toggle="modal"
                                                                data_bs_target="#exampleModal"
                                                                className={` me-2 ${getCartDisable(service.service_id, true)}`}
                                                              >
                                                                <span>
                                                                  <i
                                                                    className={`bi bi-cart-plus-fill shop-card-share mt-1 ${getCartDisable(service.service_id)}`}
                                                                  ></i>
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <BookNowModalLogic
                                                                  index={index}
                                                                  className="display-service-service-section-bookn-button"
                                                                  service_id={
                                                                    service.service_id
                                                                  }
                                                                  vender_details={
                                                                    item.vendor_info
                                                                  }
                                                                  service_details={
                                                                    item.service_info
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className='row mt-1'>
                                                          {item.service_info > 4 && <>
                                                            <div>
                                                              <button
                                                                className="cta"
                                                                onClick={() =>
                                                                  viewAllFunction(
                                                                    item.vendor_info?.vendor_id,
                                                                    getBreadCrumbArgument(item),
                                                                    previousState,
                                                                    item?.vendor_info?.venue_name
                                                                  )
                                                                }
                                                              >
                                                                <span className="hover-underline-animation">
                                                                  {' '}
                                                                  {t(
                                                                    'globalItems.viewAllService',
                                                                  )}
                                                                </span>
                                                                <svg
                                                                  id="arrow-horizontal"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="30"
                                                                  height="10"
                                                                  viewBox="0 0 46 16"
                                                                >
                                                                  <path
                                                                    id="Path_10"
                                                                    data-name="Path 10"
                                                                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                                                    transform="translate(30)"
                                                                  ></path>
                                                                </svg>
                                                              </button>
                                                            </div>

                                                          </>}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>),
                                        )}
                                      </div>
                                      <div className='row mt-2'>
                                        {item?.service_info?.length > 4 &&
                                          <div>
                                            <button
                                              className="cta"
                                              onClick={() =>
                                                viewAllFunction(
                                                  item.vendor_info?.vendor_id,
                                                  getBreadCrumbArgument(item),
                                                  previousState,
                                                  item?.vendor_info?.venue_name
                                                )
                                              }
                                            >
                                              <span className="hover-underline-animation">
                                                {t(
                                                  'globalItems.viewAllService',
                                                )}
                                              </span>
                                              <svg
                                                id="arrow-horizontal"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="30"
                                                height="10"
                                                viewBox="0 0 46 16"
                                              >
                                                <path
                                                  id="Path_10"
                                                  data-name="Path 10"
                                                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                                  transform="translate(30)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>

                                        }

                                      </div>
                                    </div>
                                  </div>
                                </>
                                ) : (<>
                                  {/* ------------------------------------- top section search by shop name ---------------- */}

                                  <div className='display-service-container display-service-content-outer mb-4 shop-detail-div mt-1 new-display-service-info-outer'>
                                    <div className='row '>
                                      <div className='col-8'>
                                        <div>
                                          <div>
                                            {item.vendor_info?.venue_name ? (
                                              <div
                                                className="display-service-heading venue-name-h4"
                                                color={'red'}
                                                onClick={() =>
                                                  viewAllFunction(
                                                    item.vendor_info?.vendor_id,
                                                    getBreadCrumbArgument(item),
                                                    previousState,
                                                    item?.vendor_info?.venue_name
                                                  )
                                                }
                                              >
                                                {getVenueName(item)}
                                              </div>
                                            ) : (
                                              <div className="display-service-location-vn">
                                                <i
                                                  className="bi bi-geo-alt-fill"
                                                  aria-hidden="true"
                                                ></i>{' '}
                                                &nbsp;{item.vendor_info?.city},
                                                {item.vendor_info.address}
                                              </div>
                                            )}
                                          </div>
                                          <div>
                                            {item.vendor_info?.venue_name && (
                                              <div className="display-service-location">
                                                <i
                                                  className="bi bi-geo-alt-fill"
                                                  aria-hidden="true"
                                                ></i>{' '}
                                                &nbsp;{item.vendor_info?.city},
                                                {item.vendor_info?.address}
                                              </div>
                                            )}
                                          </div>

                                        </div>
                                      </div>
                                      <div className='col-4 text-end'>
                                        <div>
                                          <div className="display-service-start">
                                            <Rate
                                              allowHalf
                                              disabled
                                              value={
                                                item.vendor_info.vendor_average_rating
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                    <div>
                                      <div className='row display-service-service-info-outer-div-col'>
                                        {item.service_info && (
                                          <>
                                            {item.service_info
                                              .slice(0, 4)
                                              .map((service, index) => (
                                                <>
                                                  {service.is_enabled && (
                                                    <div
                                                      key={index}
                                                      className="col-lg-6 mt-2"
                                                    >
                                                      <div className="display-service-service-info-outer-div">
                                                        <div className='row'>
                                                          <div className='col-sm-4'>
                                                            <div className='display-service-service-image-div'>
                                                              <CustomImage
                                                                width={'unset'}
                                                                height={'unset'}
                                                                alt={'shop image'}
                                                                src={(service?.service_image) ? (service?.service_image) : (DefaultServiceImg)}
                                                                className={
                                                                  'display-service-service-section-img cursor-point-class'
                                                                }
                                                                onClick={() =>
                                                                  viewAllFunction(
                                                                    item.vendor_info?.vendor_id,
                                                                    getBreadCrumbArgument(item),
                                                                    previousState,
                                                                    item?.vendor_info?.venue_name
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className='col-sm-8'>
                                                            <div className='display-service-service-section-info-outer-div'>
                                                              <div className='row'>
                                                                <div className='display-service-service-name-div'>
                                                                  <div className='display-service-section-service-name-div cursor-point-class'
                                                                    onClick={() =>
                                                                      viewAllFunction(
                                                                        item.vendor_info?.vendor_id,
                                                                        getBreadCrumbArgument(item),
                                                                        previousState,
                                                                        item?.vendor_info?.venue_name
                                                                      )
                                                                    }
                                                                  >
                                                                    {getServiceName(
                                                                      service,
                                                                    )}
                                                                  </div>
                                                                  <div className="display-service-heart-icon-div2">
                                                                    <i
                                                                      className={`${getHearIconClassName(service)}`}
                                                                      onClick={() =>
                                                                        handleLikeClick(
                                                                          service
                                                                            .favourite
                                                                            .is_service_favourite,
                                                                          service
                                                                            .favourite
                                                                            .favourite_id,
                                                                          service.service_id,
                                                                          'service',
                                                                          role,
                                                                        )
                                                                      }
                                                                    ></i>
                                                                    <div
                                                                      className={`modal fade ${showModal ? 'd-block show' : 'd-none'}`}
                                                                      id="exampleModal"
                                                                      tabIndex="-1"
                                                                      aria-labelledby="exampleModalLabel"
                                                                      aria-hidden="true"
                                                                    >
                                                                      <SignIn
                                                                        setShowModal={
                                                                          setShowModal
                                                                        }
                                                                        showModal={
                                                                          showModal
                                                                        }
                                                                        type={type}
                                                                        commonId={
                                                                          commonId
                                                                        }
                                                                        userType={
                                                                          userType
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className='display-service-service-section-service-time'>
                                                                  <div className="min-content">
                                                                    <i className="bi bi-clock-fill clock-icon"></i>
                                                                    &nbsp;
                                                                    {service.duration}{' '}
                                                                    {t('paymentSection.min')}
                                                                  </div>
                                                                </div>
                                                                {service?.discount_on_service &&
                                                                  <div className='display-service-service-section-discount-price-outer'>
                                                                    <div className='display-service-special-price'>
                                                                      <div className="yRaY8j A6+E6v">฿
                                                                        {service.price ? <>
                                                                          ฿ {formatPrice(service.price)}
                                                                        </> : <></>}
                                                                      </div>
                                                                    </div>
                                                                    <div className='display-service-discount-percent'>
                                                                      <DiscountPercent value={service?.discount_on_service} />
                                                                    </div>
                                                                    <div>
                                                                    </div>
                                                                  </div>
                                                                }
                                                                <div className='display-service-service-section-price-and-butoon'>
                                                                  <div className='display-service-service-price-div'>
                                                                    <div className="Nx9bqj CxhGGd"> ฿ {formatPrice(getSpecialPrice(service))}</div>
                                                                  </div>
                                                                  <div className='display-service-service-btn-action-div'>
                                                                    <div className={service.special_price ? "cart-booking-btn" : "cart-booking-btn-specialprice"}>
                                                                      <div
                                                                        onClick={() =>
                                                                          handleAddToCart(
                                                                            service.service_id,
                                                                          )
                                                                        }
                                                                        data_bs_toggle="modal"
                                                                        data_bs_target="#exampleModal"
                                                                        className={` me-2 ${getCartDisable(service.service_id, true)}`}
                                                                      >
                                                                        <span>
                                                                          <i
                                                                            className={`bi bi-cart-plus-fill shop-card-share mt-1 ${getCartDisable(service.service_id)}`}
                                                                          ></i>
                                                                        </span>
                                                                      </div>
                                                                      <div>
                                                                        <BookNowModalLogic
                                                                          index={index}
                                                                          className="display-service-service-section-bookn-button"
                                                                          service_id={
                                                                            service.service_id
                                                                          }
                                                                          vender_details={
                                                                            item.vendor_info
                                                                          }
                                                                          service_details={
                                                                            item.service_info
                                                                          }
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className='row mt-1'>
                                                                  {item.service_info > 4 && <>
                                                                    <div>
                                                                      <button
                                                                        className="cta"
                                                                        onClick={() =>
                                                                          viewAllFunction(
                                                                            item.vendor_info?.vendor_id,
                                                                            getBreadCrumbArgument(item),
                                                                            previousState,
                                                                            item?.vendor_info?.venue_name
                                                                          )
                                                                        }
                                                                      >
                                                                        <span className="hover-underline-animation">
                                                                          {' '}
                                                                          {t(
                                                                            'globalItems.viewAllService',
                                                                          )}
                                                                        </span>
                                                                        <svg
                                                                          id="arrow-horizontal"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="30"
                                                                          height="10"
                                                                          viewBox="0 0 46 16"
                                                                        >
                                                                          <path
                                                                            id="Path_10"
                                                                            data-name="Path 10"
                                                                            d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                                                            transform="translate(30)"
                                                                          ></path>
                                                                        </svg>
                                                                      </button>
                                                                    </div>

                                                                  </>}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              ))}
                                          </>
                                        )}
                                      </div>
                                      <div className='row mt-2'>
                                        {item?.service_info?.length > 4 &&
                                          <div>
                                            <button
                                              className="cta"
                                              onClick={() =>
                                                viewAllFunction(
                                                  item.vendor_info?.vendor_id,
                                                  getBreadCrumbArgument(item),
                                                  previousState,
                                                  item?.vendor_info?.venue_name
                                                )
                                              }
                                            >
                                              <span className="hover-underline-animation">
                                                {t(
                                                  'globalItems.viewAllService',
                                                )}
                                              </span>
                                              <svg
                                                id="arrow-horizontal"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="30"
                                                height="10"
                                                viewBox="0 0 46 16"
                                              >
                                                <path
                                                  id="Path_10"
                                                  data-name="Path 10"
                                                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                                  transform="translate(30)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>

                                        }

                                      </div>
                                    </div>
                                  </div>
                                </>)}
                              </React.Fragment>
                            );
                          })}
                        {loader && <>
                          <CustomSpinner displayService={true} />
                        </>}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>)}
        </div>
      </div>

      <div

        className={`${!filteredServices || isLoading ? 'alterNative-footer-div-20' : 'alterNative-footer-div-1'}`}
      >
        <div id="footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Alternative;
