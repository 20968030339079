import './Service.css';
import { React, useState, Pagination, PropTypes, Button, Rate,  Modal, Locale } from '../../../libraries';
import { CustomSpinner,EmptyComponent, ShareButtons, Heading } from '../../../common';
import { ServiceModal } from '../../index.js'
import { CustomShare, ReviewAndRatingsHeaderLogic } from '../../../components';
import { VendorHeader } from '../../../vendor/pages';
import { serviceDescription } from '../../../Routes/routes.js';
import { CurrencyDisplay, DiscountPercent } from '../../../jsonData';
import { DefaultServiceImg } from '../../../assets/Images.js';
import { CustomImage } from '../../../lazyComponent/index.js';
import { encryptData } from '../../../customHooks/urlEncyption.js';
import { organizationUrlFormat, urlFormat } from '../../../customHooks/urlFormat.js';
const sharUrl = process.env.REACT_APP_SHARE_URL;

const ReviewRatingService = ({
  setUpdateReviewRating,
  setServiceIdRating,
  serviceIdRating,
  data,
  setModal1Open,
  modal1Open,
  handleLikeClickService,
  viewAllFunction,
  isLoader,
  isErroPage,
  filterValue,
  total_pages,
  handlePaginationChange,
}) => {
  const [shareUrl, setShareUrl] = useState();
  const [title, setTitle] = useState();
  const { t } = Locale();
  const [rating, setRating] = useState(null);
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const showRatingsService = (ratings) => {
    if (ratings && ratings.length > 0) {
      setRating(ratings[0].service_rating);
    }
  };

  const handleShareUrlChange = (item, id) => {
    const encryptedId = encryptData(id);
    const formatOrganizationName = organizationUrlFormat(item);
    setShareUrl(`${sharUrl}${serviceDescription}/${encryptedId}/${formatOrganizationName}`);
    setTitle(item);
  };

  const shareItems = [
    {
      key: '1',
      label: <ShareButtons url={shareUrl} title={title} />,
    },
  ];
  const deletionRequest = localStorage.getItem('status') === 'requested';

  const handleLikeClickServiceArg = (item) => {
    return item?.favourite?.is_service_favourite
      ? item?.favourite?.favourite_id
      : item?.service_id;
  };

  const getServiceName = (item) => {
    return (selectedLanguage === 'en') ? (item?.service_name) : (item?.service_name_thai);
  }

  return (
    <>
      <div
        className={`heading-outer-div user-outer-div ${deletionRequest ? 'deletionRequest-user-header' : ''}`}
      >
        <VendorHeader />
        <Heading text={t('vendorReview.reviews')} icon="bi bi-hand-thumbs-up" />
        <div className="after-heading-outer-div">
          <div className="user-common-box">
            <div className="user-common-box-inner">
              <div className="user-common-box-fix-content row-box">
                <ReviewAndRatingsHeaderLogic />
              </div>
              <div
                className={
                  !isLoader
                    ? `add-to-fav-container add-to-inner-fav-container mt-2 user-inner-content-scrollar user-inner-content-scrollar-after-fix-content`
                    : 'd-none'
                }
              >
                <div className="container-fluid add-to-fav-container3">
                  <div className="mb-4 h-auto">
                    <>
                      {data && data.length > 0 && (
                        <div className="row">
                          {data.map((item, index) => (
                            <div
                              className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3"
                              key={index}
                            >
                              <div className="shop-cart-dd-service">
                                <div className="cart-image">
                                  <div className="service-image-div">
                                    <CustomImage
                                      src={item.service_image || DefaultServiceImg}
                                      alt="f"
                                      width={'unset'}
                                      height={'unset'}
                                      onClick={() =>
                                        viewAllFunction(
                                          item.organization_id,
                                          item.venue_name
                                            ? item.venue_name
                                            : item.service_name,
                                        )
                                      }
                                      className="cursor-point-class"
                                    />
                                  </div>
                                  <div className="cart-name-start-time-child">
                                    <p
                                      className="favourite-service-organisation-name"
                                      onClick={() =>
                                        viewAllFunction(
                                          item.organization_id,
                                          item.venue_name
                                            ? item.venue_name
                                            : item.service_name,
                                        )
                                      }
                                    >
                                      <span className="display-service-heading cursor-point-class"
                                        onClick={() =>
                                          viewAllFunction(
                                            item.organization_id,
                                            item.venue_name
                                              ? item.venue_name
                                              : item.service_name,
                                          )
                                        }
                                      >
                                        {getServiceName(item)}
                                      </span>
                                    </p>
                                    <div className="favourite-service-rating-and-time-div">
                                      <div className="favourite-service-rating">
                                        <Rate
                                          allowHalf
                                          disabled
                                          value={item.service_average_rating}
                                        ></Rate>
                                      </div>
                                    </div>
                                    <div className="price-child">
                                      <div className='display-service-price-outer'>
                                        <span className="service-card-price2-new">
                                          <CurrencyDisplay
                                            value={item.price}
                                            subPrice={
                                              item.special_price || item.price
                                            }
                                          />
                                        </span>
                                        <span>
                                          <DiscountPercent value={item?.discount_on_service} />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="service-info">
                                  <div className="name-star-time-share">
                                    <div className="share-fav-icon">
                                      <span className="shop-card-heart">
                                        <i
                                          className={`${item?.favourite?.is_service_favourite ? 'bi bi-suit-heart-fill' : 'bi bi-suit-heart'}`}
                                          style={{
                                            cursor: 'pointer',
                                            color: item?.favourite
                                              ?.is_service_favourite
                                              ? 'red'
                                              : '',
                                          }}
                                          onClick={() =>
                                            handleLikeClickService(
                                              handleLikeClickServiceArg(item),
                                              'service',
                                              item?.favourite
                                                ?.is_service_favourite,
                                            )
                                          }
                                        ></i>
                                      </span>
                                      <span>
                                        <CustomShare
                                          className="shop-card-share"
                                          placement={'bottom'}
                                          shareChange={() =>
                                            handleShareUrlChange(
                                              item.venue_name,
                                              item?.organization_id,
                                            )
                                          }
                                          items={shareItems}
                                          blanck={'_blank'}
                                        />
                                      </span>
                                    </div>
                                    <div className="view-service-button">
                                      {item.service_rating == null ? (
                                        <Button
                                          onClick={() => {
                                            setServiceIdRating(item.service_id);
                                            setRating(null);
                                            setModal1Open(true);
                                          }}
                                        >
                                          {t('globalItems.rating')}
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            showRatingsService(
                                              item.service_rating,
                                            );
                                            setModal1Open(true);
                                          }}
                                        >
                                          {t('button.view')}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {total_pages > 15 && (
                        <div className="text-center mt-3">
                          <Pagination
                            current={filterValue?.page_number}
                            pageSize={filterValue?.per_page}
                            total={total_pages}
                            showSizeChanger={false}
                            defaultCurrent={3}
                            onChange={handlePaginationChange}
                          />
                        </div>
                      )}
                      {isErroPage && (
                        <>
                          <EmptyComponent type="isService" />
                        </>
                      )}
                      <div
                        className={modal1Open ? `fade modal-backdrop show` : ''}
                      ></div>
                      <Modal
                        className="branch-modal"
                        open={modal1Open}
                        onCancel={() => setModal1Open(false)}
                        okButtonProps={{ style: { display: 'none' } }}
                        cancelButtonProps={{ style: { display: 'none' } }}
                      >
                        <ServiceModal
                          setUpdateReviewRating={setUpdateReviewRating}
                          rating={rating}
                          serviceIdRating={serviceIdRating}
                        />
                      </Modal>
                    </>
                  </div>
                </div>
              </div>
              {isLoader && (
                <>
                  <CustomSpinner />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ReviewRatingService.propTypes = {
  setServiceIdRating: PropTypes.func,
  serviceIdRating: PropTypes.any,
  data: PropTypes.array,
  setModal1Open: PropTypes.func,
  modal1Open: PropTypes.bool,
  handleLikeClickService: PropTypes.func,
  favouriteServices: PropTypes.array,
};

export default ReviewRatingService;