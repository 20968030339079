import { apiCall } from './base';

const showShopServiceRatings = async (params) => {
  let response = await apiCall(
    `/public/ratings/service?organization_id=${params}`,
    'GET',
  );
  return response;
};

const showVendorRatings = async (params) => {
  let response = await apiCall(
    `/public/ratings?organization_id=${params}`,
    'GET',
  );
  return response;
};

export {
  showShopServiceRatings,
  showVendorRatings,

};
