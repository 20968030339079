import { useEffect, useState, Locale } from '../../libraries/index';
import { TeamMemberWorkingHours } from '../pages';

const TeamMemberWorkingHoursLogic = ({
  working_hours,
  teamMemberServices,
}) => {
  const [day, setDay] = useState();
  const [thaiDay, setThaiDay] = useState();
  const [value, setValue] = useState();
  const { t } = Locale();
  const language = localStorage.getItem('i18nextLng');
  const initialOpeningValue = {
    bussiness_hours: {
      monday: {
        open: '',
        close: '',
        shop_open: false,
      },
      tuesday: {
        open: '',
        close: '',
        shop_open: false,
      },
      wednesday: {
        open: '',
        close: '',
        shop_open: false,
      },
      thursday: {
        open: '',
        close: '',
        shop_open: false,
      },
      friday: {
        open: '',
        close: '',
        shop_open: false,
      },
      saturday: {
        open: '',
        close: '',
        shop_open: false,
      },
      sunday: {
        open: '',
        close: '',
        shop_open: false,
      },
    },
  };
  const [openingHourseFormValue, setOpeningHourseFormValue] =
    useState(initialOpeningValue);

  useEffect(() => {
    let changeDay = [
      t('vendor.Monday'),
      t('vendor.Tuesday'),
      t('vendor.Wednesday'),
      t('vendor.Thursday'),
      t('vendor.Friday'),
      t('vendor.Saturday'),
      t('vendor.Sunday'),
    ];
    let newDay = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    let newValue = [
      '01:00',
      '01:30',
      '02:00',
      '02:30',
      '03:00',
      '03:30',
      '04:00',
      '04:30',
      '05:00',
      '05:30',
      '06:00',
      '06:30',
      '07:00',
      '07:30',
      '08:00',
      '08:30',
      '09:00',
      '09:30',
      '10:00',
      '10:30',
      '11:00',
      '11:30',
      '12:00',
      '12:30',
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
      '18:00',
      '18:30',
      '19:00',
      '19:30',
      '20:00',
      '20:30',
      '21:00',
      '21:30',
      '22:00',
      '22:30',
      '23:00',
      '23:30',
      '24:00',
    ];

    setValue(newValue);
    setDay(newDay);
    setThaiDay(changeDay);
  }, [language, t]);

  return (
    <>
      <TeamMemberWorkingHours
        setOpeningHourseFormValue={setOpeningHourseFormValue}
        openingHourseFormValue={openingHourseFormValue}
        working_hours={working_hours}
        day={day}
        thaiDay={thaiDay}
        value={value}
        teamMemberServices={teamMemberServices}
      />
    </>
  );
};
export default TeamMemberWorkingHoursLogic;