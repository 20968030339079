import './bootTable.css';
import { React, OverlayTrigger, Option, Modal, Select, Pagination, Tooltip, useEffect, useState, Locale } from '../../libraries';
import Filter from '../../assets/images/Vector.svg';
import { SearchField } from '../../pages';
import { EditBranch } from '../../vendor/pages';
import { CustomImage } from '../../lazyComponent';

export const BootTable = ({
  subDistrictOption,
  onChange,
  onSearch,
  filterOption,
  postalCode,
  totalPage,
  handlePaginationChange,
  handleChangeFilter,
  filterFormValue,
  setFilterFormValue,
  formErrors,
  setFormValues,
  formValues,
  setSelectedImages,
  setSelectedImage,
  selectedImage,
  selectedImages,
  handleSubmit,
  handleImageUpload,
  handleDeselectImage,
  handleChange,
  handleStatusChange,
  setFormErrors,
  bootData,
}) => {
  const { t } = Locale();
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [updatedData, seUpdatedData] = useState();
  const [editModalOpenData, setEditModalOpenData] = useState(false);
  const [editBookingModalOpen, setEditBookingModalOpen] = useState(false);
  const handleEditBranch = (branch) => {
    setFormErrors({});
    setEditBookingModalOpen(true);
    seUpdatedData(branch);
    if (editModalOpenData) {
      setEditModalOpenData(false);
    } else {
      setEditModalOpenData(true);
    }
  };
  const closeEditBookingModal = () => {
    setEditBookingModalOpen(!editBookingModalOpen);
  };

  const filterRowFunction = () => {
    setShowFilterRow(!showFilterRow);
  };

  const clearFilterFunction = () => {
    setFilterFormValue((prev) => ({
      ...prev,
      branch_name: '',
      is_enabled: 'all',
      address: '',
      district: '',
      postal_code: '',
    }));
  };
  useEffect(() => {
    const statusObject = {};

    bootData?.forEach((obj) => {
      statusObject[obj.branch.id] = obj.branch.is_enabled;
    });
  }, [bootData]);

  return (
    <>
      <Modal
        className="branch-modal"
        open={editBookingModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <EditBranch
          subDistrictOption={subDistrictOption}
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          postalCode={postalCode}
          editModalOpenData={editModalOpenData}
          handleSubmit={handleSubmit}
          formErrors={formErrors}
          handleChange={handleChange}
          formValues={formValues}
          handleDeselectImage={handleDeselectImage}
          selectedImages={selectedImages}
          setFormValues={setFormValues}
          setSelectedImages={setSelectedImages}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
          handleImageUpload={handleImageUpload}
          updatedData={updatedData}
          setEditBookingModalOpen={setEditBookingModalOpen}
          closeEditBookingModal={closeEditBookingModal}
        />
      </Modal>
      <div
        className={editBookingModalOpen ? `fade modal-backdrop show` : ''}
      ></div>

      <div className="user-common-box-fix-content">
        <div className="calendar-row">
          <div className="calendar-col"> </div>
          <div className="calendar-col d-flex">
            <div className="boot-table-filter">
              <CustomImage
                className="boot-table-filter-image"
                alt="filter"
                onClick={filterRowFunction}
                src={Filter}
                width={'unset'}
                height={'unset'}
              />
            </div>
            <SearchField
              placeholder={t('header.placeholder')}
              onChange={(e) => {
                handleChangeFilter({
                  target: { name: 'search', value: e.target.value },
                });
              }}
              onSubmit={(value) => handleChangeFilter(value)}
            />
          </div>
        </div>
      </div>

      <div className="p-2 user-inner-content-scrollar user-inner-content-scrollar-table mt-3">
        <table className="table table-heading table-bordered">
          <thead>
            <tr className="table-light branches-table-top-heading">
              <th className="branches-table-thclass thclass-width">
                {t('vendor.branchName')}
              </th>
              <th className="branches-table-thclass thclass-width">
                {t('vendor.branchAdd')}
              </th>
              <th className="branches-table-thclass thclass-width">
                {t('vendor.subDis')}
              </th>
              <th className="branches-table-thclass thclass-width14">
                {t('financeField.postalCode')}
              </th>
              <th className="branches-table-thclass thclass-width14">
                {t('ServiceField.status')}
              </th>
              <th className="branches-table-thclass thclass-width9">
                {t('ServiceField.action')}
              </th>
            </tr>
            {showFilterRow && (
              <tr>
                <th className="thclass-booking-user thclass-width">
                  <div className="branch-filter-input-feild-div">
                    <input
                      onChange={handleChangeFilter}
                      name="branch_name"
                      className="user-filter-input"
                      placeholder={t('header.search')}
                      value={filterFormValue.branch_name}
                    />
                  </div>
                </th>
                <th className="thclass-booking-user thclass-width">
                  <input
                    name="address"
                    value={filterFormValue.address}
                    className="user-filter-input"
                    placeholder={t('header.search')}
                    onChange={handleChangeFilter}
                  />
                </th>
                <th className="thclass-booking-user thclass-width">
                  <input
                    name="district"
                    value={filterFormValue.district}
                    onChange={handleChangeFilter}
                    className="user-filter-input"
                    placeholder={t('header.search')}
                  />
                </th>
                <th className="thclass-booking-user thclass-width14">
                  <input
                    name="postal_code"
                    value={filterFormValue.postal_code}
                    onChange={handleChangeFilter}
                    className="user-filter-input"
                    placeholder={t('header.search')}
                  />
                </th>
                <th className="thclass-booking-user thclass-width14">
                  <Select
                    id="status"
                    placeholder={t('header.select')}
                    value={filterFormValue?.is_enabled || undefined}
                    onChange={(value) =>
                      handleChangeFilter({
                        target: { name: 'is_enabled', value: value },
                      })
                    }
                    className="filter-select-branch"
                    optionFilterProp="children"
                  >
                    <Option value="all">{t('ServiceField.all')}</Option>
                    <Option value={true}>{t('ServiceField.active')}</Option>
                    <Option value={false}>{t('ServiceField.deActive')}</Option>
                  </Select>
                </th>
                <th className="thclass text-center thclass-width9">
                  <button
                    onClick={clearFilterFunction}
                    className="btn clear-filter"
                  >
                    {t('button.clearButton')} {t('ServiceField.filter')}
                  </button>
                </th>
              </tr>
            )}
          </thead>
          <tbody>
            {bootData && bootData?.length > 0 ? (
              <>
                {bootData.map((obj, key) => (
                  <tr key={key} className="mt-1 branch-table-tbody-tr-tag">
                    <td className="branch-td-class">
                      {obj.branch.branch_name}
                    </td>
                    <td className="branch-td-class">{obj.branch.address}</td>
                    <td className="branch-td-class">{obj.branch.district}</td>
                    <td className="branch-td-class">
                      {obj.branch.postal_code}
                    </td>
                    <td className="branch-td-class">
                      <div className="status-button-center">
                        <div
                          onClick={() => handleStatusChange(obj)}
                          className={`cursor-point-class ${obj.branch.is_enabled === true
                            ? 'status-active'
                            : 'status-deactive'
                            }`}
                        >
                          <div
                            className={`${obj.branch.is_enabled === true
                              ? 'activate'
                              : 'deactivate'
                              }`}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td className="branch-td-class">
                      <div className=" branch-table-action-outer-div">
                        <div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>{t('button.editButton')}</Tooltip>
                            }
                          >
                            <i
                              onClick={() => handleEditBranch(obj)}
                              className=" bi bi-pencil-fill action_edit"
                              aria-hidden="true"
                            ></i>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <>
                <tr className="text-center">
                  <td colSpan="6" className="notAvailable">
                    {t('ServiceField.noBranchAvailable')}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        {totalPage > 15 && (
          <div className="mt-3">
            <Pagination
              current={filterFormValue.page_number}
              pageSize={filterFormValue.per_page}
              total={totalPage}
              showSizeChanger={false}
              defaultCurrent={3}
              onChange={handlePaginationChange}
            />
          </div>
        )}
      </div>
    </>
  );
};
