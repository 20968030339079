import { apiCall } from './base.js';

const getAllCategory = async () => {
  const response = await apiCall('customer/categories', 'GET');
  return response;
};
const getAllSubCategory = async (params) => {
  const response = await apiCall(`customer/sub_categories/${params}`, 'GET');
  return response;
};
export { getAllCategory, getAllSubCategory };
