import  {React, useState } from '../../libraries';
import './customSpinner.css';

const Spinner = () => (
  <div className="Spinner SpinnerDots">
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
  </div>
);

const SpinnerDotsScale = () => (
  <div className="Spinner SpinnerDotsScale">
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
  </div>
);

const SpinnerCircle = () => (
  <div className="Spinner SpinnerCircle">
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
  </div>
);

const CustomSpinner = ({ center, displayService }) => {
  const [spinnerIndex, setSpinnerIndex] = useState(2);

  const next = () => {
    setSpinnerIndex((spinnerIndex) => (spinnerIndex + 1) % spinners.length);
  };
  const spinners = [Spinner, SpinnerCircle, SpinnerDotsScale];
  const SpinnerSelected = spinners[spinnerIndex];

  return (
    <div className={`${!displayService && 'CustomSpinner'}  ${center}`}>
      <div onClick={next}>
        <SpinnerSelected />
      </div>
    </div>
  );
};

export default CustomSpinner;
