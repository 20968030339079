import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { getHomeDetail } from '../../services/homeApi';

// Create the context
export const HomeDataContext = createContext();

// Custom hook to use the context
export const useHomeData = () => useContext(HomeDataContext);

const HomeDataProvider = ({ children }) => {
    const [showData, setShowData] = useState([]);
    const [isLoader, setIsLoader] = useState(true);

    const fetchData = useCallback(async () => {
        try {
            const { data } = await getHomeDetail();
            localStorage.removeItem("isServer");
            setShowData(data.data);
        } catch {
            localStorage.setItem("isServer", 'off')
            setShowData('error');
        } finally {
            setIsLoader(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const value = useMemo(() => ({ showData, isLoader }), [showData, isLoader]);

    return (
        <HomeDataContext.Provider value={value}>
            {children}
        </HomeDataContext.Provider>
    );
};

export default HomeDataProvider;
