import './Finance.css';
import { React, PropTypes, Locale, Select } from '../../../libraries';
import { CustomSpinner, Label, Small, Heading } from '../../../common';
import { PreviewFileModal, VendorHeader } from '../index';
const Finance = ({
  isLoader,
  postalCode,
  country,
  onChange,
  onSearch,
  filterOption,
  countryCode,
  isUpdate,
  uploadedCheque,
  formValue,
  handleSubmit,
  handleChange,
  formError,
  buttonDisabled,
  isActiveBtn
}) => {
  const { t } = Locale();

  const getEyeClassName = () => {
    if ((!(formValue?.cheque)) && (!(uploadedCheque))) {
      return 'is-disable-eye-icon-service-section'
    }
    else {
      return ''
    }

  }
  return (
    <>
      <PreviewFileModal formValue={formValue} uploadedCheque={uploadedCheque} />
      <div className="heading-outer-div">
        <VendorHeader />
        <Heading text={t('vendor.finance')} icon={'bi bi-bank'} />
        <div className="after-heading-outer-div">
          <div
            className={`finance-outer-div-cart user-common-box ${!isLoader ? `user-inner-content-scrollar` : 'd-none'}`}
          >
            <div className="row">
              <div className="col-lg-6 company-details">
                <div className="finance-inner-cart-div">
                  <div className="box-heading">
                    <div className="box-heading-content">
                      {t('financeField.companyDetails')}
                    </div>
                  </div>
                  <div className="box-content">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <Label
                          className="form-label"
                          text={
                            <>
                              {t('financeField.companyTitle')}
                              <small className="small-err">*</small>
                            </>
                          }
                        />
                        <input
                          name="title"
                          value={formValue.title}
                          onChange={handleChange}
                          className="form-control vendor-account-details-input-field"
                          type="text"
                        />
                        <Small name={'tittle'} err={formError.title} />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 finance-col-lg-4-div">
                        <Label
                          className="form-label field-space-finance"
                          text={
                            <>
                              {t('financeField.taxId')}
                              <small className="small-err">*</small>
                            </>
                          }
                        />
                        <input
                          name="taxId"
                          value={formValue.taxId}
                          onChange={handleChange}
                          className="form-control vendor-account-details-input-field"
                          type="text"
                        />
                        <Small name={'taxId'} err={formError.taxId} />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-5 col-md-5 col-sm-5 ">
                        <label className="form-label">
                          {t('financeField.billingAddress')}
                        </label>
                        <br />
                        <input
                          name="billingAddress"
                          value={formValue.billingAddress}
                          onChange={handleChange}
                          className="form-control vendor-account-details-input-field"
                          type="text"
                        />
                        <Small
                          name={'billingAddress'}
                          err={formError.billingAddress}
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 finance-col-lg-4-div">
                        <Label
                          className="form-label field-space-finance"
                          text={
                            <>
                              {t('financeField.postalCode')}
                              <small className="small-err">*</small>
                            </>
                          }
                        />
                        <Select
                          className="finace-ant-select-form"
                          showSearch
                          placeholder={`${t('header.select')} ${t(
                            'field.postalCode',
                          )}`}
                          optionFilterProp="children"
                          onChange={(value) => onChange('postal_code', value)}
                          onSearch={onSearch}
                          filterOption={filterOption}
                          options={postalCode}
                          value={formValue.postal_code || undefined}
                        />
                        <Small
                          name={'postal_code'}
                          err={formError.postal_code}
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 finance-col-lg-4-div finance-col-field">
                        <Label
                          className="form-label field-space-finance"
                          text={
                            <>
                              {t('financeField.country')}
                              <small className="small-err">*</small>
                            </>
                          }
                        />
                        <Select
                          className="finace-ant-select-form"
                          showSearch
                          placeholder={`${t('header.select')} ${t(
                            'financeField.country',
                          )}`}
                          optionFilterProp="children"
                          onChange={(value) => onChange('country', value)}
                          onSearch={onSearch}
                          filterOption={filterOption}
                          options={country}
                          value={formValue.country || undefined}
                        />
                        <Small name={'country'} err={formError.country} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 contact-person-details">
                <div className="finance-inner-cart-div">
                  <div className="box-heading">
                    <div className="box-heading-content">
                      {t('financeField.contactDetail')}
                    </div>
                  </div>
                  <div className="box-content">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <Label
                          className="form-label"
                          text={
                            <>
                              {t('financeField.contactName')}
                              <small className="small-err">*</small>
                            </>
                          }
                        />
                        <input
                          name="contactName"
                          value={formValue.contactName}
                          onChange={handleChange}
                          className="form-control vendor-account-details-input-field"
                          type="text"
                        />
                        <Small
                          name={'contactName'}
                          err={formError.contactName}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 finance-col-lg-4-div finance-col-field">
                        <Label
                          className="form-label field-space-finance"
                          text={
                            <>
                              {t('field.email')}
                              <small className="small-err">*</small>
                            </>
                          }
                        />
                        <input
                          name="email"
                          value={formValue.email}
                          onChange={handleChange}
                          className="form-control vendor-account-details-input-field"
                          type="text"
                        />
                        <Small name={'email'} err={formError.email} />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-6">
                        <div className="accountDetails_phonenumber_Div ">
                          <div className="row ">
                            <Label
                              className="form-label"
                              text={
                                <>
                                  {t('field.phoneNumber')}
                                  <small className="small-err">*</small>
                                </>
                              }
                            />
                            <div className="col-6">
                              <Select
                                className="finace-ant-select-form"
                                showSearch
                                placeholder={`${t('header.select')} ${t(
                                  'field.countryCode',
                                )}`}
                                optionFilterProp="children"
                                onChange={(value) =>
                                  onChange('countrycode', value)
                                }
                                onSearch={onSearch}
                                filterOption={filterOption}
                                options={countryCode}
                                value={formValue.countrycode || undefined}
                              />
                            </div>
                            <div className="col-6">
                              <input
                                name="phone"
                                value={formValue.phone}
                                onChange={handleChange}
                                className={
                                  'form-control vendor-account-details-input-field '
                                }
                                type="text"
                              />
                            </div>
                              <Small name={'phone'} err={formError.phone} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 finance-col-field-billing-contact">
                        <div className="accountDetails_phonenumber_Div ">
                          <div className="row ">
                            <Label
                              className="form-label"
                              text={
                                <>
                                  {t('financeField.billContactPhoneNumber')}
                                  <small className="small-err">*</small>
                                </>
                              }
                            />
                            <div className="col-6">
                              <Select
                                className="finace-ant-select-form"
                                showSearch
                                placeholder={`${t('header.select')} ${t(
                                  'field.countryCode',
                                )}`}
                                optionFilterProp="children"
                                onChange={(value) =>
                                  onChange('ccountrycode', value)
                                }
                                onSearch={onSearch}
                                filterOption={filterOption}
                                options={countryCode}
                                value={formValue.ccountrycode || undefined}
                              />
                            </div>
                            <div className="col-6">
                              <input
                                name="billingContactPhone"
                                value={formValue.billingContactPhone}
                                onChange={handleChange}
                                className={
                                  'form-control vendor-account-details-input-field'
                                }
                                type="text"
                              />
                            </div>
                              <Small
                                name={'billingContactPhone'}
                                err={formError.billingContactPhone}
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="finance-inner-cart-div mt-3">
              <div className="box-heading">
                <div className="box-heading-content">
                  {t('financeField.bankDetail')}
                </div>
              </div>
              <div className="box-content">
                <div className="row ">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <Label
                      className="form-label"
                      text={
                        <>
                          {t('financeField.accountHolderName')}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <input
                      name="accountHolderName"
                      value={formValue.accountHolderName}
                      onChange={handleChange}
                      className="form-control vendor-account-details-input-field"
                      type="text"
                    />
                    <Small
                      name={'accountHolderName'}
                      err={formError.accountHolderName}
                    />
                  </div>
                  <div className="col-lg-4 finance-col-lg-4-div col-md-4 col-sm-4 finance-col-field">
                    <Label
                      className="form-label field-space-finance"
                      text={
                        <>
                          {t('financeField.accountNumber')}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <input
                      name="accountNumber"
                      value={formValue.accountNumber}
                      onChange={handleChange}
                      className="form-control vendor-account-details-input-field"
                      type="text"
                    />
                    <Small
                      name={'accountNumber'}
                      err={formError.accountNumber}
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <Label
                      className="form-label field-space-finance"
                      text={
                        <>
                          {t('financeField.bankBranchCode')}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <input
                      name="bankBranchCode"
                      value={formValue.bankBranchCode}
                      onChange={handleChange}
                      className="form-control vendor-account-details-input-field"
                      type="text"
                    />
                    <Small
                      name={'bankBranchCode'}
                      err={formError.bankBranchCode}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-4 finance-col-lg-4-div col-md-4 col-sm-4 finance-col-field">
                    <Label
                      className="form-label"
                      text={
                        <>
                          {t('financeField.iban')}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <input
                      name="iban"
                      value={formValue.iban}
                      onChange={handleChange}
                      className="form-control vendor-account-details-input-field"
                      type="text"
                    />
                    <Small name={'iban'} err={formError.iban} />
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <Label
                      className="form-label field-space-finance"
                      text={
                        <>
                          {t('financeField.bankName')}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <input
                      name="bankName"
                      value={formValue.bankName}
                      onChange={handleChange}
                      className="form-control vendor-account-details-input-field"
                      type="text"
                    />
                    <Small name={'bankName'} err={formError.bankName} />
                  </div>
                  <div className="col-lg-4 col-md-4 finance-col-lg-4-div col-sm-4 finance-col-field">
                    <Label
                      className="form-label field-space-finance"
                      text={
                        <>
                          {t('financeField.postalCode')}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <Select
                      className="finace-ant-select-form"
                      showSearch
                      placeholder={`${t('header.select')} ${t(
                        'field.postalCode',
                      )}`}
                      optionFilterProp="children"
                      onChange={(value) => onChange('bank_postal_code', value)}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={postalCode}
                      value={formValue.bank_postal_code || undefined}
                    />
                    <Small
                      name={'bank_postal_code'}
                      err={formError.bank_postal_code}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <Label
                      className="form-label"
                      text={
                        <>
                          {t('financeField.bankAddress')}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <input
                      name="bankAddress"
                      value={formValue.bankAddress}
                      onChange={handleChange}
                      className="form-control vendor-account-details-input-field"
                      type="text"
                    />
                    <Small name={'bankAddress'} err={formError.bankAddress} />
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4  finance-col-lg-4-div finance-col-field">
                    <Label
                      className="form-label field-space-finance"
                      text={
                        <>
                          {t('financeField.country')}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <Select
                      className="finace-ant-select-form"
                      showSearch
                      placeholder={`${t('header.select')} ${t(
                        'financeField.country',
                      )}`}
                      optionFilterProp="children"
                      onChange={(value) => onChange('bankCountry', value)}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={country}
                      value={formValue.bankCountry || undefined}
                    />
                    <Small name={'bankCountry'} err={formError.bankCountry} />
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4  mb-5">
                    <div className="row">
                      <Label
                        className="form-label field-space-finance"
                        text={
                          <>
                            {t('financeField.uploadCheque')}
                            <small className="small-err">*</small>
                          </>
                        }
                      />
                      <div className="col-lg-11 col-md-11 col-sm-12 vendor-finance-form-cheque-outer-div">
                        {!formValue.cheque ? (
                          <>
                            <div className="finance-cheque-field-after-cheque-outer-div">
                              <div>
                                <input
                                  type="file"
                                  className="hidden-input"
                                  id="file"
                                  name="cheque"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={handleChange}
                                />
                                <label
                                  htmlFor="file"
                                  className="custom-file-upload"
                                >
                                  <i className="bi bi-upload"></i>
                                </label>
                              </div>
                              <div className="vendor-finance-file-name">
                                {t('financeField.noFileChoosen')}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="finance-cheque-field-after-cheque-outer-div">
                              <div>
                                <input
                                  type="file"
                                  className="hidden-input"
                                  id="file"
                                  name="cheque"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={handleChange}
                                />
                                <label
                                  htmlFor="file"
                                  className="custom-file-upload"
                                >
                                  <i className="bi bi-upload "></i>
                                </label>
                              </div>
                              <div className="vendor-finance-file-name">
                                {typeof formValue.cheque === 'string' ? (
                                  <>{formValue.chequeName}</>
                                ) : (
                                  <>{formValue.cheque.name}</>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="vendor-finance-img-inst-eye">
                          <Small
                            className="vendor-finance-support-inst"
                            err={t('vendor.supportsFormateFinance')}
                          ></Small>
                          <div className="eye-icon-mobile-view">
                            <span className="finance-previewbutton-Div">
                              {!(formValue?.cheque instanceof File) &&
                                formValue?.cheque?.endsWith('.pdf') ? (
                                <>
                                  <a
                                    className="btn finance-previewbutton"
                                    href={formValue.cheque}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      alt="User"
                                      className="img-fluid finance-cheque-availabe-img-tag"
                                      src={
                                        uploadedCheque ||
                                        formValue.cheque ||
                                        'defaultImageURL'
                                      }
                                    />
                                    <i
                                      className="bi bi-eye"
                                      title="View Your File"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </>
                              ) : (
                                <>
                                  <i
                                    className="bi bi-eye mt-2"
                                    title="View Your File"
                                    aria-hidden="true"
                                    data-bs-toggle="modal"
                                    data-bs-target="#previewFileModal"
                                  ></i>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-12 vendor-finance-web-eye">
                        <span className="finance-previewbutton-Div">
                          {!(formValue?.cheque instanceof File) &&
                            formValue?.cheque?.endsWith('.pdf') ? (
                            <>
                              <a
                                className="btn finance-previewbutton"
                                href={formValue.cheque}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  alt="User"
                                  className="img-fluid finance-cheque-availabe-img-tag"
                                  src={
                                    uploadedCheque ||
                                    formValue.cheque ||
                                    'defaultImageURL'
                                  }
                                />
                                <i
                                  className={`bi bi-eye ${getEyeClassName()}`}
                                  title="View Your File"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </>
                          ) : (
                            <>
                              <i
                                className={`bi bi-eye mt-2 ${getEyeClassName()}`}
                                title="View Your File"
                                aria-hidden="true"
                                data-bs-toggle="modal"
                                data-bs-target="#previewFileModal"
                              ></i>
                            </>
                          )}
                        </span>
                      </div>
                      <Small name="cheque" err={formError.cheque} />
                    </div>
                  </div>
                </div>
                <div>
                  <span className="finance-save-button-div">
                    {isUpdate ? (
                      <>
                        <button
                          className={`btn cursor-point-class activeClass`}
                          name={'Save'}
                          onClick={handleSubmit}
                          disabled={buttonDisabled}
                        >
                          {t('button.update')}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className={`btn cursor-point-class`}
                          name={'Save'}
                          onClick={handleSubmit}
                          disabled={buttonDisabled}
                        >
                          {t('button.saveButton')}
                        </button>
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {isLoader && (
            <>
              <CustomSpinner />
            </>
          )}
        </div>
      </div>
    </>
  );
};
Finance.propTypes = {
  postalCode: PropTypes.array,
  country: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  filterOption: PropTypes.func,
  countryCode: PropTypes.array,
  isUpdate: PropTypes.bool,
  uploadedCheque: PropTypes.string,
  formValue: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  formError: PropTypes.object,
};

export default Finance;