import { Button, DatePicker, Modal, Select, Locale } from '../../libraries';
import { CurrencyDisplay } from '../../jsonData';

const AddToCartModal = ({
  open,
  handleOk,
  products,
  handleCancel,
  deleteServiceId,
  handleDateChange,
  formErr,
  handleDisableDate,
  handleDisableTime,
  handleLocationChange,
  selectedLocation, // Make sure this prop is used correctly
}) => {
  const { t } = Locale();
  // Create an object to store options for each product
  const productOptions = products.reduce((acc, service) => {
    const {
      service_at_bussiness_location,
      service_at_customer_location,
      cart_services_id,
    } = service;

    const options = [];
    if (service_at_bussiness_location) {
      options.push({ value: 'at_shop', label: t('field.atShop') });
    }

    if (service_at_customer_location) {
      options.push({ value: 'at_my_location', label: t('field.atHome') });
    }

    acc[cart_services_id] = options;
    return acc;
  }, {});

  const branchOptions = products.reduce((acc, service) => {
    const { branch_details, cart_services_id } = service;

    const options = branch_details?.map(branch => ({
      value: branch.id,
      label: `${branch.name}`,
    }));

    acc[cart_services_id] = options;
    return acc;
  }, {});

  return (
    <div className="add-to-cart-modal">
      <Modal
        title="Select Date & Time"
        open={open}
        className="add-to-cart-modal"
        footer={[
          <Button key="1" onClick={handleCancel}>
            {t('button.cancelButton')}
          </Button>,
          <Button key="3" className="confirm" onClick={(e) => handleOk(e)}>
            {t('field.confirm')}
          </Button>,
        ]}
      >
        {products && products.length > 0 ? (
          deleteServiceId.some((productId) => {
            const product = products.find(
              (item) => item.cart_services_id === productId
            );
            return (
              product &&
              product.vendor_business_hours &&
              Object.values(product.vendor_business_hours).some(
                (day) => day.shop_open === 'true'
              )
            );
          }) ? (
            deleteServiceId.map((productId, index) => {
              const product = products.find(
                (item) => item.cart_services_id === productId
              );

              if (product) {
                const options = productOptions[product.cart_services_id] || [];
                const selectedValue = selectedLocation[productId]
                  ? selectedLocation[productId]
                  : (product?.service_at_bussiness_location)
                    ? 'At Shop'
                    : 'At Home';

                const showBranchOption = branchOptions[product.cart_services_id] || [];

                return (
                  <div key={index}>
                    <div className="row">
                      <div className="col-5">
                        <span className="service-name">
                          {product.service_name}
                        </span>
                        <div className="modal-text">
                          <i className="bi bi-clock-fill"></i>
                          <span className="add-to-cart-duration-span">
                            {' ' + product.duration + ' '}Min
                          </span>
                        </div>
                        <span className="modal-text">
                          <CurrencyDisplay
                            value={
                              product.special_price === 0
                                ? product.price
                                : product.special_price
                            }
                          />
                        </span>
                      </div>
                      <div className='col-7'>
                        <div className='row'>
                          <div className='col-sm-7'>
                            <div className="mt-1 add-to-cart-date">
                              {Object.values(product.vendor_business_hours).some(
                                (day) => day.shop_open === 'true'
                              ) ? (
                                <DatePicker
                                  className="add-to-cart-date-picker"
                                  format="DD/MM/YYYY HH:mm"
                                  onChange={(date, dateString) => {
                                    handleDateChange(
                                      productId,
                                      date,
                                      dateString,
                                      product.vendor_business_hours,
                                      options,
                                      showBranchOption,
                                      product
                                    );
                                  }}
                                  showTime={{ format: 'HH:mm' }}
                                  showNow={false}
                                  allowClear={false}
                                  disabledDate={(current) =>
                                    handleDisableDate(current, product)
                                  }
                                  disabledTime={(current) =>
                                    handleDisableTime(current, product)
                                  }
                                />
                              ) : (
                                <small className="small-err">
                                  {t('validation.notAvailable')}
                                </small>
                              )}
                              <small className="small-err">
                                {formErr[productId]}
                              </small>
                            </div>
                          </div>
                          <div className='col-sm-5'>
                            <div className="mt-1 add-to-cart-date">
                              <div>
                                <Select
                                  value={selectedValue}
                                  onChange={(value) => handleLocationChange(value, productId)}
                                  options={options}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div>
                        <hr />

                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })
          ) : (
            <small className="small-err">
              {t('validation.notAvailable')}
            </small>
          )
        ) : (
          <>{t('validation.notAvailable')}.</>
        )}
      </Modal>
    </div>
  );
};

export default AddToCartModal;
