import './UserSidebar.css';
import { Link, useLocation, Locale, useEffect, useState, useNavigate, useCookies, PropTypes } from '../../libraries/index';
import { CancelDeletionHeaderLogic } from '../../components/index';
import { bussinessbookings, referral, cashback, addToCart, reviewShop, favouriteShop, editProfile, home, signIn, cartPayment, payment } from '../../Routes/routes.js';
import { handleErrorResponse, handleSuccessResponse, Logout } from '../../services';

const UserSidebar = ({
  isOpenUser,
  children,
  changeState,
  setIsOpen,
  deletionRequest,
  setDeletionRequest,
}) => {
  const { t } = Locale();
  const location = useLocation();
  const navigate = useNavigate();
  const [isActiveSection, setIsActiveSection] = useState();
  const [menuOpenOrClose, setMenuOpenOrClose, removeMenuOpenOrClose] =
    useCookies(['menu']);

  const handleSectionClick = async (section) => {
    if (section !== 'isHome') localStorage.setItem('isSection', section);
    else localStorage.removeItem('isSection');
    setIsActiveSection(section);
    if (section === 'logout') {
      try {
        const response = await Logout();
        handleSuccessResponse(response, response.data.message);
        window.history.pushState(null, null, `${signIn}`);
        navigate(signIn);
        const disableBack = (event) => {
          window.history.pushState(null, null, `${signIn}`);
          event.preventDefault();
        };
        return () => {
          window.removeEventListener('popstate', disableBack);
        };
      } catch (error) {
        handleErrorResponse(error);
      }
    }
  };

  const selectedLangugeThai = localStorage.getItem('i18nextLng') === 'thai';
  const menuToggle = () => {
    document.getElementById('menuBarIcon').classList.toggle('open_close');
    var menuOpenedValue =
      document.getElementById('menuBarIcon').classList.value;
    if (menuOpenedValue.length > 0) {
      setMenuOpenOrClose('menu', 'open');
      menuOpenedOrNot = true;
    } else {
      menuOpenedOrNot = false;
      removeMenuOpenOrClose('menu');
    }
  };

  const menuOpened = () => {
    return (
      menuOpenOrClose.menu !== undefined && menuOpenOrClose.menu.length > 1
    );
  };
  let menuOpenedOrNot = menuOpened();
  const updateMenuOpenOrClose = (value) => {
    if (menuOpenedOrNot) {
      document.getElementsByClassName('mainDiv')[0].style =
        `margin-left: ${!selectedLangugeThai ? '190px' : '230px'};`;
      setIsOpen(true);
    } else {
      document.getElementsByClassName('mainDiv')[0].style = '';
      setIsOpen(value);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('isSection')) {
      setIsActiveSection(localStorage.getItem('isSection'));
    }
    updateMenuOpenOrClose(false);
  }, [location.pathname]);

  const sidebarLinks = [
    {
      title: t('header.message'),
      icon: 'bi-house-door',
      path: home,
      id: 'isHome',
    },
    {
      title: t('vendor.myProfile'),
      icon: 'bi-person',
      path: editProfile,
      id: 'isProfile',
    },
    {
      title: t('userBooking.myBookings'),
      icon: 'bi-calendar-week',
      path: bussinessbookings,
      id: 'isBooking',
    },
    {
      title: t('vendor.suraCashback'),
      icon: 'bi bi-wallet',
      path: cashback,
      id: 'isCashback',
    },
    {
      title: t('vendor.referral'),
      icon: 'bi-megaphone',
      path: referral,
      id: 'isRefral',
    },
    {
      title: `${t('vendor.review')} & ${t('vendor.ratings')}`,
      icon: 'bi-hand-thumbs-up',
      path: reviewShop,
      id: 'isReview',
    },
    {
      title: t('vendor.favorite'),
      icon: 'bi-suit-heart',
      path: favouriteShop,
      id: 'isFavourite',
    },
    {
      title: t('vendor.carts'),
      icon: 'bi-cart3',
      path: addToCart,
      id: 'isCart',
    },
  ];

  const renderedLinks = sidebarLinks.map((link) => (
    <div className="d-flex" key={link.id}>
      <Link
        onClick={() => handleSectionClick(link.id)}
        className={`vendor_link ${isOpenUser ? 'vendor_inactiveLink' : 'vendor_activeLink'} ${isActiveSection === link.id ? 'vendor_sidebar-active-link' : ''}`}
        onMouseEnter={() => !isOpenUser && changeState()}
        to={link.path}
      >
        <div className={`vendor_icon`}>
          <i
            className={`bi ${link.icon} venodr-sidebar-fa-fa-icon ${isActiveSection === link.id ? 'vendor_sidebar-active-link' : 'vendor_sidebar-deactive-link'}`}
          ></i>
          <span
            className={`vendor_link_text ${isActiveSection === link.id ? 'vendor_sidebar-active-link' : ''} ${!isOpenUser && 'd-none'}`}
          >
            {link.title}
          </span>
        </div>
      </Link>
    </div>
  ));

  return (
    <>
      <div
        className={
          location.pathname === cartPayment ||
            location.pathname === payment
            ? 'cart-payment-div'
            : `sidebar_outer_container`
        }
      >
        <div className="user-sidebar-cancel-deletion-header">
          {deletionRequest && (
            <>
              <CancelDeletionHeaderLogic
                deletionRequest={deletionRequest}
                setDeletionRequest={setDeletionRequest}
              />
            </>
          )}
        </div>
        <div className={`${deletionRequest && `after-deletion-sidebar`}`}>
          <div className="vendor_sidebar_outer_container ">
            <div className="sidebar vendor_sidebar_outer_div">
              <div className="vendor_sidebarContaine-outer-div">
                <div
                  className="vendor_sidebarTop_heading"
                  onClick={() => updateMenuOpenOrClose(false)}
                >
                  <div className="vendor_top_section">
                    <div
                      onClick={() => menuToggle()}
                      id="menuBarIcon"
                      className={menuOpenedOrNot ? 'open_close' : ''}
                    >
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                    </div>
                  </div>
                </div>
                <div
                  className="vendor_menuBarOuterDiv"
                  onMouseLeave={() => updateMenuOpenOrClose(false)}
                >
                  {renderedLinks}
                  <div className="d-flex sidebar-logout ">
                    <Link
                      onClick={() => handleSectionClick('logout')}
                      className={`vendor_link  ${isOpenUser ? 'vendor_inactiveLink' : 'vendor_activeLink'}`}
                      onMouseEnter={() => !isOpenUser && changeState()}
                    >
                      <div className={`vendor_icon vendor-sidebar-icon-logout`}>
                        <i
                          className={`bi bi-power  venodr-sidebar-fa-fa-icon vendor_sidebar-deactive-link`}
                        ></i>
                        <span
                          className={`vendor_link_text vendor_sidebar-active-link venodr-sidebar-log-out-section-active-lick d-${isOpenUser ? 'block' : 'none'}`}
                        >
                          {t('header.logout')}
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main
          className="mainDiv"
          onMouseEnter={() => updateMenuOpenOrClose(false)}
        >
          {children}
        </main>
      </div>
    </>
  );
};
UserSidebar.propTypes = {
  isPage: PropTypes.object,
  isOpenUser: PropTypes.bool,
  children: PropTypes.node,
  changeState: PropTypes.func,
  setIsOpen: PropTypes.func,
  deletionRequest: PropTypes.any,
  setDeletionRequest: PropTypes.func,
};
export default UserSidebar;