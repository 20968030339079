import { message } from 'antd';

const checkFileSize = (file, t) => {
  const maxSize = 2 * 1024 * 1024;
  if (file.size > maxSize) {
    const errorMessage = `${t('validation.imageSize')}`;
    message.error(errorMessage);
    return false;
  }
  return true;
};

export { checkFileSize };
