import { React, useEffect, useLocation, useState } from '../../../libraries/index';
import { AccountDetails } from '../../pages/index';
import { AddressJson } from '../../../jsonData';
import {handleErrorResponse,handleSuccessResponse,delete_image,vendorOrgnizationInformation} from '../../../services/index';

const VendorAccountDetails = () => {
  const location = useLocation();
  const [vendorAccoutnDetails, setVendorAccoutnDetails] = useState();
  const initialValues = {
    venue_name: '',
    countrycode: '+66',
    venueThaiName: '',
    venueThaiDescription: '',
    venueEnglishDescription: '',
    address: '',
    postcode: '',
    city: '',
    venuPhoneNumber: '',
    website: '',
    shopImages: [],
    shopImagesShow: [],
    shopTempImages: [],
  };
  const [formValue, setFormValue] = useState(initialValues);
  const [subDistrictOption, setSubDistrictOption] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const isSection = localStorage.getItem('isSection');
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
    ],
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  useEffect(() => {
    if (isSection !== 'isBusinessAccount') {
      localStorage.setItem('isSection', 'isBusinessAccount');
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const response = await vendorOrgnizationInformation();
        setVendorAccoutnDetails(response.data);
      } catch (error) {
        handleErrorResponse(error);
      } finally {
        setIsLoader(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (vendorAccoutnDetails && vendorAccoutnDetails.organization) {
      let phone_number =
        vendorAccoutnDetails &&
        vendorAccoutnDetails.organization.phone &&
        vendorAccoutnDetails.organization.phone.split(' ').length > 1 &&
        vendorAccoutnDetails.organization.phone.split(' ')[1].toString();

      let tempCountryCode =
        vendorAccoutnDetails &&
        vendorAccoutnDetails.organization.phone &&
        vendorAccoutnDetails.organization.phone.split(' ').length > 1 &&
        vendorAccoutnDetails.organization.phone.split(' ')[0].toString();
      let tempImageArray;

      tempImageArray = Object.values(
        vendorAccoutnDetails.organization_image,
      ).map((imageObject) => imageObject.url);

      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        email: vendorAccoutnDetails.organization.email,
        bussiness_hours: vendorAccoutnDetails.organization.bussiness_hours,
        venue_name: vendorAccoutnDetails.organization.name,
        countrycode: tempCountryCode,
        venueThaiName:
          vendorAccoutnDetails.organization?.name_thai === 'null'
            ? ''
            : vendorAccoutnDetails.organization?.name_thai,
        address: vendorAccoutnDetails.organization.location,
        postcode: vendorAccoutnDetails.organization.postcode,
        city: vendorAccoutnDetails.organization.city,
        venuPhoneNumber: phone_number,
        website:
          vendorAccoutnDetails.organization.website === 'null'
            ? ''
            : vendorAccoutnDetails.organization.website,
        shopImages: tempImageArray,
        shopTempImages: vendorAccoutnDetails.organization_image,
        serviceMyLocation:
          vendorAccoutnDetails.organization.service_at_bussiness_location,
        serviceCustomerLocation:
          vendorAccoutnDetails.organization.service_at_customer_location,
        breakTime: vendorAccoutnDetails.organization.break_time,
        cancellationPolicy:
          vendorAccoutnDetails.organization.cancellation_policy,
        cash_payment: vendorAccoutnDetails.organization.cash_payment,
        pay_online: vendorAccoutnDetails.organization.online_payment,
        dayValidAfterPurchase:
          vendorAccoutnDetails.organization.cancel_days_before_scheduled,
        cancelDayBeforeScheduleDate:
          vendorAccoutnDetails.organization.rescheduling_permitted_days,

        venueEnglishDescription:
          vendorAccoutnDetails?.organization?.description,
        venueThaiDescription:
          vendorAccoutnDetails?.organization?.description_thai,
      }));
    }
  }, [vendorAccoutnDetails?.organization]);

  const deleteImage = (deleteImageIndex) => {
    setFormValue((prevState) => {
      const deleteImageUrl = prevState.shopImages[deleteImageIndex]; // Retrieve the URL
      const updatedShopImages = prevState.shopImages.filter(
        (image, index) => index !== deleteImageIndex,
      );
      let deleteImageId;
      for (const key in formValue.shopTempImages) {
        const imageObject = formValue.shopTempImages[key];
        if (imageObject.url === deleteImageUrl) {
          deleteImageId = imageObject.id;
          delete_image(deleteImageId)
            .then((response) => {
              handleSuccessResponse(response, response.data.message);
            })
            .catch((error) => {
              handleErrorResponse(error);
            });
        }
      }
      return {
        ...prevState,
        shopImages: updatedShopImages,
      };
    });
  };

  const onChange = (name, value) => {
    if (name === 'postcode') {
      const selectedPostalCode = value;
      const selectedPostalCodeData = AddressJson.filter(
        (item) => item.postalCode === selectedPostalCode,
      );
      const subDistrictOP = selectedPostalCodeData.map((item) => ({
        value: item.districtNameEn,
        label: item.districtNameEn,
      }));
      setSubDistrictOption(subDistrictOP);
    }
    setFormValue({ ...formValue, [name]: value });
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleDescriptionChange = (name, value) => {
    setFormValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <AccountDetails
        modules={modules}
        formats={formats}
        handleDescriptionChange={handleDescriptionChange}
        subDistrictOption={subDistrictOption}
        onChange={onChange}
        filterOption={filterOption}
        formValue={formValue}
        setFormValue={setFormValue}
        deleteImage={deleteImage}
        isLoader={isLoader}
      />
    </>
  );
};
export default VendorAccountDetails;
