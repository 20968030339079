import { useState , useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCheckoutUrl } from '../../services/bookNowApi.js';
import { toast } from 'react-toastify';

const MobileResponseId = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const getCheckoutUrlData = async (id) =>{
    let response = await getCheckoutUrl(id);
    const checkoutUrl = response.data.checkout_url;
    if (checkoutUrl && checkoutUrl !== 'null') {
      window.location.href = checkoutUrl;
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error('Unable to proceed with the payment. Please refresh the page or contact support.');
    }
  }


  useEffect(() => {
    if (id) getCheckoutUrlData(id);
  }, [id]);

};

export default MobileResponseId;

