import './BookingList.css';
import '../../../../pages/UserBooking/UserBookingList/UserBookingList.css'
import '../../'
import { React, Locale, dayjs, useState, DatePicker, Select, Option, OverlayTrigger, Tooltip, Pagination, Modal, useLocation, useEffect } from '../../../../libraries/index.js';
import { useBookingServicesUserData, UnderReviewLogic } from '../../../component/index.js';
import {  SearchField, MyModal, Heading, EmptyComponent, CustomSpinner } from '../../../../common/index.js';
import { VendorHeader, EditBooking } from '../../index.js';
import { CurrencyDisplay } from '../../../../jsonData';
import { home } from '../../../../Routes/routes.js';
import { CustomImage } from '../../../../lazyComponent/index.js';
import { Filter } from '../../../../assets/Images.js';
const BookingsList = () => {
  const location = useLocation();
  const { t } = Locale();
  const {
    bookingTableHeader,
    isComplte,
    setIsComplte,
    isEmptyPage,
    isOrganizationStatus,
    clickToconfirm,
    setClickConfirm,
    setLoader,
    loader,
    cancelUserBooking,
    showModal,
    handleShow,
    handleClose,
    teamMemberArrayForm,
    services,
    customers,
    filterValue,
    handlefilterInputChange,
    totalPage,
    toggleFilterRow,
    showFilterRow,
    clearFilterfunction,
    setSearchServices,
    handlePaginationChange,
    currentPage,
    itemsPerPage,
    error,
    bookings,
    setBookings,
    filterDataFromSearchHere,
    handleOptChange,
    iscompleteModal,
    setIsCompleteModal,
    otp,
    getServiceLocation,
    bookingFilterInputs,
    bookingSelectInputs,
  } = useBookingServicesUserData();
  const [bookingId, setBookingId] = useState('');
  const [bookingsTab, setBookingsTab] = useState('all');
  const [isNewBooking, setNewBooking] = useState(false);
  const [blankFeild, setBlankFeild] = useState(false);
  const [editBookingModalOpen, setEditBookingModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const th = localStorage.getItem('i18nextLng');
  const isTeamMember = localStorage.getItem('role') === 'TeamMember';
  const bookingIdObj = document.getElementById('bookingId');
  const isSection = localStorage.getItem('isSection');

  useEffect(() => {
    if (isSection !== 'isBusinessBooking') {
      localStorage.setItem('isSection', 'isBusinessBooking');
    }
  }, [location.pathname]);

  const closeEditBookingModal = () => {
    setBlankFeild(!blankFeild);
    setNewBooking(false);
    setEditBookingModalOpen(!editBookingModalOpen);
  };

  const newValue = (value) => {
    const date = new Date(value);
    const timeString = date.toLocaleTimeString('en-US', {
      timeZone: 'Asia/Bangkok',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    return timeString; // Output: "16:30"
  };

  const adjustWidthBookingId = () => {
    bookingIdObj.style.minWidth = 'auto';
    const contentWidth = bookingIdObj.scrollWidth;
    bookingIdObj.style.minWidth = `${contentWidth + 2}px`;
  };

  bookingIdObj?.addEventListener('input', adjustWidthBookingId);

  const getStatusClassName = (booking) => {
    return booking.booking_service.status === 'confirmed'
      ? 'bookinig-confirm'
      : booking.booking_service.status === 'completed'
        ? 'bookinig-complete'
        : booking.booking_service.status === 'cancelled'
          ? 'bookinig-cancelled'
          : booking.booking_service.status === 'expired'
            ? 'bookinig-expire'
            : '';
  };

  const getStatusName = (booking) => {
    return booking.booking_service.status === 'confirmed'
      ? `${t('userBooking.confirmed')}`
      : booking.booking_service.status === 'completed'
        ? `${t('userBooking.completed')}`
        : booking.booking_service.status === 'cancelled'
          ? `${t('userBooking.cancelled')}`
          : booking.booking_service.status === 'expired'
            ? `${t('userBooking.expired')}`
            : '';
  };

  const getCompleteStatuClassName = (booking) => {
    return `bi bi-check2-square editiconsize-user-booking ms-2 cursor-point-class${!(booking.booking_service.status === 'confirmed') ? ' dull-image' : ''}`;
  };

  const getRescheduleStatusClassName = (booking) => {
    return `bi bi-journal-text editiconsize-user-booking-journal ms-2 cursor-point-class${!(booking.booking_service.status === 'confirmed') ? ' dull-image' : ''
      }`;
  };

  const getCancelStatusClassName = (booking) => {
    return `bi bi-trash3-fill editiconsize-user-booking-trash ms-2 cursor-point-class${!(booking.booking_service.status === 'confirmed') ? ' dull-image' : ''
      }`;
  };

  const getBookingTabClassName = (tab) => {
    let baseClassName = 'user-bookings-tab btn';
    if (bookingsTab === tab) {
      return `${baseClassName} user-bookings-tab-active`;
    }
    return `${baseClassName} user-bookings-tab-de-active`;;
  };

  return (
    <>
      {error ? (
        <EmptyComponent type="pageNotFound" />
      ) : (
        <>
          <div className="heading-outer-div">
            <VendorHeader />
            <Heading
              text={t('venderBooking.bookingDetails')}
              icon={'bi bi-calendar-week'}
            />
            <div className="after-heading-outer-div">
              {isOrganizationStatus !== 'verified' ? (
                <>
                  <UnderReviewLogic />
                </>
              ) : (
                <>
                  <div className="user-common-box">
                    <div className={`user-common-box-inner`}>
                      {isEmptyPage !== 'emptyBookings' ? (
                        <>
                          <div className="user-common-box-fix-content">
                            <div className="row row-box m-0 mb-3">
                              <div className="col-md-6 col-sm-6 mobile-view-filter-icon-outer-div">
                                <div className="user-booking-tab-outer-div">
                                  <span
                                    className={getBookingTabClassName('all')}
                                    onClick={() => {
                                      handlefilterInputChange('bookings', '');
                                      setBookingsTab('all');
                                    }}
                                  >
                                    {t('ServiceField.all')}
                                  </span>
                                  <span
                                    className={getBookingTabClassName(
                                      'upcoming',
                                    )}
                                    onClick={() => {
                                      handlefilterInputChange(
                                        'bookings',
                                        'upcoming',
                                      );
                                      setBookingsTab('upcoming');
                                    }}
                                  >
                                    {t('ServiceField.upcoming')}
                                  </span>
                                  <span
                                    className={getBookingTabClassName('past')}
                                    onClick={() => {
                                      handlefilterInputChange(
                                        'bookings',
                                        'past',
                                      );
                                      setBookingsTab('past');
                                    }}
                                  >
                                    {t('ServiceField.past')}
                                  </span>
                                </div>
                                <div className="mobile-view-filter-icon mt-1 me-2">
                                  <CustomImage
                                    alt="filter"
                                    onClick={toggleFilterRow}
                                    src={Filter}
                                    width={'20'}
                                    height={'20'}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6 p-2">
                                <div className="outer-search-feild-booking-buisiness">
                                  <div className="web-view-filter-icon">
                                    <div className="mt-1">
                                      <CustomImage
                                        alt="filter"
                                        onClick={toggleFilterRow}
                                        src={Filter}
                                        width={'20'}
                                        height={'20'}
                                      />
                                    </div>
                                  </div>
                                  <div className="me-2 outer-search-feild-booking-buisiness-inner">
                                    <SearchField
                                      placeholder={t('header.placeholder')}
                                      onChange={(e) => {
                                        setSearchServices(e.target.value);
                                        filterDataFromSearchHere(
                                          e.target.value,
                                        );
                                      }}
                                      onSubmit={(value) =>
                                        filterDataFromSearchHere(value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`user-inner-content-scrollar user-inner-content-scrollar-table`}
                            >
                              {bookings?.length ||
                                isEmptyPage === 'emptyBookingsFilter' ? (
                                <>
                                  <table className="table table-heading table-bordered">
                                    <thead className="table-heading-text">
                                      <tr className="table-light top-heading-booking">
                                        {bookingTableHeader?.length > 0 &&
                                          bookingTableHeader.map((header) => {
                                            if (header.condition === false)
                                              return null;
                                            return (
                                              <th
                                                className={header.className}
                                                key={header.key}
                                              >
                                                {t(header.key)}
                                              </th>
                                            );
                                          })}
                                      </tr>
                                      {showFilterRow && (
                                        <tr>
                                          {bookingFilterInputs?.length > 0 &&
                                            bookingFilterInputs.map((input) => (
                                              <th
                                                className="thclass-booking-user"
                                                key={input.valueKey}
                                              >
                                                {input.type === 'date' ? (
                                                  <DatePicker
                                                    id={input.id}
                                                    value={
                                                      filterValue[
                                                        input.valueKey
                                                      ]
                                                        ? dayjs(
                                                          filterValue[
                                                          input.valueKey
                                                          ],
                                                          input.format,
                                                        )
                                                        : null
                                                    }
                                                    format={input.format}
                                                    className={input.className}
                                                    placeholder="dd/mm/yyyy"
                                                    onChange={(
                                                      date,
                                                      dateString,
                                                    ) =>
                                                      handlefilterInputChange(
                                                        input.valueKey,
                                                        dateString,
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <input
                                                    className={input.className}
                                                    type={input.type}
                                                    id={input.id}
                                                    placeholder={
                                                      input.placeholder
                                                    }
                                                    value={
                                                      filterValue[
                                                      input.valueKey
                                                      ]
                                                    }
                                                    onChange={(e) =>
                                                      handlefilterInputChange(
                                                        input.valueKey,
                                                        e.target.value,
                                                      )
                                                    }
                                                  />
                                                )}
                                              </th>
                                            ))}
                                          {bookingSelectInputs.map((select) =>
                                            select.condition ===
                                              false ? null : (
                                              <th
                                                className="thclass-booking-user"
                                                key={select.valueKey}
                                              >
                                                <Select
                                                  id={select.id}
                                                  placeholder={
                                                    select.placeholder
                                                  }
                                                  value={
                                                    filterValue[
                                                    select.valueKey
                                                    ] || undefined
                                                  }
                                                  onChange={(value) =>
                                                    handlefilterInputChange(
                                                      select.valueKey,
                                                      value,
                                                    )
                                                  }
                                                  className={select.className}
                                                >
                                                  {select.options.map(
                                                    (option) => (
                                                      <Option
                                                        value={option.value}
                                                        key={option.value}
                                                      >
                                                        {option.label}
                                                      </Option>
                                                    ),
                                                  )}
                                                </Select>
                                              </th>
                                            ),
                                          )}

                                          <th className="thclass-booking-user">
                                            <button
                                              onClick={clearFilterfunction}
                                              className="btn clear-filter-booking"
                                            >
                                              {t('button.clearButton')}
                                            </button>
                                          </th>
                                        </tr>
                                      )}
                                    </thead>
                                    {bookings?.length ? (
                                      <tbody>
                                        {bookings.map((booking) => (
                                          <tr
                                            key={booking.booking_service?.id}
                                            className={`mt-1 top-heading-booking`}
                                          >
                                            <td className="tdclass-booking">
                                              {booking.booking_service?.id}
                                            </td>
                                            <td className="tdclass-booking">
                                              {booking.customer_name}
                                            </td>
                                            <td className="tdclass-booking">
                                              {th === 'thai' && th !== undefined
                                                ? booking?.service_name_thai
                                                : booking.service_name_en}
                                            </td>
                                            <td className="tdclass-booking">
                                              {booking.booking_service.created_at
                                                .substring(0, 10)
                                                .split('-')[2] +
                                                home +
                                                booking.booking_service.created_at
                                                  .substring(0, 10)
                                                  .split('-')[1] +
                                                home +
                                                booking.booking_service.created_at
                                                  .substring(0, 10)
                                                  .split('-')[0]}
                                            </td>

                                            <td className="tdclass-booking">
                                              {booking.booking_service.booking_date.split(
                                                '-',
                                              )[2] +
                                                home +
                                                booking.booking_service.booking_date.split(
                                                  '-',
                                                )[1] +
                                                home +
                                                booking.booking_service.booking_date.split(
                                                  '-',
                                                )[0]}
                                            </td>
                                            <td className="tdclass-booking">
                                              {booking.duration} Min
                                            </td>

                                            <td className="tdclass-booking">
                                              {newValue(
                                                booking.booking_service
                                                  .booking_time,
                                              )}
                                            </td>
                                            <td className="tdclass-booking-number px-2">
                                              <CurrencyDisplay
                                                value={
                                                  booking.booking_service.price
                                                    ? booking.booking_service
                                                      .price
                                                    : 0
                                                }
                                              />
                                            </td>
                                            <td className="tdclass-booking-number px-2">
                                              <CurrencyDisplay
                                                value={
                                                  booking.total_discount
                                                    ? booking.total_discount
                                                    : 0
                                                }
                                              />
                                            </td>
                                            <td className="tdclass-booking-number px-2">
                                              <CurrencyDisplay
                                                value={
                                                  booking.peak_off_discount
                                                    ? booking.peak_off_discount
                                                    : 0
                                                }
                                              />
                                            </td>
                                            <td className="tdclass-booking-number px-2">
                                              <CurrencyDisplay
                                                value={
                                                  booking?.booking_service
                                                    ?.applied_cashback
                                                    ? booking?.booking_service
                                                      ?.applied_cashback
                                                    : 0
                                                }
                                              />
                                            </td>
                                            <td className="tdclass-booking-number px-2">
                                              <CurrencyDisplay
                                                value={
                                                  booking.paid_amount
                                                    ? booking.paid_amount
                                                    : 0
                                                }
                                              />
                                            </td>
                                            <td className="tdclass-booking">
                                              {booking.payment_type === 'online'
                                                ? `${t(
                                                  'userBooking.payOnline',
                                                )}`
                                                : `${t(
                                                  'userBooking.payAtShop',
                                                )}`}
                                            </td>
                                            {!isTeamMember ? (
                                              <>
                                                <td className="tdclass-booking">
                                                  {booking?.team_member_name}
                                                </td>
                                                <td className="tdclass-booking">
                                                  {getServiceLocation(
                                                    booking?.booking_service,
                                                  )}
                                                </td>
                                              </>
                                            ) : (
                                              <>
                                                <td className="tdclass-booking">
                                                  {getServiceLocation(
                                                    booking?.booking_service,
                                                  )}
                                                </td>
                                              </>
                                            )}
                                            <td
                                              className={`tdclass-booking ${getStatusClassName(booking)}`}
                                            >
                                              <span
                                                className={`btn-status ${booking.booking_service.status}`}
                                              >
                                                {getStatusName(booking)}
                                              </span>
                                              &nbsp;
                                              {booking.booking_service
                                                .status === 'cancelled' &&
                                                booking.booking_service
                                                  .cancel_reason && (
                                                  <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                      <Tooltip id="booking-status-tooltip">
                                                        {
                                                          booking
                                                            .booking_service
                                                            .cancel_reason
                                                        }
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span className="cursor-point-class">
                                                      &#9432;
                                                    </span>
                                                  </OverlayTrigger>
                                                )}
                                            </td>
                                            <td className="tdclass-booking">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>
                                                    {t('orderHistory.complete')}
                                                  </Tooltip>
                                                }
                                              >
                                                <i
                                                  className={getCompleteStatuClassName(
                                                    booking,
                                                  )}
                                                  aria-hidden="true"
                                                  onClick={() => {
                                                    setIsComplte(!isComplte);
                                                    setClickConfirm(false);
                                                    handleShow(
                                                      isTeamMember
                                                        ? true
                                                        : false,
                                                      booking,
                                                    );
                                                    setIsCompleteModal(
                                                      isTeamMember
                                                        ? true
                                                        : false,
                                                    );
                                                    setBookingId(booking);
                                                  }}
                                                ></i>
                                              </OverlayTrigger>
                                              {!isTeamMember && (
                                                <>
                                                  <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                      <Tooltip>
                                                        {t(
                                                          'venderBooking.reshelule',
                                                        )}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <i
                                                      className={getRescheduleStatusClassName(
                                                        booking,
                                                      )}
                                                      aria-hidden="true"
                                                      onClick={() => {
                                                        setNewBooking(false);
                                                        setBookingId(
                                                          booking
                                                            .booking_service.id,
                                                        );
                                                        setEditBookingModalOpen(
                                                          true,
                                                        );
                                                      }}
                                                    ></i>
                                                  </OverlayTrigger>
                                                </>
                                              )}

                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>
                                                    {t('button.cancelButton')}
                                                  </Tooltip>
                                                }
                                              >
                                                <i
                                                  className={getCancelStatusClassName(
                                                    booking,
                                                  )}
                                                  aria-hidden="true"
                                                  onClick={() => {
                                                    setClickConfirm(true);
                                                    handleShow();
                                                    setBookingId(booking);
                                                    setCancelReason(
                                                      booking?.cancel_reason,
                                                    );
                                                  }}
                                                ></i>
                                              </OverlayTrigger>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <>
                                        <tbody>
                                          <tr className="text-center">
                                            <td
                                              colSpan={
                                                isTeamMember ? '16' : '17'
                                              }
                                              className="notAvailable"
                                            >
                                              {t(
                                                'ServiceField.noBookingsAvailable',
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </>
                                    )}
                                  </table>

                                  {totalPage > 15 && (
                                    <div className="text-center">
                                      <Pagination
                                        current={currentPage}
                                        pageSize={itemsPerPage}
                                        total={totalPage}
                                        showSizeChanger={false}
                                        defaultCurrent={3}
                                        onChange={handlePaginationChange}
                                      />
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {isEmptyPage === 'emptyBookings' && (
                                    <EmptyComponent type="isBooking" />
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              className={
                                editBookingModalOpen
                                  ? `fade modal-backdrop show`
                                  : ''
                              }
                            ></div>
                            <Modal
                              className="branch-modal"
                              open={editBookingModalOpen}
                              okButtonProps={{ style: { display: 'none' } }}
                              cancelButtonProps={{ style: { display: 'none' } }}
                            >
                              <EditBooking
                                setLoader={setLoader}
                                blankFeild={blankFeild}
                                services={services}
                                customers={customers}
                                isNewBooking={isNewBooking}
                                id={bookingId ? bookingId : 0}
                                bookings={bookings}
                                setBookings={setBookings}
                                teamMemberArrayForm={teamMemberArrayForm}
                                closeEditBookingModal={closeEditBookingModal}
                              />
                            </Modal>
                          </div>
                        </>
                      ) : (
                        <>
                          <EmptyComponent type="isBooking" />
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      <MyModal
        otp={otp}
        iscompleteModal={iscompleteModal}
        handleOptChange={handleOptChange}
        isComplte={isComplte}
        showModal={showModal}
        handleClose={handleClose}
        deleteServices={cancelUserBooking}
        message={
          clickToconfirm
            ? t('userBooking.areYouSure')
            : t('venderBooking.completeThisBooking')
        }
        deleteItemId={bookingId ? bookingId : 0}
        isCancel={true}
        reason={cancelReason}
      />
      {loader && <CustomSpinner />}
    </>
  );
};

export default BookingsList;
