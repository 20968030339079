import './customAntCard.css';
import { React, Card } from '../../libraries';
import { CustomImage } from '../../lazyComponent';
const { Meta } = Card;
const CustomAntCard = ({ image, title, className }) => (
  <Card
    hoverable
    cover={
      <CustomImage
        className="custom_ant_card_img"
        alt="example"
        src={image}
        width={'unset'}
        height={'unset'}
      />
    }
  >
    <Meta className={className} title={title} />
  </Card>
);
export default CustomAntCard;
