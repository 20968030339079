import './ServiceList.css';
import { React, OverlayTrigger, Tooltip, Pagination, Locale, useLocation, useEffect } from '../../../libraries/index.js';
import { VendorHeader, ServiceFilterRow } from '../index.js';
import { MyModal, SearchField, CustomSpinner, Heading, EmptyComponent } from '../../../common/index.js';
import { useServicesData, UnderReviewLogic } from '../../component/index.js';
import { CurrencyDisplay } from '../../../jsonData';
import { Filter } from '../../../assets/Images.js';
const ServicesIndex = () => {
  const {
    filterValue,
    handlefilterInputChange,
    totalPage,
    toggleFilterRow,
    showFilterRow,
    filterDataFromSearchHere,
    clearFilterfunction,
    error,
    spinner,
    serviceData,
    showModal,
    handleShow,
    handleClose,
    editService,
    addNewService,
    deleteServices,
    setDeleteItemId,
    deleteItemId,
    actovateOrDeactivate,
    handlePaginationChange,
    currentPage,
    itemsPerPage,
    categories,
    subCategories,
    setSearchServices,
    showBookingsRecords,
    isOrganizationStatus,
    showEmptyPage,
    setIsComplte,
    isComplte,
  } = useServicesData();
  const location = useLocation();
  const { t } = Locale();
  const isSection = localStorage.getItem('isSection');
  const selectedLanguage = localStorage.getItem("i18nextLng")
  useEffect(() => {
    if (isSection !== 'isBusinessService') {
      localStorage.setItem('isSection', 'isBusinessService');
    }
  }, [location.pathname]);

  const getServiceName = (service) => {
    return (selectedLanguage === 'en') ? (service?.service_name_en) : (service?.service_name_thai)
  }

  const getCategoryName = (service) => {
    return (selectedLanguage === 'en') ? (service?.category?.name) : (service?.category?.name_thai)
  }

  const subCategoryName = (service) => {
    return (selectedLanguage === 'en') ? (service?.sub_category?.name) : (service?.sub_category?.name_thai)

  }
  return (
    <>
      <div className={`heading-outer-div ${spinner && 'blure-bacground'}`}>
        {spinner && <CustomSpinner />}
        <MyModal
          isComplte={isComplte}
          showModal={showModal}
          handleClose={handleClose}
          deleteServices={deleteServices}
          message={t('ServiceField.deleteThisService')}
          deleteItemId={deleteItemId}
          service_component={true}
        />
        <VendorHeader />
        <Heading
          text={t('ServiceField.services')}
          icon={'bi bi-collection'}
          button={
            <>
              {' '}
              <button onClick={addNewService} className="btn branches-buttton">
                {t('button.newService')}
              </button>
            </>
          }
        />
        <div className="after-heading-outer-div">
          {isOrganizationStatus !== 'verified' ? (
            <>
              <UnderReviewLogic />
            </>
          ) : (
            <>
              <div className="user-common-box">
                {error ? (
                  <>
                    <div className="service-page">
                      <EmptyComponent type="notFound" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="user-common-box-inner">
                      <div className="user-common-box-fix-content">
                        <div className="calendar-row ">
                          <div className="calendar-col"></div>
                          <div className="calendar-col d-flex">
                            <div className="boot-table-filter">
                              <img
                                alt="filter"
                                onClick={toggleFilterRow}
                                onKeyDown={(event) =>
                                  (event.key === 'Enter' ||
                                    event.key === ' ') &&
                                  toggleFilterRow()
                                }
                                src={Filter}
                                role="presentation"
                                className="filterSvg"
                              />
                            </div>
                            <SearchField
                              placeholder={t('header.placeholder')}
                              onChange={(e) => {
                                setSearchServices(e.target.value);
                                filterDataFromSearchHere(e.target.value);
                              }}
                              onSubmit={(value) =>
                                filterDataFromSearchHere(value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {serviceData &&
                        (serviceData.length > 0 ||
                          showEmptyPage === 'emptyServicesFilter') ? (
                        <>
                          <div className="user-inner-content-scrollar user-inner-content-scrollar-table mt-3 vendor-service-list-table">
                            <table className="table table-heading table-bordered">
                              <thead>
                                <tr className="table-light top-heading">
                                  <th className="thclass w-16">
                                    {t('ServiceField.serviceName')}
                                  </th>
                                  <th className="thclass thclass-width15">
                                    {t('ServiceField.category')}
                                  </th>
                                  <th className="thclass w-16">
                                    {t('ServiceField.subCategory')}
                                  </th>
                                  <th className="thclass thclass-width9">
                                    {t('ServiceField.duration')}
                                  </th>
                                  <th className="thclass-number w-12">
                                    {t('ServiceField.price')}
                                  </th>
                                  <th className="thclass-number w-12">
                                    {t('ServiceField.specialPrice')}
                                  </th>
                                  <th className="thclass w-10">
                                    {t('ServiceField.status')}
                                  </th>
                                  <th className="thclass w-10">
                                    {t('ServiceField.action')}
                                  </th>
                                </tr>
                                {showFilterRow && (
                                  <ServiceFilterRow
                                    t={t}
                                    filterValue={filterValue}
                                    handlefilterInputChange={
                                      handlefilterInputChange
                                    }
                                    categories={categories}
                                    subCategories={subCategories}
                                    clearFilterfunction={clearFilterfunction}
                                  />
                                )}
                              </thead>
                              {serviceData?.length ? (
                                <tbody>
                                  {serviceData.map((service, index) => (
                                    <tr
                                      key={index}
                                      className="mt-1 top-heading"
                                    >
                                      <td className="tdclass ">
                                        {getServiceName(service?.service)}
                                      </td>
                                      <td className="tdclass ">
                                        {getCategoryName(service?.service)}
                                      </td>
                                      <td className="tdclass ">
                                        {subCategoryName(service?.service)}
                                      </td>
                                      <td className="tdclass ">{`${service?.service.duration} min`}</td>
                                      <td className="tdclass-number ">
                                        <CurrencyDisplay
                                          value={service?.service.price}
                                        />
                                      </td>
                                      <td className="tdclass-number ">
                                        <CurrencyDisplay
                                          value={
                                            service.service.special_price
                                              ? service.service.special_price
                                              : '0'
                                          }
                                        />
                                      </td>
                                      <td className="tdclass ">
                                        <div className="status-button-center">
                                          <div
                                            onClick={() =>
                                              actovateOrDeactivate(service)
                                            }
                                            onKeyDown={(event) =>
                                              (event.key === 'Enter' ||
                                                event.key === ' ') &&
                                              actovateOrDeactivate(service)
                                            }
                                            role="button"
                                            tabIndex="0"
                                            className={`cursor-point-class ${service.service?.is_enabled === true ? 'status-active' : 'status-deactive'}`}
                                          >
                                            <div
                                              className={`${service.service?.is_enabled ===
                                                true
                                                ? 'activate'
                                                : 'deactivate'
                                                }`}
                                            ></div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="tdclass px-2">
                                        <div className="d-flex status-button-center">
                                          <div className="px-3">
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>
                                                  {t('button.viewBookings')}
                                                </Tooltip>
                                              }
                                            >
                                              <i
                                                onClick={() =>
                                                  showBookingsRecords(service)
                                                }
                                                className="bi bi-eye-fill"
                                                aria-hidden="true"
                                              ></i>
                                            </OverlayTrigger>
                                          </div>
                                          <div>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>
                                                  {t('button.editButton')}
                                                </Tooltip>
                                              }
                                            >
                                              <i
                                                onClick={() =>
                                                  editService(service)
                                                }
                                                className="bi bi-pencil-fill action_edit"
                                                aria-hidden="true"
                                              ></i>
                                            </OverlayTrigger>
                                          </div>
                                          <div
                                            className="ms-3"
                                            onClick={() => {
                                              setIsComplte(true);
                                              handleShow();
                                              setDeleteItemId(
                                                service?.service.id,
                                              );
                                            }}
                                            onKeyDown={(event) =>
                                              (event.key === 'Enter' ||
                                                event.key === ' ') &&
                                              (handleShow(),
                                                setDeleteItemId(
                                                  service?.service.id,
                                                ))
                                            }
                                            role="button"
                                            tabIndex="0"
                                          >
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>
                                                  {t('button.deleteButton')}
                                                </Tooltip>
                                              }
                                            >
                                              <i
                                                className="bi bi-trash-fill action_delete"
                                                aria-hidden="true"
                                              ></i>
                                            </OverlayTrigger>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr className="text-center">
                                    <td colSpan="8" className="notAvailable">
                                      {t('ServiceField.noServiceAvailable')}
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                            {totalPage > 15 && (
                              <div className="text-center">
                                <Pagination
                                  current={currentPage}
                                  pageSize={itemsPerPage}
                                  total={totalPage}
                                  showSizeChanger={false}
                                  defaultCurrent={3}
                                  onChange={handlePaginationChange}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {showEmptyPage === 'emptyServices' && (
                            <EmptyComponent type="isService" />
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default ServicesIndex;
