import { apiCall } from './base';

const getAboutUsDetail = async () => {
  const response = await apiCall('footer/abouts', 'GET');
  return response;
};
const getPrivacyPolicies = async () => {
  const response = await apiCall('footer/privacy_policies', 'GET');
  return response;
};
const getBussinessPolicyDetail = async () => {
  const response = await apiCall('footer/business_partner_policies', 'GET');
  return response;
};

const getTermConditionDetail = async () => {
  const response = await apiCall('footer/terms_and_conditions', 'GET');
  return response;
};

const getCancelPolicyDetail = async () => {
  const response = await apiCall(
    'footer/refund_and_cancellation_policies',
    'GET',
  );
  return response;
};

const contactUs = async (param) => {
  const response = await apiCall('footer/contacts', 'POST', param);
  return response;
};
export {
  getAboutUsDetail,
  getPrivacyPolicies,
  getBussinessPolicyDetail,
  contactUs,
  getTermConditionDetail,
  getCancelPolicyDetail,
};
