import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from '@react-google-maps/api';
import { shopNearMe } from '../../services/mapApi';
import { serviceDescription } from '../../Routes/routes';
import { encryptData } from '../../customHooks/urlEncyption';
import { organizationUrlFormat, urlFormat } from '../../customHooks/urlFormat';
const CustomImage = React.lazy(() => import('../../common/image/image'));

const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;

const geocodeAddress = async (address) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${googleMapApiKey}`,
    );
    const data = await response.json();
    if (data.results && data.results.length > 0) {
      const location = data.results[0].geometry.location;
      return { lat: location.lat, lng: location.lng };
    }
  } catch (error) {
    console.error('Error geocoding address:', error);
  }
  return null;
};

export const DisplayServiceMap = ({ address, postcode, city }) => {
  const [selectedShop, setSelectedShop] = useState(null);
  const [shops, setShops] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: googleMapApiKey,
    libraries: ['places'],
  });
  let encryptedId;
  let formatOrganizationName


  useEffect(() => {
    const initializeMap = async () => {
      const fullAddress = `${address}, ${city}, ${postcode}`;
      let location = await geocodeAddress(fullAddress);

      if (!location) {
        const fallbackAddress = `${city} ${postcode}`;
        location = await geocodeAddress(fallbackAddress);
      }

      if (location) {
        setCurrentLocation(location);
        fetchShopsWithinRadius(location);
      }
    };

    initializeMap();
  }, [address, city, postcode]);

  const fetchShopsWithinRadius = useCallback(async (location) => {
    try {
      const response = await shopNearMe(location);
      if (response.ok) {
        const data = await response.json();
        setShops(data?.shops);
      } else {
        console.error('Failed to fetch shops within radius');
      }
    } catch (error) {
      console.error('Error fetching shops within radius:', error);
    }
  }, []);

  const handleMarkerClick = useCallback((shop) => {
    setSelectedShop(shop);
     encryptedId = encryptData(selectedShop?.id);
     formatOrganizationName = organizationUrlFormat(selectedShop.name);
  }, []);

  const handleMapClick = useCallback(() => {
    setSelectedShop(null);
  }, []);

  const calculateDistance = useCallback((lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180); // deg2rad below
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  }, []);

  const memoizedMarkers = useMemo(() => {
    const currentLocationMarker = currentLocation ? (
      <Marker
        key="currentLocation"
        position={{ lat: currentLocation.lat, lng: currentLocation.lng }}
        icon={{
          path: window?.google?.maps?.SymbolPath?.CIRCLE,
          fillColor: 'blue',
          fillOpacity: 0.8,
          strokeWeight: 0,
          scale: 8,
        }}
        onClick={() => handleGoogleMap(address)}
        clickable={true}
      />
    ) : null;

    const shopMarkers = shops.map((shop) => {
      const distance = calculateDistance(
        currentLocation.lat,
        currentLocation.lng,
        shop.latitude,
        shop.longitude,
      );
      return (
        <Marker
          key={shop.id}
          position={{ lat: shop.latitude, lng: shop.longitude }}
          icon={{
            url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
            scaledSize: new window.google.maps.Size(32, 32),
          }}
          onClick={() => handleMarkerClick(shop)}
          clickable={true}
        >
          {selectedShop === shop && (
            <InfoWindow
              position={{
                lat: selectedShop.latitude,
                lng: selectedShop.longitude,
              }}
              options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
              className="custom-info-window"
            >
              <div className="image-section">
                <a href={`${serviceDescription}/${encryptedId}/${formatOrganizationName}`}>
                  <CustomImage
                    width={'unset'}
                    height={'unset'}
                    src={selectedShop.images}
                    alt="shop_image"
                    className={'display-service-image-secion-img'}
                  />
                </a>
                <span>
                  <a href={`${serviceDescription}/${encryptedId}/${formatOrganizationName}`}>
                    {selectedShop.name ? selectedShop.name : ' '}
                  </a>
                </span>
                <p>
                  <i className="bi bi-star-fill colorYellow fontSize-15"></i>{' '}
                  {selectedShop.rating}{' '}
                </p>
                <p>Distance: {distance.toFixed(2)} km</p>
              </div>
            </InfoWindow>
          )}
        </Marker>
      );
    });

    return [currentLocationMarker, ...shopMarkers];
  }, [
    currentLocation,
    shops,
    handleMarkerClick,
    selectedShop,
    calculateDistance,
    address,
    city,
    postcode,
  ]);

  const handleGoogleMap = (address) => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(googleMapsUrl, '_blank');
  };


  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          className={'map-div'}
          onClick={handleMapClick}
          center={currentLocation}
          zoom={12}
          mapContainerStyle={{ width: '100%', aspectRatio: '7.69/8' }}
        >
          {memoizedMarkers}
        </GoogleMap>
      ) : (
        <div className="text-center">Loading...</div>
      )}
    </>
  );
};
