import { React, useEffect, useState, useCallback, useMemo,PropTypes,useLocation } from '../../../libraries';
import {
  customerRatings,
  topServices,
  upComingBooking,
  totalRevenue,
  filtertotalRevenue,
  handleErrorResponse
} from '../../../services';
import { Dashbord } from '../../pages';
const DashBoardLogic = () => {
  const location = useLocation();
  const [topService, setTopService] = useState([]);
  const [upCommingBooking, setUpCommingBooking] = useState([]);
  const [customerRating, setCustomerRating] = useState([]);
  const [customerRevenue, setCustomerRevenue] = useState([]);
  const [dashBoardData, setDashBoardData] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [filterRevenue, setFilterRevenue] = useState(null);
  const isOrganizationStatus = localStorage.getItem('status');
  const currentYear = new Date().getFullYear();
  const [filterValue, setFilterValue] = useState({
    all: true,
    monthly: false,
    weekly: false,
  });
  const [isEmpty, setIsEmpty] = useState({
    isTopService: false,
    isUpComingBooking: false,
    isCustomerRating: false,
    isTotalRevenue: false,
  });
  const isSection = localStorage.getItem('isSection');

  useEffect(() => {
    if (isSection !== 'isDashboard') {
      localStorage.setItem('isSection', 'isDashboard');
    }
  }, [location.pathname, isSection]);

  useEffect(() => {
    if (isOrganizationStatus === 'verified') {
      fetchData();
    }
  }, [isOrganizationStatus]);

  const fetchData = useCallback(async () => {
    try {
      const [
        topServiceResponse,
        upComingBookingResponse,
        customerRatingResponse,
        totalRevenueResponse,
      ] = await Promise.all([
        topServices(),
        upComingBooking(),
        customerRatings(),
        totalRevenue(),
      ]);
      setTopService(topServiceResponse.data.best_selling_services || []);
      setUpCommingBooking(upComingBookingResponse.data.vendor_bookings || []);
      setCustomerRating(customerRatingResponse.data.reviews_rating || []);
      setCustomerRevenue(
        totalRevenueResponse.data.total_revenue.total_revenue || [],
      );
      setDashBoardData(topServiceResponse.data);

      setIsEmpty({
        isTopService: !topServiceResponse.data.best_selling_services,
        isUpComingBooking: !upComingBookingResponse.data.vendor_bookings,
        isCustomerRating: !customerRatingResponse.data.reviews_rating,
        isTotalRevenue: !totalRevenueResponse.data.total_revenue,
      });

      setIsLoader(false);
    } catch (err) {
      handleErrorResponse(err);
      setIsLoader(false);
    }
  }, []);

  const handleFilterRevenue = useCallback(async (year,setIsSalesIcon) => {
    try {
      const response = await filtertotalRevenue(year);
      setFilterRevenue(response.data);
      if (setIsSalesIcon)
        setIsSalesIcon(false)
    } catch (error) {
      console.error('Error fetching filtered revenue:', error);
    }
  }, []);

  useEffect(() => {
    handleFilterRevenue(currentYear);
  }, [currentYear, handleFilterRevenue]);

  const handleFilter = useCallback(async (value,setIsserviceIcon) => {
    setFilterValue({
      all: value === 'all',
      monthly: value === 'monthly',
      weekly: value === 'weekly',
    });

    try {
      const param = value !== 'all' ? { filter: value } : null;
      const response = await topServices(param);

      setTopService(response.data.best_selling_services || []);
      setIsEmpty((prev) => ({
        ...prev,
        isTopService: !response.data.best_selling_services,
      }));
      setIsserviceIcon(false)
    } catch (err) {
      handleErrorResponse(err);
    }
  }, []);

  const memoizedDashbordProps = useMemo(
    () => ({
      handleFilterRevenue,
      filterRevenue,
      isEmpty,
      filterValue,
      handleFilter,
      isOrganizationStatus,
      isLoader,
      topService,
      upCommingBooking,
      customerRating,
      dashBoardData,
      customerRevenue,
    }),
    [
      handleFilterRevenue,
      filterRevenue,
      isEmpty,
      filterValue,
      handleFilter,
      isOrganizationStatus,
      isLoader,
      topService,
      upCommingBooking,
      customerRating,
      dashBoardData,
      customerRevenue,
    ],
  );

  return <Dashbord {...memoizedDashbordProps} />;
};

DashBoardLogic.propTypes = {
  pageViews: PropTypes.number,
};
export default DashBoardLogic;
