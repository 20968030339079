import {
  useState,
  useEffect,
  useLocation,
  Locale,
} from '../../../../libraries/index';
import { handleErrorResponse } from '../../../../services/index';
import {
  viewMember,
  sendBookingVerificationCode,
  viewService,
  handleSuccessResponse,
  getBookingsVendor,
  getAllCustomerInfo,
  getBookingById,
  editVendorBookings,
} from '../../../../services/index';
import { useDebouncedValue } from '../../../../jsonData/debouncingFunction';
import {GetOrganizationStatus} from '../../../component/index';

const useBookingServicesUserData = () => {
  const { t } = Locale();
  const { isOrganizationStatus } = GetOrganizationStatus();
  const location = useLocation();
  const serviceObject = location.state;
  const [bookings, setBookings] = useState([]);
  const [updateData, setUpdateData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [totalPage, setTotalePage] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const [callPagination, setPagination] = useState(false);
  const [filterValue, setFilterValue] = useState({
    bookings: '',
    bookingId: '',
    serviceName: '',
    customerName: '',
    date: '',
    duration: '',
    amount: '',
    discount: '',
    peakOfDiscount: '',
    paymentType: '',
    status: '',
    teamMember: '',
    cashback: '',
    totalAmaount: '',
    time: '',
    createTime: '',
    serviceLocation: '',
  });
  const [teamMemberArrayForm, setTeamMemberArrayForm] = useState([]);
  const [searchServices, setSearchServices] = useState('');
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const debouncedSearchTerm = useDebouncedValue(filterValue, 500);
  const [clickToconfirm, setClickConfirm] = useState(false);
  const [previousMinute, setPreviousMinute] = useState('');
  const [isEmptyPage, setIsEmptyPage] = useState(false);
  const [callFilter, setCallFilter] = useState(false);
  const [isComplte, setIsComplte] = useState(false);
  const [otp, setOpt] = useState({ verificationCode: '' });
  const [iscompleteModal, setIsCompleteModal] = useState(false);
  const isTeamMember = localStorage.getItem('role') === 'TeamMember';
  const bookingTableHeader = [
    { key: 'venderBooking.id', className: 'thclass-booking' },
    { key: 'venderBooking.customer', className: 'thclass-booking' },
    { key: 'venderBooking.service', className: 'thclass-booking' },
    { key: 'calender.createTime', className: 'thclass-booking' },
    { key: 'venderBooking.sheduleAt', className: 'thclass-booking' },
    { key: 'ServiceField.duration', className: 'thclass-booking' },
    { key: 'ServiceField.booking_time', className: 'thclass-booking' },
    { key: 'venderBooking.paidAmount', className: 'thclass-booking-number' },
    { key: 'cart.discount', className: 'thclass-booking-number' },
    { key: 'venderBooking.peakDiscount', className: 'thclass-booking-number' },
    { key: 'venderBooking.cashback', className: 'thclass-booking-number' },
    { key: 'venderBooking.totalAmount', className: 'thclass-booking-number' },
    {
      key: 'venderBooking.paymentType',
      className: 'thclass-booking vendor-payment-list-select',
    },
    
    {
      key: 'venderBooking.teamMember',
      className: 'thclass-booking vendor-team-list-select',
      condition: !isTeamMember,
    },
    {
      key: 'venderBooking.serviceLocation',
      className: 'thclass-booking vendor-team-list-select',
    },
    { key: 'ServiceField.status', className: 'thclass-booking' },
    { key: 'venderBooking.action', className: 'thclass-booking' },
  ];

  useEffect(() => {
    isOrganizationStatus === 'verified' && callFilter && applyFilter(1, 15);
  }, [isOrganizationStatus, debouncedSearchTerm]);

  useEffect(() => {
    callPagination && handlePaginationChange(currentPage, itemsPerPage);
  }, [updateData]);

  useEffect(() => {
    isOrganizationStatus === 'verified' && fetchData();
  }, [isOrganizationStatus]);

  useEffect(() => {
    if (serviceObject) {
      setShowFilterRow(!showFilterRow);
      handlefilterInputChange(
        'serviceName',
        serviceObject.service.service_name_en,
      );
    }
  }, []);

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    const currentMinute = selectedTime.substring(3, 5);
    if (currentMinute !== previousMinute && previousMinute !== '') {
      handlefilterInputChange('time', selectedTime);
    }
    setPreviousMinute(currentMinute);
  };

  const paramObject = (page, pageSize) => {
    const commonParams = {
      ...(filterValue.bookings && { bookings: filterValue.bookings }),
      page_number: page,
      per_page: pageSize,
      booking_service_id: filterValue.bookingId
        ? parseInt(filterValue.bookingId, 10)
        : '',
      name: filterValue.customerName,
      service_name_en: filterValue.serviceName,
      booking_date: filterValue.date,
      duration: filterValue.duration ? parseInt(filterValue.duration, 10) : '',
      created_at: filterValue.createTime ? filterValue.createTime : '',
      booking_time: filterValue?.time,
      price: filterValue.amount ? parseInt(filterValue.amount, 10) : '',
      discount_amount: filterValue.discount
        ? parseInt(filterValue.discount, 10)
        : '',
      peak_off_discount: filterValue.peakOfDiscount
        ? parseInt(filterValue.peakOfDiscount, 10)
        : '',
      applied_cashback: filterValue.cashback
        ? parseInt(filterValue.cashback, 10)
        : '',
      paid_amount: filterValue?.totalAmaount
        ? parseInt(filterValue?.totalAmaount, 10)
        : '',
      payment_type: filterValue?.paymentType,
      team_member_id: filterValue?.teamMember,
      status: filterValue?.status,
      search: searchServices,
    };

    if (filterValue?.serviceLocation) {
      return new URLSearchParams({
        ...commonParams,
        delivery_type: filterValue?.serviceLocation,
      });
    } else {
      return new URLSearchParams(commonParams);
    }
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      const params = { page_number: currentPage, per_page: itemsPerPage };
      let bookingResponse;
      let serviceCustomerResponse;
      bookingResponse = await getBookingData(params);
      if (
        bookingResponse.data &&
        bookingResponse.data.booked_services &&
        bookingResponse.data.booked_services.length > 0
      ) {
        serviceCustomerResponse = getServiceCustomerData();
        setCallFilter(true);
        setPagination(true);
      }
      const bookingData = bookingResponse?.data;
      if (
        !bookingData?.booked_services ||
        bookingData?.booked_services?.length <= 0 ||
        bookingResponse?.data?.status_code === 204
      ) {
        setIsEmptyPage('emptyBookings');
      } else {
        setIsEmptyPage(false);
      }
      const serviceCustomerData = serviceCustomerResponse.data;
      setBookings(bookingData.booked_services || []);
      setTotalePage(
        bookingData.booked_services ? bookingData.total_pages * 15 : 0,
      );
      setCurrentPage(1);
      const team = await viewMember();
      setTeamMemberArrayForm(team.data.team_members);
      if (serviceCustomerData.services_info)
        setServices(serviceCustomerData.services_info);

      if (serviceCustomerData.customers)
        setCustomers(serviceCustomerData.customers);
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  const applyFilter = async (page, pageSize) => {
    setLoader(true);
    try {
      const booking = await getBookingsVendor(paramObject(page, pageSize));
      setBookings(booking.data.booked_services || []);
      if (
        !booking?.data?.booked_services ||
        booking?.data?.booked_services?.length < 0 ||
        booking?.data?.status_code === 204
      ) {
        setIsEmptyPage('emptyBookingsFilter');
      } else {
        setIsEmptyPage(false);
      }
      setTotalePage(
        booking.data.booked_services ? booking.data.total_pages * 15 : 0,
      );
      setCurrentPage(1);
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  const handlefilterInputChange = (name, value) => {
    if (name === 'bookings') {
      if (name === 'bookings') {
        setFilterValue({
          [name]: value,
          bookingId: '',
          serviceName: '',
          customerName: '',
          date: '',
          duration: '',
          amount: '',
          discount: '',
          peakOfDiscount: '',
          paymentType: '',
          status: '',
          teamMember: '',
          cashback: '',
          totalAmaount: '',
          time: '',
          createTime: '',
        });
      }
    } else {
      setFilterValue({ ...filterValue, [name]: value });
    }
  };

  const handlePaginationChange = async (page, pageSize) => {
    setLoader(true);
    try {
      const queryParams = paramObject(page, pageSize);
      const booking = await getBookingsVendor(queryParams);
      setBookings(booking.data.booked_services);
      setCurrentPage(page);
      setItemsPerPage(pageSize);
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  const toggleFilterRow = () => {
    setShowFilterRow(!showFilterRow);
  };

  const clearFilterfunction = async () => {
    setCurrentPage(1);
    setFilterValue({
      bookingId: '',
      serviceName: '',
      customerName: '',
      date: '',
      duration: '',
      amount: '',
      discount: '',
      paymentType: '',
      status: '',
      teamMember: '',
      cashback: '',
      totalAmaount: '',
      time: '',
      createTime: '',
      peakOfDiscount: '',
    });
  };

  const filterDataFromSearchHere = () => {
    setFilterValue({
      bookingId: '',
      serviceName: '',
      customerName: '',
      date: '',
      duration: '',
      amount: '',
      discount: '',
      paymentType: '',
      status: '',
      teamMember: '',
      cashback: '',
      totalAmaount: '',
      time: '',
      createTime: '',
    });
  };

  const handleShow = async (isComplete, booking) => {
    if (isComplete && isTeamMember) {
      let response = await optVerification(booking); // Await the async function
      if (response && response.status === 200) {
        setShowModal(true);
      }
    } else {
      setShowModal(true);
    }
  };

  const handleClose = () => {
    setIsComplte(false);
    setShowModal(false);
    setIsCompleteModal(false);
  };

  const cancelUserBooking = async (bookings, updateCancelReason) => {
    try {
      setIsComplte(false);
      setLoader(true);
      if (clickToconfirm) {
        handleClose();
        const formData = new FormData();
        formData.set('status', 'cancelled');
        formData.set('booking_date', bookings.booking_service.booking_date);
        formData.set('booking_time', bookings.booking_service.booking_time);
        formData.set('cancel_reason', updateCancelReason);
        const response = await editVendorBookings(
          bookings.booking_service?.id,
          formData,
        );
        handleSuccessResponse(response, response.data.message);
        setUpdateData((pre) => pre + 1);
        const booking = await getBookingById(bookings.booking_service?.id);
        setBookings(
          bookings.map((item) =>
            item.booking_service.id === bookings.booking_service?.id
              ? booking.data
              : item,
          ),
        );
      } else {
        const formData = new FormData();
        formData.set('status', 'completed');
        if (isTeamMember) {
          formData.set('verification_code', otp?.verificationCode);
        }
        if (!isTeamMember) {
          formData.set('booking_date', bookings.booking_service.booking_date);
          formData.set('booking_time', bookings.booking_service.booking_time);
        }
        const response = await editVendorBookings(
          bookings.booking_service?.id,
          formData,
        );
        handleSuccessResponse(response, response?.data?.message);
        handleClose();
        setUpdateData((pre) => pre + 1);
        const booking = await getBookingById(bookings?.booking_service?.id);
        setBookings(
          bookings?.map((item) =>
            item?.booking_service?.id === bookings?.booking_service?.id
              ? booking?.data
              : item,
          ),
        );
      }
    } catch (err) {
      handleErrorResponse(err, 'invalidArgument');
    } finally {
      setLoader(false);
    }
  };

  const optVerification = async (bookings) => {
    try {
      let param = {
        bookingId: bookings?.booking_service?.id,
      };
      let response = await sendBookingVerificationCode(param);
      handleSuccessResponse(response);
      return response;
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  const getBookingData = async (params) => {
    try {
      return await getBookingsVendor(params);
    } catch (err) {
      throw new Error('Error fetching booking data');
    }
  };

  const getServiceCustomerData = async () => {
    try {
      return await Promise.all([getAllCustomerInfo(), viewService()]);
    } catch (err) {
      throw new Error('Error fetching service customer data');
    }
  };

  const handleOptChange = (e) => {
    const { name, value } = e.target;
    setOpt((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getServiceLocation = (booking) => {
    if (booking?.delivery_type === 'at_shop') {
      return t('venderBooking.shop');
    } else if (booking?.delivery_type === 'at_my_location') {
      return t('venderBooking.home');
    } else {
      return '';
    }
  };

  const bookingFilterInputs = [
    {
      type: 'number',
      id: 'bookingId',
      valueKey: 'bookingId',
      className: 'user-filter-input',
    },
    {
      type: 'text',
      placeholder: t('header.search'),
      valueKey: 'customerName',
      className: 'user-filter-input',
    },
    {
      type: 'text',
      placeholder: t('header.search'),
      valueKey: 'serviceName',
      className: 'user-filter-input',
    },
    {
      type: 'date',
      id: 'createdDate',
      valueKey: 'createTime',
      format: 'DD/MM/YYYY',
      className: 'user-booking-filter-input-feild-date',
    },
    {
      type: 'date',
      id: 'bookingDate',
      valueKey: 'date',
      format: 'DD/MM/YYYY',
      className: 'user-booking-filter-input-feild-date',
    },
    {
      type: 'number',
      placeholder: t('header.search'),
      valueKey: 'duration',
      className: 'user-filter-input',
    },
    {
      type: 'time',
      valueKey: 'time',
      className: 'user-filter-input',
      onChange: handleTimeChange,
    },
    {
      type: 'number',
      placeholder: t('header.search'),
      valueKey: 'amount',
      className: 'user-filter-input',
    },
    {
      type: 'number',
      placeholder: t('header.search'),
      valueKey: 'discount',
      className: 'user-filter-input',
    },
    {
      type: 'number',
      placeholder: t('header.search'),
      valueKey: 'peakOfDiscount',
      className: 'user-filter-input',
    },
    {
      type: 'number',
      placeholder: t('header.search'),
      valueKey: 'cashback',
      className: 'user-filter-input',
    },
    {
      type: 'number',
      placeholder: t('header.search'),
      valueKey: 'totalAmaount',
      className: 'user-filter-input',
    },
  ];

  const bookingSelectInputs = [
    {
      id: 'status',
      placeholder: t('header.select'),
      valueKey: 'paymentType',
      options: [{ value: 'online', label: t('userBooking.payOnline') },
        { value: 'pay_at_shop', label: t('userBooking.payAtShop') },
      ],
      className: 'user-filter-select vendor-booking-select-tag-filter',
    },
    {
      id: 'vendor-team-list-select',
      placeholder: t('header.select'),
      valueKey: 'teamMember',
      options: teamMemberArrayForm.map((item) => ({
        value: item.id,
        label: item.full_name,
      })),
      className:
        'booking-filter-select vendor-booking-select-tag-filter vendor-booking-team-list-select',
      condition: !isTeamMember,
    },
    {
      id: 'serviceLocation',
      placeholder: t('header.select'),
      valueKey: 'serviceLocation',
      options: [
        { value: 'at_my_location', label: t('venderBooking.home') },
        { value: 'at_shop', label: t('venderBooking.shop') },
      ],
      className: 'user-filter-select vendor-booking-select-tag-filter',
    },
    {
      id: 'status',
      placeholder: t('header.select'),
      valueKey: 'status',
      options: [
        { value: '', label: t('ServiceField.all') },
        { value: 'confirmed', label: t('userBooking.confirmed') },
        { value: 'cancelled', label: t('userBooking.cancelledByCustomer') },
        { value: 'completed', label: t('userBooking.completed') },
        { value: 'expired', label: t('userBooking.expired') },
      ],
      className: 'booking-filter-select vendor-booking-select-tag-filter',
    },
  ];

  return {
    isComplte,
    setIsComplte,
    isEmptyPage,
    isOrganizationStatus,
    clickToconfirm,
    setClickConfirm,
    setLoader,
    loader,
    cancelUserBooking,
    showModal,
    handleShow,
    handleClose,
    teamMemberArrayForm,
    services,
    customers,
    filterValue,
    handlefilterInputChange,
    totalPage,
    toggleFilterRow,
    showFilterRow,
    clearFilterfunction,
    setSearchServices,
    handlePaginationChange,
    currentPage,
    itemsPerPage,
    bookings,
    setBookings,
    handleTimeChange,
    filterDataFromSearchHere,
    handleOptChange,
    iscompleteModal,
    setIsCompleteModal,
    otp,
    getServiceLocation,
    bookingTableHeader,
    bookingFilterInputs,
    bookingSelectInputs,
  };
};

export default useBookingServicesUserData;
