import { React, useLocation, useEffect } from '../../libraries';
import { handleErrorResponse, handleSuccessResponse, cancelDeleteUserRequest } from '../../services';
import { CancelDeletionRequest } from '../../pages';

const CancelDeletionAction = ({ setDeletionRequest }) => {
  const location = useLocation();
  const isSection = localStorage.getItem('isSection');

  useEffect(() => {
    if (isSection !== 'isProfile') {
      localStorage.setItem('isSection', 'isProfile');
    }
  }, [location.pathname]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let param = {
      status: 'activated',
    };

    cancelDeleteUserRequest(param)
      .then((response) => {
        handleSuccessResponse(response, response.data.message);
        localStorage.setItem('status', 'activated');
        setDeletionRequest(false);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  return (
    <>
      <CancelDeletionRequest handleSubmit={handleSubmit} />
    </>
  );
};
export default CancelDeletionAction;
