import { React, useEffect, useState } from '../../../libraries';
import { ShopModal } from '../../../pages/ReviewAndRatings/ShopModal/ShopModal';
import { handleErrorResponse, handleSuccessResponse ,venderRatingCreate} from '../../../services';

const ShopModalLogic = ({
  setUpdateReviewRating,
  setShopRating,
  shopRating,
  serviceIdRating,
}) => {
  const [ambienceRating, setAmbienceRating] = useState(0);
  const [cleanlinessRating, setCleanlinessRating] = useState(0);
  const [staffRating, setStaffRating] = useState(0);
  const [valueRating, setValueRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [ratingValidation, setRatingValidation] = useState('');
  const [reviewValidation, setReviewValidation] = useState('');
  const [ratingNotShow, setRatingNotShow] = useState(false);
  let response;
  const handleStarClick = (category, rating) => {
    switch (category) {
      case 'ambience':
        setAmbienceRating(rating);
        break;
      case 'cleanliness':
        setCleanlinessRating(rating);
        break;
      case 'staff':
        setStaffRating(rating);
        break;
      case 'value':
        setValueRating(rating);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (serviceIdRating === 0) {
      setRatingNotShow(true);
    }
  }, [serviceIdRating]);
  const handleReviewSubmit = async ({ closeButtonElement }) => {
    if (
      ambienceRating === 0 ||
      cleanlinessRating === 0 ||
      staffRating === 0 ||
      valueRating === 0
    ) {
      setRatingValidation('Please rate all categories');
      return;
    }
    if (reviewText.trim() === '') {
      setReviewValidation('Please enter your review');
      return;
    }

    if (closeButtonElement) {
      const closeButtonObject = {
        element: closeButtonElement,
        click: function () {
          closeButtonElement.click();
        },
      };

      closeButtonObject.click();
    }
    let data = {
      reviewable_id: serviceIdRating,
      reviewable_type: 'vendor',
      ambience_rating: ambienceRating,
      cleanliness_rating: cleanlinessRating,
      staff_rating: staffRating,
      value_rating: valueRating,
      comment: reviewText,
    };
    try {
      response = await venderRatingCreate(data);
      await handleSuccessResponse(response, response.data.message);
      setUpdateReviewRating((prev) => prev + 1);
    } catch (error) {

      handleErrorResponse(error);
    }
  };
  return (
    <>
      <ShopModal
        ratingNotShow={ratingNotShow}
        handleStarClick={handleStarClick}
        ambienceRating={ambienceRating}
        cleanlinessRating={cleanlinessRating}
        staffRating={staffRating}
        valueRating={valueRating}
        reviewText={reviewText}
        setReviewText={setReviewText}
        handleReviewSubmit={handleReviewSubmit}
        ratingValidation={ratingValidation}
        reviewValidation={reviewValidation}
        shopRating={shopRating}
        setShopRating={setShopRating}
      />
    </>
  );
};
export default ShopModalLogic;