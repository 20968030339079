import {React} from '../../libraries';

const CustomButton = ({
  backgroundColor,
  color,
  text,
  type,
  width,
  className,
  buttonClicked,
}) => {
  return (
    <button
      style={{ backgroundColor, color, width }}
      type={type}
      className={`btn ${className}`}
      disabled={buttonClicked}
    >
      {text || 'Search'}
    </button>
  );
};
export default CustomButton;