import {  Route, Routes, React, Suspense, useEffect, useState, useDispatch, useLocation } from '../libraries';
import { CustomSpinner, PageNotFound } from './index.js';
import { UserSidebars } from '../components';
import ProtectedRoute from '../Routes/ProtectedRoute.js';
import { fetchUserReferralsInformation } from '../reduxConfig/slice/user/userReferalSlice.js';
import { userRoutes } from '../routingConfig/routingConfig.js';
import { user } from '../Routes/routes.js';

const User = () => {
  const role = localStorage.getItem('role');
  const [deletionRequest, setDeletionRequest] = useState(false);
  const status = localStorage.getItem('status');
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (status === 'requested') setDeletionRequest(true);
    else setDeletionRequest(false);
  }, [status]);

  useEffect(() => {
    let refreshCount = 0;
    dispatch(fetchUserReferralsInformation());
    const handleBeforeUnload = (event) => {
      refreshCount++;
      if (refreshCount > 1 && !event.clientX && !event.clientY) {
        localStorage.removeItem('isSection');
      }
    };

    if (localStorage.getItem('isSection')) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dispatch]);

  if (role !== 'Customer') {
    return (
      <Routes>
        <Route path="*" element={<PageNotFound />} ></Route>
      </Routes>
    );
  }
  const isUserRoute = userRoutes.some(route => `${user}${route.path}` === location.pathname);
  return (
    <ProtectedRoute>
      {isUserRoute ? (
        <UserSidebars deletionRequest={deletionRequest} setDeletionRequest={setDeletionRequest}>
          <Routes>
            {userRoutes.map(({ path, component: Component }) => (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<CustomSpinner />}>
                    <Component deletionRequest={deletionRequest} setDeletionRequest={setDeletionRequest} />
                  </Suspense>
                }
              />
            ))}
          </Routes>
        </UserSidebars>
      ) : (
        // Render PageNotFound without UserSidebars
        <Routes>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </ProtectedRoute>
  );
};

export default User;