import { React, useEffect, useState, useLocation, useNavigate } from '../../libraries/index';
import { ReviewAndRatingsHeader } from '../../pages/index';
import { reviewService, reviewShop } from '../../Routes/routes';
const ReviewAndRatingsHeaderLogic = () => {
  const [isPage, setIsPage] = useState({
    accountEditPage: false,
    openingHours: false,
  });
  const pathlocation = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const isReviewShopPage = pathlocation.pathname === `/user/${reviewShop}`;
    const isReviewServicePage =
      pathlocation.pathname === `/user/${reviewService}`;

    setIsPage((prevPage) => ({
      ...prevPage,
      accountEditPage: isReviewShopPage,
      openingHours: isReviewServicePage,
    }));
  }, [pathlocation.pathname]);

  const navigateFun = (e, path) => {
    e.preventDefault();
    navigate(path);
  };
  return (
    <>
      <ReviewAndRatingsHeader navigateFun={navigateFun} isPage={isPage} />
    </>
  );
};
export default ReviewAndRatingsHeaderLogic;