import { useEffect, useState, moment, Locale, useCallback } from "../../../libraries/index"
import { isRequired, isRequiredSelect, isValidFirstName, isValidPhoneNumber } from "../../pages/Validation/Validation";
import { vendorEditMyProfile, vendorFetchProfileDetails,handleErrorResponse, handleSuccessResponse } from "../../../services";
import { checkFileSize } from "../../../common/uploadFileModal/fileSizeLogic";
import getCroppedImg from "../../../common/imageCrop/getCroppedImg";

const VendorProfileLogic = () => {
    const initialValues = { email: "", first_name: "", image: "", last_name: "", country_code: "", phone_number: "", gender: "", date_of_birth: "", file: "" }
    const [formValue, setFormValue] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [vendorProfileResponse, setVendorProfileResponse] = useState();
    const [isLoader, setIsLoader] = useState(true);
    const [workingHour, setWorkingHour] = useState("")
    const [teamMemberServices, setTeamMemberServices] = useState()
    const [profileVisible, setProfileVisible] = useState(true)
    const isTeamMember = localStorage.getItem("role") === "TeamMember"
    const { t } = Locale();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const onCropComplete = useCallback((croppedArea,croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);
  
    const showCroppedImage = useCallback(async () => {
      try {
        const croppedImage = await getCroppedImg(selectedFile, croppedAreaPixels);
        try {
          const response = await fetch(croppedImage);
          const blob = await response.blob();
          const file = new File([blob],formValue.file.name, { type: blob.type });
            setSelectedFile(croppedImage);
            return file;
        } catch (error) {
          console.error('Error fetching or processing blob:', error);
        }
      } catch (e) {
        console.error(e);
      }
    }, [selectedFile,croppedAreaPixels,formValue.file.name]);

    useEffect(() => {
        if (vendorProfileResponse) {
            if (!isTeamMember) {
                setFormValue((prevValues) => ({
                    ...prevValues,
                    image: vendorProfileResponse.images ? vendorProfileResponse.images : vendorProfileResponse.image_url,
                    email: vendorProfileResponse?.user?.email,
                    first_name: vendorProfileResponse?.user?.first_name,
                    last_name: vendorProfileResponse?.user?.last_name,
                    gender: vendorProfileResponse?.user?.gender,
                    date_of_birth: vendorProfileResponse?.user?.date_of_birth,
                    phone_number: (vendorProfileResponse?.user?.phone_number) && vendorProfileResponse?.user?.phone_number?.split(" ").length > 0 ? vendorProfileResponse?.user?.phone_number?.split(" ")[1] : vendorProfileResponse.user.phone_number,
                    country_code: (vendorProfileResponse?.user?.phone_number) && vendorProfileResponse?.user?.phone_number?.split(" ").length > 0 ? vendorProfileResponse?.user?.phone_number?.split(" ")[0] : "+66"
                }))
                localStorage.setItem("name", vendorProfileResponse?.user?.first_name + " " + vendorProfileResponse?.user?.last_name)
            }
            else {
                setWorkingHour(vendorProfileResponse?.team_member?.working_hours)
                setFormValue((prevValues) => ({
                    ...prevValues,
                    image: vendorProfileResponse?.image_url,
                    email: vendorProfileResponse?.team_member?.team_member?.email,
                    first_name: vendorProfileResponse?.team_member?.team_member?.first_name,
                    last_name: vendorProfileResponse?.team_member?.team_member?.last_name,
                    gender: vendorProfileResponse?.team_member?.team_member?.gender,
                    date_of_birth: vendorProfileResponse?.team_member?.team_member?.date_of_birth,
                    phone_number: (vendorProfileResponse?.team_member?.team_member?.phone_number) && vendorProfileResponse?.team_member?.team_member?.phone_number?.split(" ").length > 0 ? vendorProfileResponse?.team_member?.team_member?.phone_number?.split(" ")[1] : vendorProfileResponse?.team_member?.team_member?.phone_number,
                    country_code: (vendorProfileResponse?.team_member?.team_member?.phone_number) && vendorProfileResponse?.team_member?.team_member?.phone_number?.split(" ").length > 0 ? vendorProfileResponse?.team_member?.team_member?.phone_number?.split(" ")[0] : "+66"
                }))
                localStorage.setItem("name", vendorProfileResponse?.team_member?.team_member?.first_name + " " + vendorProfileResponse?.team_member?.team_member?.last_name)

            }
        }

    }, [vendorProfileResponse])

    const handleChange = (e) => {
        if (e instanceof File) {
            setProfileVisible(false)
            if (e?.type?.includes('image') && checkFileSize(e, t)) {
                // If file passes size validation, update form state
                setFormValue((prevValues) => ({
                    ...prevValues,
                    file: e,
                }));
                setSelectedFile(URL.createObjectURL(e));
            }
            else
                return;

        }
        else {
            const { name, value } = e.target;
            const errorLogs = validation({
                ...formValue, // Pass existing form values
                [name]: value, // Update the current field being changed
            });
            setFormErrors(errorLogs);
            setFormValue((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }

    };

    const handleDateSelect = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
        dateString: [string, string] | string,
    ) => {
        setFormValue((prevValues) => ({
            ...prevValues,
            date_of_birth: dateString
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let cropFile=await showCroppedImage();
        let err = validation();
        setProfileVisible(true)
        setFormErrors(err)
        if (Object.keys(err).length === 0) {
            let formData = appendData(cropFile);
            try {
                let response = await vendorEditMyProfile(formData);
                localStorage.setItem('name', response.data.user.first_name + " " + response.data.user.last_name);
                if (response.data.images)
                    localStorage.setItem('profilePhoto', response.data.images);
                setVendorProfileResponse(response.data)
                handleSuccessResponse(response, response.data.message)
                isTeamMember && fatchVendorProfileDetails()
            }
            catch (err) {
                handleErrorResponse(err)
            }
        }
    }

    const validation = (formType) => {
        let checkErr = {};
        if (!formType) {
            checkErr = isValidFirstName(formValue.first_name, { ...checkErr }, 'first_name', `${t("field.fname")}`, t)
            checkErr = isValidFirstName(formValue.last_name, { ...checkErr }, 'last_name', `${t("field.lName")}`, t)
            checkErr = isValidPhoneNumber(formValue.phone_number, { ...checkErr }, "phone_number", `${t("field.phoneNumber")}`, "", t)
            checkErr = isRequiredSelect(formValue.gender, { ...checkErr }, 'gender', `${t("field.gender")}`, t)
            checkErr = isRequired(formValue.date_of_birth, { ...checkErr }, 'date_of_birth', 'DOB', t)
        }
        return checkErr
    }

    const appendData = (cropFile) => {
        const formData = new FormData();
            formData.append("first_name", formValue.first_name);
            formData.append("last_name", formValue.last_name);
            let phone_with_country_code = formValue.phone_number
                ? (formValue.country_code
                    ? formValue.country_code.split("(").length > 0
                        ? formValue.country_code.split("(")[0].toString()
                        : formValue.country_code
                    : "+66") + " " + formValue.phone_number
                : "";
            formData.append("phone_number", phone_with_country_code);
            if (formValue.gender)
                formData.append("gender", !formValue.gender || formValue.gender === 'null' ? null : formValue.gender)
            formData.append("date_of_birth", formValue.date_of_birth);
            if (formValue.file)
                formData.append('image', cropFile)
    

        return formData;
    }

    const fatchVendorProfileDetails = async () => {

        try {
            let response = await vendorFetchProfileDetails();
            setVendorProfileResponse(response.data)
            isTeamMember && setTeamMemberServices(response?.data?.services)
            setIsLoader(false)
        }
        catch (err) {
            handleErrorResponse(err)
        }
    }

    const onChange = (name, value) =>
        setFormValue({ ...formValue, [name]: value });
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const disabledFutureDate = (current) => {
        return current && current > moment().endOf("day");
    };

    return {
        workingHour,
        teamMemberServices,
        isLoader,
        disabledFutureDate,
        onChange,
        filterOption,
        fatchVendorProfileDetails,
        formValue,
        selectedFile,
        handleChange,
        handleSubmit,
        formErrors,
        handleDateSelect,
        profileVisible,
        onCropComplete,
        crop,
        zoom,
        setZoom,
        setCrop,
    }
}
export default VendorProfileLogic;