import './AddToFavoriteService.css';
import { React, Rate, Locale, Pagination } from '../../libraries';
import { AddToFavouriteSettingHeaders, BookNowModalLogic } from '../../components';
import { VendorHeader } from '../../vendor/pages';
import { CurrencyDisplay, DiscountPercent } from '../../jsonData';
import { DefaultServiceImg } from '../../assets/Images.js';
import { Heading, EmptyComponent, CustomSpinner } from '../../common';
import { CustomImage } from '../../lazyComponent/index.js';

const AddToFavouriteService = ({
  cartItem,
  handleAddToCartCount,
  handleAddToCart,
  favouriteServices,
  handleLike,
  viewAllFunction,
  isLoader,
  isErroPage,
  filterValue,
  total_pages,
  handlePaginationChange,
}) => {
  const { t } = Locale();
  const deletionRequest = localStorage.getItem('status') === 'requested';
  const selectedLanguage = localStorage.getItem('i18nextLng')
  const getCartDisable = (serviceIs, outer) => {
    if (cartItem?.includes(serviceIs)) {
      return !outer ? 'disabled-icon' : 'disable-icon-outer';
    } else {
      return 'inabled-icon';
    }
  };

  const getServiceName = (service) => {
    return selectedLanguage === 'en' ? service.service_name : service.service_name_thai
  }

  return (
    <>
      <div
        className={`heading-outer-div user-outer-div ${deletionRequest && 'deletionRequest-user-header'}`}
      >
        <VendorHeader handleAddToCartCount={handleAddToCartCount} />
        <Heading text={t('vendor.favorite')} icon="bi-suit-heart" />
        <div className="after-heading-outer-div">
          <div className="user-common-box">
            <div className="user-common-box-inner">
              <div className="user-common-box-fix-content row-box">
                <AddToFavouriteSettingHeaders />
              </div>
              <div
                className={
                  !isLoader
                    ? `add-to-fav-container add-to-inner-fav-container mt-2 user-inner-content-scrollar user-inner-content-scrollar-after-fix-content`
                    : 'd-none'
                }
              >
                <div className="container-fluid add-to-fav-container3">
                  <div className="mb-4 h-auto">
                    <>
                      {favouriteServices && favouriteServices.length > 0 && (
                        <div className="row mb-2">
                          {favouriteServices.map((service, index) => (
                            <div
                              className="col-lg-6 col-md-12 col-sm-12 col-12 mt-3"
                              key={index}
                            >
                              <div className="shop-cart-dd-service">
                                <div className="cart-image">
                                  <div className="service-image-div">
                                    <CustomImage
                                      src={service.service_image || DefaultServiceImg}
                                      alt="f"
                                      width={'unset'}
                                      height={'unset'}
                                      onClick={() =>
                                        viewAllFunction(
                                          service.organization_id,
                                          service.vendor_name
                                            ? service.vendor_name
                                            : service.service_name,
                                        )
                                      }
                                      className="cursor-point-class"

                                    />
                                  </div>
                                  <div className="cart-name-start-time-child">
                                    <p
                                      className="favourite-service-organisation-name"
                                      onClick={() =>
                                        viewAllFunction(
                                          service.organization_id,
                                          service.vendor_name
                                            ? service.vendor_name
                                            : service.service_name,
                                        )
                                      }
                                    >
                                      <span className="display-service-heading cursor-point-class">
                                        {getServiceName(service)}
                                      </span>
                                    </p>
                                    <div className="favourite-service-rating-address">
                                      <div className="favourite-service-rating">
                                        <Rate
                                          allowHalf
                                          disabled
                                          value={service.service_average_rating}
                                        ></Rate>
                                      </div>
                                      <span className="shop-card-address favorite-service-clock">
                                        <i className="bi bi-clock-fill"></i>
                                      </span>
                                      &nbsp;{' '}
                                      <span className="shop-card-address favourite-service-time">
                                        {service.duration} {t('paymentSection.min')}
                                      </span>
                                    </div>
                                    <div className="price-child favourite-shop-price">
                                      <span className="service-card-price2-new">
                                        {service.special_price ? (
                                          <div className='display-service-price-outer'>
                                            <CurrencyDisplay
                                              value={
                                                service.special_price
                                                  ? service.special_price
                                                  : service.price
                                              }
                                              subPrice={
                                                service.price || service.price
                                              }
                                            />
                                            <span>
                                              <DiscountPercent value={service?.discount_on_service} />
                                            </span>
                                          </div>
                                        ) : (
                                          <>
                                            <CurrencyDisplay
                                              value={
                                                service.special_price
                                                  ? service.special_price
                                                  : service.price
                                              }
                                            />
                                          </>
                                        )}
                                      </span>
                                    </div>

                                    <div className="add-to-favoutite-shop-button-mobile">
                                      <span
                                        onClick={() =>
                                          handleAddToCart(service.service_id)
                                        }
                                        className={`cart-icon-btn btn me-2 ${getCartDisable(service.service_id, true)}`}
                                      >
                                        <span>
                                          <i
                                            className={`bi bi-cart-plus-fill shop-card-share mt-1 ${getCartDisable(service.service_id)}`}
                                          ></i>
                                        </span>
                                        <span
                                          className={`cart-btn-text ${getCartDisable(service.service_id)}`}
                                        >
                                          {t('cart.addToCart')}
                                        </span>
                                      </span>
                                      <BookNowModalLogic
                                        type="AddToFavourite"
                                        vender_details={service}
                                        service_id={service.service_id}
                                        className="display-service-button1"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="service-info">
                                  <div className="name-star-time-share">
                                    <div className="share-fav-icon">
                                      <span className="shop-card-heart">
                                        <i
                                          className={`bi bi-suit-heart-fill ${service.isLiked ? 'liked' : ''
                                            }`}
                                          onClick={() =>
                                            handleLike(service.favorite_id)
                                          }
                                        ></i>
                                      </span>
                                    </div>
                                    <div className="view-service-button add-to-favoutite-shop-button">
                                      <span
                                        onClick={() =>
                                          handleAddToCart(service.service_id)
                                        }
                                        className={`cart-icon-btn btn me-2 ${getCartDisable(service.service_id, true)}`}
                                      >
                                        <span>
                                          <i
                                            className={`bi bi-cart-plus-fill shop-card-share mt-1 ${getCartDisable(service.service_id)}`}
                                          ></i>
                                        </span>
                                        <span
                                          className={`cart-btn-text ${getCartDisable(service.service_id)}`}
                                        >
                                          {t('cart.addToCart')}
                                        </span>
                                      </span>
                                      <BookNowModalLogic
                                        type="AddToFavourite"
                                        vender_details={service}
                                        service_id={service.service_id}
                                        className="display-service-button1"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {total_pages > 15 && (
                        <div className="text-center">
                          <Pagination
                            current={filterValue?.page_number}
                            pageSize={filterValue?.per_page}
                            total={total_pages}
                            showSizeChanger={false}
                            defaultCurrent={3}
                            onChange={handlePaginationChange}
                          />
                        </div>
                      )}
                      {isErroPage && (
                        <>
                          <EmptyComponent type="isService" />
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
              {isLoader && (
                <>
                  <CustomSpinner />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddToFavouriteService;