import { useState, useEffect, useNavigate } from '../../../libraries/index.js';
import {
  getServices,
  deleteService,
  updateServices,
  handleSuccessResponse,
  handleErrorResponse,
  getAllCategory,
  getAllSubCategory
} from '../../../services/index.js';
import { useDebouncedValue } from '../../../jsonData/debouncingFunction.js';
import {
  buisinesseditService,
  bussinessbookings,
  addService,
  bussiness,
} from '../../../Routes/routes.js';
import { GetOrganizationStatus } from '../index';
const useServicesData = () => {
  const navigate = useNavigate();
  const { isOrganizationStatus } = GetOrganizationStatus();
  const [serviceData, setServiceData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [spinner, setSpinner] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategory] = useState([]);
  const [searchServices, setSearchServices] = useState('');
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [filterValue, setFilterValue] = useState({
    serviceId: '',
    duration: '',
    price: '',
    specialPrice: '',
    status: '',
    category: '',
    subCategory: '',
  });
  const [isFlaceMessage, setIsFlashMessage] = useState('');
  const debouncedSearchTerm = useDebouncedValue(filterValue, 500);
  const [showEmptyPage, setShowEmptyPage] = useState(false);
  const [isComplte, setIsComplte] = useState(false);

  useEffect(() => {
    if (isOrganizationStatus !== 'verified') {
      setSpinner(false);
    } else {
      fetchData();
    }
  }, [isOrganizationStatus, debouncedSearchTerm]);

  const fetchData = async () => {
    setSpinner(true);
    try {
      if (filterValue.category) {
        const subCategory = await getAllSubCategory(filterValue.category);
        setSubCategory(subCategory.data);
      }
      const params = paramObject(currentPage, itemsPerPage);
      const serviceResponse = await getServices(params);
      if (serviceResponse?.data?.services) {
        const categories = await getAllCategory();
        setCategories(categories.data);
        setServiceData(serviceResponse?.data?.services);
        if (
          serviceResponse?.data?.services?.length < 0 ||
          serviceResponse?.data?.status_code === 204
        ) {
          setShowEmptyPage(true);
        } else {
          setShowEmptyPage(false);
        }
        setTotalPage(serviceResponse?.data?.total_pages * itemsPerPage);
      } else {
        setServiceData([]);
        setTotalPage(0);
        const allValuesNull = isObjectEmpty(params);
        if (allValuesNull) {
          setShowEmptyPage('emptyServices');
        } else {
          setShowEmptyPage('emptyServicesFilter');
        }
      }
    } catch (error) {
      setShowEmptyPage(true);
      if (error?.response?.data?.status_code === 404) {
        handleErrorResponse(error);
      }
      if (error?.response?.status)
        setIsFlashMessage(error?.response?.data?.error);
      else handleErrorResponse(error);
    } finally {
      setSpinner(false);
    }
  };

  const isObjectEmpty = (params) => {
    const obj = Object.fromEntries(params.entries());
    for (let key in obj) {
      if (
        key !== 'page_number' &&
        key !== 'per_page' &&
        (obj[key] === null || obj[key] === '')
      ) {
        continue;
      }
      if (key !== 'page_number' && key !== 'per_page') {
        return false;
      }
    }
    return true;
  };

  const handlefilterInputChange = (name, value) => {
    setFilterValue({ ...filterValue, [name]: value });
  };

  const handleShow = () => setShowModal(true);

  const handleClose = () => {
    setIsComplte(false);
    setShowModal(false);
  };

  const deleteServices = async (id) => {
    setShowModal(false);
    setIsComplte(false);
    setSpinner(true);
    try {
      const response = await deleteService(id);
      if (response?.data?.status_code === 422)
        handleSuccessResponse(response, response?.data?.message);
      else {
        const updatedServiceData = serviceData?.filter(
          (item) => item?.service?.id !== id,
        );
        setServiceData(updatedServiceData);
        handleSuccessResponse(response, response?.data?.message);
      }
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setSpinner(false);
    }
  };

  const paramObject = (page, pageSize) => {
    return new URLSearchParams({
      page_number: page,
      per_page: pageSize,
      service_name_en: filterValue?.serviceId,
      duration: filterValue?.duration
        ? parseInt(filterValue?.duration, 10)
        : '',
      price: filterValue?.price ? parseInt(filterValue?.price, 10) : '',
      special_price: filterValue?.specialPrice
        ? parseInt(filterValue?.specialPrice, 10)
        : '',
      category_id: filterValue?.category,
      sub_category_id: filterValue?.subCategory,
      is_enabled: filterValue?.status,
      search: searchServices,
    });
  };

  const editService = (serviceObject) => {
    navigate(`${bussiness}${buisinesseditService}`, { state: serviceObject });
  };

  const showBookingsRecords = (serviceObject) => {
    localStorage.setItem('isSection', 'isBusinessBooking');
    navigate(`${bussiness}${bussinessbookings}`, { state: serviceObject });
  };

  const addNewService = () => {
    navigate(`${bussiness}${addService}`);
  };

  const actovateOrDeactivate = async (serviceObj) => {
    serviceObj.service.is_enabled = !serviceObj.service.is_enabled;
    serviceObj.service.category_id = serviceObj.service.category.id;
    serviceObj.service.sub_category_id = serviceObj.service.sub_category.id;
    delete serviceObj.service.category;
    delete serviceObj.service.sub_category;
    try {
      const response = await updateServices(serviceObj.service);
      const updatedServiceData = serviceData?.map((item) =>
        item.service.id === response.data.service.id ? response.data : item,
      );
      setServiceData(updatedServiceData);
    } catch (error) {}
  };

  const handlePaginationChange = async (page, pageSize) => {
    setSpinner(true);
    const queryParams = paramObject(page, pageSize);
    try {
      const serviceResponse = await getServices(queryParams);
      setServiceData(serviceResponse.data.services);
      setTotalPage(serviceResponse.data.total_pages * 15);
      setCurrentPage(page);
      setItemsPerPage(pageSize);
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setSpinner(false);
    }
  };

  const toggleFilterRow = () => {
    setShowFilterRow(!showFilterRow);
  };

  const clearFilterfunction = async () => {
    setCurrentPage(1);
    setFilterValue({
      serviceId: '',
      duration: '',
      price: '',
      specialPrice: '',
      status: '',
      category: '',
      subCategory: '',
    });
  };

  const filterDataFromSearchHere = async () => {
    setFilterValue({
      serviceId: '',
      duration: '',
      price: '',
      specialPrice: '',
      status: '',
      category: '',
      subCategory: '',
    });
  };

  return {
    filterValue,
    handlefilterInputChange,
    totalPage,
    toggleFilterRow,
    showFilterRow,
    filterDataFromSearchHere,
    clearFilterfunction,
    spinner,
    serviceData,
    showModal,
    handleShow,
    handleClose,
    editService,
    addNewService,
    deleteServices,
    setDeleteItemId,
    deleteItemId,
    actovateOrDeactivate,
    handlePaginationChange,
    currentPage,
    itemsPerPage,
    categories,
    subCategories,
    setSearchServices,
    showBookingsRecords,
    isFlaceMessage,
    isOrganizationStatus,
    showEmptyPage,
    isComplte,
    setIsComplte,
  };
};

export default useServicesData;
